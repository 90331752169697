<template>
    <div>
        <div class="main" v-html="htmlData">
        </div>
    </div>
</template>

<script>
    import {getContentById} from "@/api/request";
    import {Toast} from "vant";

    export default {
        name: "intro",
        data() {
            return {
                htmlData: '',
            }
        },
        mounted() {
            let id = this.$route.query.id;
            this.initPage(id);
        },
        methods: {
            initPage(id) {
                getContentById(id).then(res => {
                    if (res.data.success) {
                        this.htmlData = res.data.data;
                    }
                    else {
                        Toast.fail(res.data.error.message);
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .main{
        width: 100%;
        height: 100vh;
        overflow-y: auto;
    }
</style>