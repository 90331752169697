<template>
    <div class="main-container">
        <div class="top-info">
            <van-image :src="headImg" width="2.5rem" round>
            </van-image>

            <div class="title-user-panel">
                <div>
                    <span class="user-name">{{username}}</span>
                    <span class="user-uid">{{uid}}</span>
                </div>
                <div class="second-info">
                    <van-image :src="phoneIcon" width=".8rem"></van-image>
                    <span class="user-tel">{{tel}}</span>
                    <span class="text-button" v-if="!hasTel" @click="openBindPage">绑定</span>
                    <span class="text-button" v-if="hasTel" @click="openUnbindPage">解绑</span>
                </div>

            </div>
        </div>

        <van-divider :style="{ borderColor: '#777775', 'font-size': '.8rem', margin: '.2rem 16px' }">
        </van-divider>
        <div class="main-panel">
            <van-cell :icon="qiliLogo" title="七里币" :value="qlb"/>

            <div class="main-panel-button">
                <van-button plain type="info" @click="openKf" size="mini" :icon="kfIcon" color="#99C800">
                    在线客服
                </van-button>

                <van-button plain type="info" @click="openChangePassword" size="mini" :icon="passwordIcon" color="#99C800">
                    修改密码
                </van-button>

                <van-button plain type="info" @click="openRealName" size="mini" :icon="cardIcon" color="#99C800">
                    实名认证
                </van-button>

            </div>
        </div>

        <div class="box-button">
            <van-button round type="info" @click="continuePlay" block size="small" color="#99C800">
                继续游戏
            </van-button>

            <p class="text-button" @click="logout">退出登录
            </p>
        </div>

    </div>
</template>

<script>

  import headImg from '@/assets/headimg.png'
  import phoneIcon from '@/assets/phone.png'
  import qiliLogo from '@/assets/qiliLogo.png'
  import passwordIcon from '@/assets/password-g.png'
  import kfIcon from '@/assets/kf-g.png'
  import cardIcon from '@/assets/card-g.png'

  import { Dialog, Toast } from 'vant'

  export default {
    name: 'realName',
    data: function () {

      return {
        headImg,
        phoneIcon,
        kfIcon,
        cardIcon,
        qiliLogo,
        passwordIcon,
        username: '',
        uid: '',
        tel: '',
        hasTel: false,
        qlb: '0',
      }
    },
    mounted() {
      let params = {
        channelId: '100' ,
        device: 'H5' ,
        gameId: '10' ,
      };
      getGameQLBalance(params).then(res => {
        if(res.data.success) {
          this.qlb = res.data.data;
        }
        else {
          Toast.fail(res.data.error.message);
        }
      });

      let user = JSON.parse(localStorage.getItem('user'));
      //{"token":"a868a91a68e5442baded334fd90a9260","headerImg":null,"nickName":null,
      // "userName":"lilu666","phone":null,"isUserCheck":"0","userId":"52","adminId":"100","needSecondCheck":"0","is_audit":null}
      this.username = user.userName;
      this.uid = 'UID:' + user.userId;
      this.tel = user.phone ? user.phone : "未绑定";
      user.phone && (this.hasTel = true);
    },
    methods: {
      continuePlay () {
        window.androidWebJS.finish();
      },
      logout () {
        window.androidWebJS.logout();
      },
      openKf() {
        window.androidWebJS.onlineCustomerServer();
      },
      openChangePassword(){
        window.androidWebJS.modifyPassword();
      },
      openRealName() {
        window.androidWebJS.personCheck();
      }
      ,openBindPage() {
        window.androidWebJS.bindPhone();
      }
      ,openUnbindPage() {
        window.androidWebJS.unbindPhone();
      }
    }
  }
</script>

<style scoped lang="scss">
    .main-container {
        margin: .5rem .3rem .8rem;
        .top-info {
            text-align: left;
            padding: .5rem .8rem;
            display: flex;
            /*实现垂直居中*/
            align-items: center;
            justify-content: left;

            .title-user-panel {
                display: inline-block;
                margin-left: .8rem;
                width: 100%;

                .user-name {
                    font-size: .9rem;
                    color: #4d4e50;
                }

                .user-uid {
                    font-size: .9rem;
                    color: #7a7a7c;
                    margin-left: .9rem;
                }

                .second-info {
                    margin-top: .3rem;

                    .user-tel {
                        font-size: .8rem;
                        color: #7a7a7c;
                        margin-left: .3rem;
                    }

                    .text-button {
                        font-size: .8rem;
                        color: #99C800;
                        margin-left: .7rem;
                    }
                }
            }
        }

        .main-panel {
            text-align: left;
            .van-cell {
                font-size: .8rem;
            }
            .main-panel-button{
                margin-top: .8rem;
                margin-bottom: 3rem;
                padding: 0 .9rem;
                /*.van-button--mini+.van-button--mini {
                    margin-left: 0px;
                }*/
            }
        }

        .box-button {
            width: 10rem;
            margin: .7rem auto 0;
            p{
                margin: 3px 10px;
            }
            .text-button{
                font-size: .7rem;
                margin-top: .4rem;
                color: #e96d7c;
            }
        }
    }
</style>
