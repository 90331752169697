<template>
    <div class="all-page">
        <div class="header-panel">
            <div class="logo-panel">
                <van-image :src="logo" width="1.5rem"></van-image>
                <span style="color: white;margin-left: 10px;font-size: 18px;font-weight: 700">七里游戏下载</span>
            </div>
        </div>


        <div class="main-page">

            <div style="width: 100%;margin: 10px auto 0;max-width: 600px;">
                <video v-if="!!videoUrl" :src="videoUrl" style="width: 90%; object-fit:cover;border-radius: 5px;"
                       :poster="videoImageUrl" :controls="true" :autoplay="true"></video>
            </div>
            <div class="game-info">
                <img :src="logoUrl" style="width: 60px;height: 60px;">
                <div class="game-text">
                    <span style=" font-size: 16px;font-weight: bold;">{{gameName}}</span>
                    <div class="tags" style="margin-top: 12px;">
                        <span v-for="item in tagList">{{item}}</span>
                    </div>
                </div>
            </div>

            <div style="text-align: left;font-weight: bold;font-size: 18px;margin: 10px 20px;align-self: flex-start">
                游戏简介
            </div>
            <div class="game-desc">
                {{gameDesc}}
            </div>

            <div class="ios-div"  v-if="isM" v-show="showInstall">点击允许后，打开手机设置->通用->VPN与设备管理，安装证书</div>

            <div style="padding: 10px 20px;width: 90%;margin-top: 15px;margin-bottom: 10px;">
                <van-button block round color="#e55825" style="font-size: 16px;font-weight: bold;" @click="downloadGame">下载游戏
                </van-button>
                <div style="color: #888888;font-size: 10px;margin-top: 60px;">更多精彩关注七里网络公众号</div>
            </div>

            <div v-if="!isM" class="code-div">
                <div class="qr-code" ref="qrCodeUrl"></div>
                <p>扫描二维码下载游戏</p>
            </div>

        </div>
        <van-overlay :show="overlayShow">
            <div class="overlay-panel">
                <div>
                    <van-image :src="wjtImg" width="80px" height="80px"></van-image>
                </div>
                <div class="overlay-info">
                    <p>
                        请点击右上角 ... 在默认浏览器中打开
                    </p>
                </div>
            </div>


        </van-overlay>
    </div>
</template>

<script>
    import wjtImg from '@/assets/wjt.png'
    import logo from '@/assets/logo.png'
    import QRCode from 'qrcodejs2'
    import {Toast} from "vant";
    import {getDownParam} from "@/api/request";

    export default {
        name: 'download_h5',
        data() {
            return {
                logo,
                wjtImg,
                logoUrl: '',
                thisUrl: '',
                androidUrl: '',
                iosUrl: '',
                iosUrlToSet: 'https://game.7li.cn/static/download/toset.mobileprovision',
                qrCodeShow: false,
                isM: false,
                downloading: false,
                showInstall: false,
                overlayShow: false,
                gameName: '',
                videoUrl: null,
                videoImageUrl: '',
                tagList: [],
                gameDesc: '',
            }
        },
        mounted() {
            this.channelId = this.$route.path.split('/')[2];
            this.gameId = this.$route.path.split('/')[3];
            this.thisUrl = window.location.href;
            this.isM = this.isMobile();
            if (!this.isM) {
                this.creatQrCode();
            }
            if (this.isWeiXin()) {
                this.overlayShow = true;
            } else {
                this.getDownLoad();
            }
        },
        methods: {
            getDownLoad() {
                let params = {
                    adminId: this.channelId,
                    gameId: this.gameId
                };
                getDownParam(params).then((res) => {
                    if (res.data.success === true) {
                        this.gameSystem = res.data.data.gameSystem;
                        this.iosUrl = res.data.data.iosUrl;
                        this.androidUrl = res.data.data.androidUrl;
                        this.logoUrl = res.data.data.logoUrl;
                        this.gameName = res.data.data.gameName;
                        this.videoUrl = res.data.data.videoUrl;
                        this.videoImageUrl = res.data.data.videoImgUrl;
                        this.tagList = res.data.data.tagList;
                        this.gameDesc = res.data.data.gameDesc;
                    } else {
                        Toast(res.data.error.message);
                    }
                });
            },
            isWeiXin() {
                let ua = window.navigator.userAgent.toLowerCase();
                //通过正则表达式匹配ua中是否含有MicroMessenger字符串
                return ua.match(/MicroMessenger/i) == 'micromessenger';
            },
            isMobile() {
                let browser = {
                    versions: function () {
                        let u = navigator.userAgent, app = navigator.appVersion;
                        return {
                            trident: u.indexOf('Trident') > -1, //IE内核
                            presto: u.indexOf('Presto') > -1, //opera内核
                            webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
                            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1,//火狐内核
                            mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
                            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
                            android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1, //android终端
                            iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
                            iPad: u.indexOf('iPad') > -1, //是否iPad
                            webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
                            weixin: u.indexOf('MicroMessenger') > -1, //是否微信 （2015-01-22新增）
                            qq: u.match(/\sQQ/i) == " qq" //是否QQ
                        };
                    }(),
                    language: (navigator.browserLanguage || navigator.language).toLowerCase()
                }
                // return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
                return browser.versions.mobile || browser.versions.android || browser.versions.ios;
            },
            creatQrCode() {
                //console.log(this.$refs.qrCodeUrl.innerHTML = "");
                this.qrCode = new QRCode(this.$refs.qrCodeUrl, {
                    text: this.thisUrl,
                    width: 150,
                    height: 150,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H

                });
            },
            downloadGame() {
                if (this.isMobile()) {
                    let ua = navigator.userAgent.toLowerCase();
                    if (/iphone|ipad|ipod/.test(ua)) {//判断是否ios
                        Toast({
                            message: "点击允许后，找到 对应游戏文件 点击安装，输入锁屏密码，安装之后，在桌面进行查看",
                            position: 'top',
                            duration: -1
                        });
                        window.location.href = this.iosUrl;
                        this.downloading = true;
                        setTimeout(() => {
                            this.showInstall = true;
                        }, 2000)
                    } else {
                        window.location.href = this.androidUrl;
                    }
                } else {
                    window.location.href = this.androidUrl;
                }
            },
            goGuide() {
                window.location.href = this.iosUrlToSet;
            }
        }
    }
</script>

<style scoped>
    .all-page {
        overflow: hidden;
        background-color: #FFFFFF;
        width: 100%;
        height: 100vh;
    }

    .header-panel {
        width: 100%;
        height: 70px;
        background-color: #37414d;
        text-align: center;
    }

    .logo-panel {
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
        display: flex;
        height: 100%;
        align-content: center;
        align-items: center;
        justify-content: center;
    }

    .main-page {
        overflow: hidden;
        overflow-y: auto;
        background-color: #F5F6F8;
        width: 100%;
        height: calc(100vh - 70px);
        max-width: 600px;
        margin: 0 auto;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        box-sizing: border-box;
    }

    .game-info {
        background-color: #FFFFFF;
        border-radius: 5px;
        width: 90%;
        box-sizing: border-box;
        margin: 10px;
        display: flex;
        align-items: center;
        padding: 10px 15px;
    }

    .tags > span {
        padding: 3px 5px;
        margin-right: 5px;
        background-color: #FDF1EB;
        color: #EC7338;
        font-size: 13px;
    }

    .game-desc {
        width: 90%;
        background-color: #FFFFFF;
        border-radius: 5px;
        margin: 10px 0;
        text-align: left;
        padding: 15px 10px;
        box-sizing: border-box;
        color: #333333;
        font-size: 13px;
    }

    .game-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        height: 70%;
        margin-left: 15px;
    }

    .ios-div {
        margin-top: 10px;
        color: #e55825;
        border: 1px solid #e55825;
        font-size: 13px;
        font-weight: bold;
        padding: 5px 15px;
        border-radius: 5px;
    }

    .code-div {
        background-color: #FFFFFF;
        border-radius: 5px;
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 15px;
        margin-top: 10px;
        box-sizing: border-box;
    }

    .overlay-panel {
        text-align: right;
        margin-right: 15px;
        margin-top: 10px;
    }

    .overlay-info {
        text-align: center;
        width: 80%;
        margin: 20px auto;
        border-radius: 60px;
        border: 2px dashed white;
        color: white;
        font-weight: 600;
    }

</style>
