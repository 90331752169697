<template>
  <div class="all-page">
    <div class="header-panel">
      <div class="logo-panel">
        <van-image :src="logo" width="3rem"></van-image>
        <span style="color: white;margin-left: 10px;font-size: 18px;font-weight: 700">七里游戏下载</span>
      </div>
    </div>

    <div class="main-page">
      <div class="top-panel">
        <div class="top-inner">
          <van-image :src="logoUrl" class="game-logo-div"></van-image>
          <div class="name-div">{{ this.gameName }}</div>
          <van-button type="primary" color="#7456ea" class="down-button" @click="downloadGame">下载游戏</van-button>
          <div class="ios-div" @click="goGuide" v-if="!isSelfSigned&&downloading">iOS授权示例</div>
          <div class="ios-div" @click="goSet" v-if="isM" v-show="downloading&&isSelfSigned">去安装证书</div>
        </div>

      </div>
      <div v-if="!isM" class="code-div">
        <div class="code-inner">
          <div class="qr-code" ref="qrCodeUrl"></div>
          <p>扫描二维码下载游戏</p>
        </div>

      </div>

    </div>
    <van-overlay :show="overlayShow">
      <div class="overlay-panel">
        <div>
          <van-image :src="wjtImg" width="80px" height="80px"></van-image>
        </div>
        <div class="overlay-info">
          <p>
            请点击右上角 ... 在默认浏览器中打开
          </p>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>

import downloadImg from '@/assets/downloadback/button_download.jpg'
import guideImg from '@/assets/downloadback/button_guide.jpg'
import logo from '@/assets/logo.png'
import wjtImg from '@/assets/wjt.png'
import QRCode from 'qrcodejs2'
import {getDownParam} from '@/api/request'
import {Toast} from 'vant'

export default {
  name: 'downloadold',
  data() {
    return {
      isSelfSigned: true,
      logoUrl: '',
      gameName: '',
      downloadImg,
      guideImg,
      wjtImg,
      logo,
      thisUrl: '',
      androidUrl: '',
      iosUrl: '',
      qrCodeShow: false,
      iosUrlToSet: 'https://game.7li.cn/static/download/toset.mobileprovision',
      isM: false,
      downloading: false,
      overlayShow: false,
      gameCode: '',

    }
  },
  mounted() {
    this.gameCode = this.$route.path.split('/')[2]
    this.getGameName(this.gameCode)
    this.thisUrl = window.location.href
    this.iosUrl = "https://game.7li.cn/static/download/udid_"+this.gameCode+".mobileconfig"
    this.logoUrl= "https://qili-data.oss-cn-qingdao.aliyuncs.com/oldlogo/"+this.gameCode+".png"
    if (this.isWeiXin()) {
      this.overlayShow = true
    } else {
      this.getDownLoad()
    }
  },
  methods: {
    getGameName(code){
      if(code=="yzsy_old"){
        this.gameName="远征手游";
      }
      else if(code=="7liyx_old"){
        this.gameName="七里游戏";
      }
      else if(code=="hdqy_old"){
        this.gameName="混沌起源";
      }
      else if(code=="nuhuoyidao_old"){
        this.gameName="怒火一刀";
      }
      else if(code=="nuhuoyidao_new"){
        this.gameName="怒火一刀";
      }
    },
    isWeiXin() {
      let ua = window.navigator.userAgent.toLowerCase()
      //通过正则表达式匹配ua中是否含有MicroMessenger字符串
      return ua.match(/MicroMessenger/i) == 'micromessenger'
    },
    isMobile() {
      let browser = {
        versions: function () {
          let u = navigator.userAgent, app = navigator.appVersion;
          return {
            trident: u.indexOf('Trident') > -1, //IE内核
            presto: u.indexOf('Presto') > -1, //opera内核
            webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1,//火狐内核
            mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
            android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1, //android终端
            iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
            iPad: u.indexOf('iPad') > -1, //是否iPad
            webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
            weixin: u.indexOf('MicroMessenger') > -1, //是否微信 （2015-01-22新增）
            qq: u.match(/\sQQ/i) == " qq" //是否QQ
          };
        }(),
        language: (navigator.browserLanguage || navigator.language).toLowerCase()
      }
      // return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      return browser.versions.mobile || browser.versions.android || browser.versions.ios;
    },
    creatQrCode() {
      this.qrCode = new QRCode(this.$refs.qrCodeUrl, {
        text: this.thisUrl,
        width: 150,
        height: 150,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H

      })
    },
    downloadGame() {
          let msg = '';
          if (this.isSelfSigned) {
            msg = "点击允许后，再点击去安装证书，找到刚下载好的描述文件点击安装，输入锁屏密码"
          } else {
            msg = '点击允许安装后，在桌面查看下载进度，下载完毕之后设置信任，操作步骤查看ios授权示例';
          }
          Toast({
            message: msg,
            position: 'bottom',
            duration: 60000
          })
          window.location.href = this.iosUrl
          this.downloading = true
    },
    goGuide() {
      this.$router.push({path: '/download/iosGuide'})
    },
    goSet() {
      window.location.href = this.iosUrlToSet;
    }
  }
}
</script>

<style scoped lang="scss">
.all-page {
  overflow: hidden;
  background-color: #eceaea;
  width: 100%;
  height: 100vh;

  .header-panel {
    width: 100%;
    height: 70px;
    background-color: #37414d;
    text-align: center;

    .logo-panel {
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
      display: flex;
      height: 100%;
      align-content: center;
      align-items: center;
      justify-content: center;
    }
  }

  .main-page {
    overflow: hidden;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;

    .top-panel {
      margin: 10px 15px;
      width: 100%;

      .top-inner {
        margin: 0 15px;
        padding-bottom: 20px;
        border-radius: 5px;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;

        .game-logo-div {
          width: 100px;
          display: block;
          margin-top: 30px;
        }

        .name-div {
          width: 100px;
          display: block;
          font-weight: bold;
          margin-top: 20px;
        }

        .ios-div {
          width: 100px;
          display: block;
          color: #7456ea;
          margin-top: 30px;
        }

        .down-button {
          width: 100px;
          display: block;
          font-weight: bold;
          margin-top: 30px;
        }
      }

    }

    .code-div {
      width: 100%;

      bottom: 5rem;

      .code-inner {
        margin: 0 15px;
        background-color: white;
        border-radius: 5px;
        padding-bottom: 10px;

        p {
          font-weight: 600;
          background-color: white;
          border-radius: 5px;
        }

        .qr-code {
          padding: 10px;
          border-radius: 10px;
          background-color: white;

          /deep/ img {
            display: inline !important;
          }
        }
      }

    }


  }

  .van-overlay {
    .overlay-panel {
      text-align: right;

      .overlay-info {
        text-align: center;
        width: 80%;
        margin: 20px auto;
        border-radius: 60px;
        border: 2px dashed white;
        color: white;
        font-weight: 600;
      }
    }
  }
}

</style>
