import { render, staticRenderFns } from "./ydcsls.vue?vue&type=template&id=52bf1102&scoped=true&"
import script from "./ydcsls.vue?vue&type=script&lang=js&"
export * from "./ydcsls.vue?vue&type=script&lang=js&"
import style0 from "./ydcsls.vue?vue&type=style&index=0&id=52bf1102&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52bf1102",
  null
  
)

export default component.exports