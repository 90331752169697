<template>
  <div class="back-div">
  <div class="main-page">
    <!--<van-button @click="testRole">角色</van-button>-->
    <iframe v-show="true" ref="gameIframe" :src="gameUrl" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>

  </div>
  </div>
</template>

<script>

import {sdkGetGameUrl,sdkPreOrder,sdkUploadRole} from '@/api/request'
import mainRoundImg from "@/assets/main-round.png";
import { Toast } from 'vant'
import * as $ from 'jquery';

export default {
  name: 'woyang',
  data() {
    return {
      mainRoundImg,
      channelId: '106',//固定值
      game_id: '',
      username: '',
      user_id: '',
      time: '',
      server_id:'',
      localUserId: '',
      sign:'',
      gameUrl: '',
    };
  },
  mounted() {
    this.game_id = this.$route.query.game_id;
    this.username = this.$route.query.username;
    this.user_id = this.$route.query.user_id;
    this.time = this.$route.query.time;
    this.server_id = this.$route.query.server_id;
    this.sign = this.$route.query.sign;
    localStorage.setItem("channelId", this.channelId);
    localStorage.setItem("gameId", this.game_id);
    if (!this.gameUrl) {
      let params = {
        channelId: this.channelId ,
        game_id: this.game_id ,
        user_id: this.user_id ,
        username: this.username ,
        time: this.time ,
        server_id: this.server_id ,
        sign: this.sign ,
      };
      sdkGetGameUrl(params).then(res => {
        if(res.data.success) {
          this.gameUrl = res.data.data.loginUrl;
          this.localUserId = res.data.data.userId;
          console.log('返回游戏页面', this.gameUrl);
        }
        else {
          Toast.fail('拉取游戏数据异常');
        }
      });
    }
    this.initListener();
  },
  methods: {
    initListener() {
      window.addEventListener("message", this.listener);
    },
    listener(event) {
      console.dir(event);
      let me = this;
      if (event.data.operation === 'pay') {
        console.log("sdk触发支付");
        let order = event.data.param;
        me.preOrder(order);
      }
      else if (event.data.operation === 'uploadGameRole') {
        console.log("sdk触发角色上报");
        let param = event.data.param;
        me.uploadRole(param);
      }
      else if (event.data.operation === 'repeatLogin') {
        console.log("sdk触发登陆被顶");
        me.logout();
      }
    },
    preOrder(order) {
      order.channelId = this.channelId;
      order.userId = this.localUserId;
      order.cpOrder = order.cpOrderId;
      order.serviceId = order.server;
      order.serviceName = order.server;
      order.desc = order.goodsName;
      order.roleName = order.role;
      order.roleId = order.role;

      sdkPreOrder(order).then(res => {
        if(res.data.success) {
          window.parent.window.postMessage(JSON.stringify({type: "open", params: res.data.data}), '*');
        }
        else {
          Toast.fail(res.data.error.message);
        }
      });
    },
    testRole() {
      let roleParam = {
        gameId: "1",
        userId: "10",
        serverId: "1",
        serverName: "昊天二区",
        roleId: "1",
        roleName: "⻆⾊名",
      };
      this.uploadRole(roleParam);
    },
    uploadRole(roleParam) {
      roleParam.channelId = this.channelId;
      roleParam.serviceId = roleParam.serverId;
      roleParam.serviceName = roleParam.serverName;
      sdkUploadRole(roleParam).then(res => {
        if(res.data.success) {
          console.log("上报后台角色信息成功");
          let role = {
            role_id: roleParam.roleId,
            role_name: roleParam.roleName,
            server_id: roleParam.serverId,
            server_name: roleParam.serverName,
            event: '1',
            role_level: 1,
            role_vip: 1,
            combat_num: '1'
          }
          let data = {
            role: role,
            format: 'jsonp',
            extra: '',
            app_id: res.data.data.game_id,
            user_id: res.data.data.user_id
          }

          $.ajax({
            url: 'https://api.sy12306.com/v8/user/uprole',
            type:'post',
            data: data,
            dataType: 'jsonp',
            success: function (res) {
              console.log(res)
              if(res.code === 200){
                console.log('角色上报成功');
              }
              else {
                console.log('角色上报失败');
              }
            },
            error:function(e) {
              console.log('角色上报失败，接口调用异常');
            }
          });
        }
        else {
          console.log("上报后台角色信息失败");
        }
      });
    },
    logout() {
       console.log("登出");
    },
    testPay() {
      let order = {
        goodsName: '测试商品',
        money: '200',
        gameId: '101',
      };
      this.openPayPage(order);
    },
  }
}
</script>

<style scoped lang="scss">
.back-div{
  background-color: #eceaea;

  .main-page {
    position: relative;
    max-width: 600px;
    background-color: #ffffff;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    touch-action: none;
    margin: 0 auto;
    .main-dialog{

    }

    .loading-dialog {
      position: fixed;
      top: 40%;
      left: 20%;
      width: 60%;
      height: 5rem;
    }
  }
}


</style>
