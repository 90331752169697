<template>
  <div class="back-div">
  <div class="main-page" ref="mainPage">
    <iframe v-show="true" ref="gameIframe" :src="gameUrl" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
  </div>
  </div>
</template>

<script>

  import {hantianGetGameUrl, hantianPreOrder, hantianUploadRole} from '@/api/request'
  import mainRoundImg from "@/assets/main-round.png";
  import {Toast} from 'vant'

  export default {
  name: 'hantian',//晗天 联运
  data() {
    return {
      mainRoundImg,
      channelId: '206',//固定值
      u_token: '',
      time: '',

      appid: '',
      gameId: '',
      uid: '',
      localUserId: '',
      gameUrl: '',
    };
  },
  mounted() {
    this.gameId = this.$route.path.split('/')[3];
    this.u_token = this.$route.query.u_token;
    this.time = this.$route.query.time;

    localStorage.setItem("channelId", this.channelId);
    //localStorage.setItem("gameId", this.game_id);
    if (!this.gameUrl) {
      let params = {
        channelId: this.channelId ,
        gameId: this.gameId ,
        u_token: this.u_token ,
        time: this.time ,
      };
      hantianGetGameUrl(params).then(res => {
        if(res.data.success) {
          if (res.data.data.screenType === 1) {
            this.$refs.mainPage.style.maxWidth = "600px";
          }
          this.gameUrl = res.data.data.loginUrl;
          this.localUserId = res.data.data.userId;
          console.log('返回游戏页面', this.gameUrl);
        }
        else {
          Toast.fail('拉取游戏数据异常');
        }
      });
    }
    this.initListener();
  },
  methods: {
    initListener() {
      window.addEventListener("message", this.listener);
    },
    listener(event) {
      let me = this;
      if (event.data.operation === 'pay') {
        console.log("sdk触发支付");
        let order = event.data.param;
        me.preOrder(order);
      }
      else if (event.data.operation === 'uploadGameRole') {
        console.log("sdk触发角色上报");
        let param = event.data.param;
        me.uploadRole(param);
      }
      else if (event.data.operation === 'repeatLogin') {
        console.log("sdk触发登陆被顶");
        me.logout();
      }
    },
    preOrder(order) {
      order.channelId = this.channelId;
      order.userId = this.localUserId;
      order.cpOrder = order.cpOrderId;
      order.serviceId = order.server;
      order.serviceName = order.server;
      order.desc = order.goodsName;
      order.roleName = order.role;
      order.roleId = order.role;

      hantianPreOrder(order).then(res => {
        if(res.data.success) {
          let sign = res.data.data.sign;
          //调起支付界面
          let data = {
            money: order.money / 100,
            appid: res.data.data.appId,
            serverid: order.server,
            uid: res.data.data.user_id,
            amount: order.money / 100,
            extinfo: res.data.data.orderId,
            sign: sign
          };
          console.log('准备拉起支付页面,参数为：', data);
          top.postMessage(data,'*');

        }
        else {
          Toast.fail(res.data.error.message);
        }
      });
    },
    uploadRole(roleParam) {
      roleParam.serviceId = roleParam.serverId;
      roleParam.serviceName = roleParam.serverName;

      hantianUploadRole(roleParam).then(res => {
        if(res.data.success) {
         /* console.log("上报后台角色信息成功");
          let sign = res.data.data.sign;
          let params = {
            uid: this.uid,
            charid: roleParam.serverId,
            appid: this.appid,
            serverid: roleParam.serverId,
            role_name: roleParam.roleName,
            sign: sign,
          }
          this.roleSync(params).then(r => {
            if (r.data.code === 1) {
              console.log('hantian角色上报成功');
            }
            else {
              console.log(r.data.message);
            }
          })*/
        }
        else {
          console.log("上报后台角色信息失败");
        }
      });
    },
    logout() {
      console.log("登出");
    },
  }
}
</script>

<style scoped lang="scss">
.back-div{
  background-color: #eceaea;

  .main-page {
    position: relative;
    background-color: #ffffff;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    touch-action: none;
    margin: 0 auto;
    .main-dialog{

    }

    .loading-dialog {
      position: fixed;
      top: 40%;
      left: 20%;
      width: 60%;
      height: 5rem;
    }
  }
}


</style>
