<template>
    <div class="all-page">
        <div class="main-page">
          <van-image :src="logo" class="logo-div"></van-image>
          <div class="name-div">{{this.gameName}}</div>
          <van-button type="primary" class="down-button" v-show="!downloading" @click="downloadGame">下载游戏</van-button>
          <div class="ios-div" @click="goGuide" v-if="isM" v-show="downloading">去安装证书</div>
            <div  v-if="!isM" class="code-div">
                <div  class="qr-code" ref="qrCodeUrl"></div>
                <p>扫描二维码下载游戏</p>
            </div>

        </div>
        <van-overlay :show="overlayShow">
            <div class="overlay-panel">
                <div>
                    <van-image :src="wjtImg" width="80px" height="80px" ></van-image>
                </div>
                <div class="overlay-info">
                    <p>
                        请点击右上角 ... 在默认浏览器中打开
                    </p>
                </div>
            </div>


        </van-overlay>
    </div>
</template>

<script>
  import wjtImg from '@/assets/wjt.png'
  import logo from '@/assets/logo.png'
  import QRCode from 'qrcodejs2'
  import {Toast} from "vant";

  export default {
    name: 'download_h5_old',
    data() {
      return {
        logo,
        wjtImg,
        logoUrl:'',
        thisUrl: '',
        androidUrl: '',
        iosUrl: '',
        iosUrlToSet: 'https://game.7li.cn/static/download/toset.mobileprovision',
        qrCodeShow: false,
        isM: false,
        downloading: false,
        overlayShow: false,
        gameName:''
      }
    },
    mounted() {
      let gameCode= this.$route.path.split('/')[2];
      let channelId = this.$route.path.split('/')[3];
      this.iosUrl="https://qili-data.oss-cn-qingdao.aliyuncs.com/oldsystemiphone/"+gameCode+"_"+channelId+".mobileconfig"
      this.androidUrl = "https://qili-data.oss-cn-qingdao.aliyuncs.com/shengshiwd/channel/"+gameCode+"/"+gameCode+"_"+channelId+"_1.2.2.apk"
      this.gameName = gameCode
      this.thisUrl = window.location.href;
      this.isM = this.isMobile();
      if (!this.isM) {
        this.creatQrCode();
      }
      if (this.isWeiXin()) {
        this.overlayShow = true;
      }
    },
    methods: {

      isWeiXin(){
        let ua = window.navigator.userAgent.toLowerCase();
        //通过正则表达式匹配ua中是否含有MicroMessenger字符串
        return ua.match(/MicroMessenger/i) == 'micromessenger';
      },
      isMobile() {
        let browser={
          versions:function(){
            let u = navigator.userAgent, app = navigator.appVersion;
            return {
              trident: u.indexOf('Trident') > -1, //IE内核
              presto: u.indexOf('Presto') > -1, //opera内核
              webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
              gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1,//火狐内核
              mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
              ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
              android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1, //android终端
              iPhone: u.indexOf('iPhone') > -1 , //是否为iPhone或者QQHD浏览器
              iPad: u.indexOf('iPad') > -1, //是否iPad
              webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
              weixin: u.indexOf('MicroMessenger') > -1, //是否微信 （2015-01-22新增）
              qq: u.match(/\sQQ/i) == " qq" //是否QQ
            };
          }(),
          language:(navigator.browserLanguage || navigator.language).toLowerCase()
        }
        // return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
        return browser.versions.mobile||browser.versions.android||browser.versions.ios;
      },
      creatQrCode() {
        this.qrCode = new QRCode(this.$refs.qrCodeUrl, {
          text: this.thisUrl,
          width: 150,
          height: 150,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H

        });
      },
      downloadGame() {
        if (this.isMobile()) {
          let ua = navigator.userAgent.toLowerCase();;
          if (/iphone|ipad|ipod/.test(ua)) {//判断是否ios
            Toast({
                message: "点击允许后，再点击去安装证书，找到 对应游戏文件 点击安装，输入锁屏密码，安装之后，在桌面进行查看",
                position: 'center',
                duration: -1
            });
            window.location.href = this.iosUrl;
            this.downloading = true;
          }
          else {
            window.location.href = this.androidUrl;
          }
        }else {
          window.location.href = this.androidUrl;
        }
      },
      goGuide() {
       window.location.href=this.iosUrlToSet;
      }
    }
  }
</script>

<style scoped lang="scss">
    .all-page{
        overflow: hidden;
        background-color: #eceaea;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      .main-page{
        overflow: hidden;
        width: 100%;
        max-width: 500px;
        height: 100%;
        background-color: white;
        text-align: center;

        .logo-div{
          z-index: 1;
          position: fixed;
          top: 2%;
          left: 2%;
          width: 100px;
          display: block;

        }
        .game-logo-div{
          z-index: 1;
          position: fixed;
          top: 20%;
          left: calc(50% - 50px);
          width: 100px;
          display: block;

        }
        .name-div{
          z-index: 1;
          position: fixed;
          top: 15%;
          left: calc(50% - 50px);
          width: 100px;
          display: block;
          font-weight:bold;
        }
        .ios-div{
          z-index: 1;
          position: fixed;
          top: 55%;
          left: calc(50% - 50px);
          width: 100px;
          display: block;
          color:#00F;
        }
        .down-button{
          z-index: 1;
          position: fixed;
          top: 45%;
          left: calc(50% - 50px);
          width: 100px;
          display: block;
          font-weight:bold;
        }

        .code-div{
          position: fixed;
          top: auto;
          left: calc(50% - 85px);
          bottom: 5rem;
          p{
            font-weight: 600;
            background-color: white;
            border-radius: 5px;
          }
          .qr-code{
            padding: 10px;
            border-radius: 10px;
            background-color: white;

            /deep/ img{
              display: inline !important;
            }
          }
        }


      }

        .van-overlay{
            .overlay-panel{
                text-align: right;
                .overlay-info{
                    text-align: center;
                    width: 80%;
                    margin: 20px auto;
                    border-radius: 60px;
                    border: 2px dashed white;
                    color: white;
                    font-weight: 600;
                }
            }
        }
    }
</style>
