<template>
  <div class="main-container">
    <div class="left-top-title">
      <van-image class="back-icon" :src="backIcon" @click="back" width="2rem"></van-image>
      <h2>联系客服</h2></div>
    <div class="top-label" >
      <van-image :src="qqIcon" width=".8rem"></van-image>
      <span>QQ在线客服</span>
    </div>
    <div class="cs-qq-panel">
      <span v-for="item in qqService">{{item}}</span>
    </div>

    <div class="top-label" >
      <van-image :src="wxIcon" width=".8rem"></van-image>
      <span>微信公众号</span>
    </div>
    <div class="cs-wx-panel">
      <p>公众号搜索“{{subscriptionName}}”，更多福利等着你</p>
      <van-image :src="qiliQrcodeImg" width="5.8rem"></van-image>
    </div>
  </div>
</template>

<script>
  import {getConfig} from '@/api/request';

import qqIcon from "@/assets/qqkf-icon.png";
import wxIcon from "@/assets/wx.png";
import backIcon from "@/assets/back.png";
import qiliQrcodeImg from "@/assets/qili-qrcode.png";
  import { Toast } from 'vant'

export default {
  name: 'kf',
  data() {
    return {
      qiliQrcodeImg: '',
      qqIcon,
      wxIcon,
      backIcon,
      qqService: [],
      subscriptionName: '',
    };
  },
  mounted() {
    let params = {
      device: 'H5'
    };
    getConfig(params).then(res => {
      this.loading = false;
      if(res.data.success) {
        this.qqService = res.data.data.qqList;
        this.subscriptionName = res.data.data.qrCodeName;
        this.qiliQrcodeImg = res.data.data.qrCodeUrl;
      }
      else {
        Toast.fail(res.data.error.message);
      }
    });
  },
  methods: {
    back() {
      this.$parent.$parent.closeKfPage();
    }
  }
}
</script>

<style scoped lang="scss">
.main-container {
  overflow: hidden;
  margin: .5rem .3rem .8rem;
    .left-top-title{
      display: flex;
      /*实现垂直居中*/
      align-items: center;
      justify-content: left;
      width: 100%;
      padding-left: .2rem;
      color: #666666;
      vertical-align: center;
      height: 3rem;
      line-height: 3rem;
      .back-icon{
        display: inline-block;
      }
      h2{
        display: inline-block;
        font-size: 1.3rem;
        margin: 0;
      }

    }

    .top-label{
      display: flex;
      /*实现垂直居中*/
      align-items: center;
      justify-content: left;
      width: 100%;
      text-align: left;
      padding: 0 .8rem;
      color: #666666;
      font-size: .8rem;
      span{
        margin-left: .5rem;
      }
      image{
        margin-top: .1rem;
      }
    }

    .cs-qq-panel {
      padding: .5rem 1.5rem;
      text-align: left;
      color: dodgerblue;
      span{
        margin: 0 .8rem;
        display: inline-block;
        height: 1.6rem;
        line-height: 1.6rem;
      }
    }

    .cs-wx-panel{
      text-align: left;
      padding: .5rem 1.5rem;
      margin-left: .8rem;
      p{
        margin: 0 0 .8rem;
        font-size: .8rem;
        color: #999999;
      }
    }

    .down-input{
      margin-top: .8rem;
    }
    .van-cell{
      padding: 12px 16px;
    }
}
</style>
