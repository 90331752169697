<template>
    <div class="login-page">
        <div v-if="showBack" style="text-align: left;width: 100%;position: absolute;top: 40px;padding-left: 20px;">
            <van-icon name="arrow-left" size="25" @click="back"/>
        </div>

        <div style="width: 100%;margin-top: 40px;text-align: left;padding: 10px 25px;font-size: 16px;box-sizing: border-box;display: flex;align-items: center;">
            <img src="../assets/qiliLogo.png" style="width: 80px;height: 80px;">
            <div style="margin-left: 20px;">
                <div>您好</div>
                <div>欢迎来到<span style="font-weight: bold">七里游戏</span></div>
            </div>
        </div>
        <div class="main-border">

            <van-form @submit="onSubmit" ref="submitForm">

                <div style="font-size: 18px;font-weight: bold;text-align: left;margin-bottom: 15px;">
                    <span>请先登录</span>
                </div>

                <van-field label-width="40px" label="帐号" style="color: #F18F25" placeholder="请输入七里帐号"
                           class="border-input" v-model="account" clearable>
                    <template #button>
                        <van-icon name="arrow-down" @click="selectOtherAccount"/>
                    </template>
                </van-field>

                <van-field label-width="40px" type="password" label="密码" style="color: #F18F25;margin-top: 20px;"
                           placeholder="请输入登录密码" class="border-input" v-model="password" clearable/>

                <van-popup v-model="showPicker" round position="bottom">
                    <van-picker
                        show-toolbar
                        :columns="accountColumns"
                        @cancel="showPicker = false"
                        @confirm="pickAccount"
                        @change="onChange"
                    />
                </van-popup>

                <div
                    style="display: flex;align-items: center;justify-content: space-between;margin-top: 20px;font-size: 12px;padding: 0 5px;">

                    <van-checkbox v-model="rememberPassword" icon-size="14px" shape="square" checked-color="#606CFD">
                        <span style="color: #666666;font-size: 12px;">记住密码</span>
                    </van-checkbox>

                    <span @click="toForgetPage" style="color: #3478F6">忘记密码?</span>
                </div>

                <div class="box-button">
                    <van-button type="info" block native-type="submit" :loading="loading" style="height: 35px;"
                                loading-text="登录中"
                                color="#5A6EFC">
                        登 录
                    </van-button>
                    <div class="text-button">还没有帐号？<span @click="toRegisterPage"
                                                              style="color: #3478F6">立即注册</span></div>
                </div>
            </van-form>

        </div>
    </div>
</template>

<script>

import {login} from '@/api/request'
import crypto from '@/utils/crypto.js';
import accountIcon from "@/assets/account.png";
import passwordIcon from "@/assets/password.png";

import {Toast} from 'vant'

export default {
    name: 'login',
    data() {
        return {
            accountIcon,
            passwordIcon,
            account: '',
            password: '',
            rememberPassword: false,
            accountPattern: /^[a-zA-Z0-9_]{6,12}$/,
            passwordPattern: /^[a-zA-Z0-9~!@#$%^&*()_+.]{6,12}$/,
            loading: false,
            channelId: '',
            gameId: '',
            accountColumns: [],
            showPicker: false,
            userList: [],
            currentIndex: 0,
            showBack: false,
            from: '',
        };
    },
    mounted() {
        this.channelId = localStorage.getItem('channelId');
        this.gameId = localStorage.getItem('gameId');

        let isRemember = localStorage.getItem('rememberPassword');
        if (isRemember === "true") {
            this.rememberPassword = true;
            this.account = localStorage.getItem('username');
            this.password = crypto.decrypt(localStorage.getItem('password'));
        }
        let from = localStorage.getItem('from');
        if (from === 'qiliApp') {
            this.showBack = true;
        }

        this.userList = JSON.parse(localStorage.getItem('userList')) || [];
        if (this.userList.length > 0) {
            this.userList.forEach((v, i) => {
                this.accountColumns.push(v.u);
            })
        }
    },
    methods: {
        back() {
            this.$router.back();
        },
        onSubmit() {
            let ok = this.checkForm();
            if (ok !== 'ok') {
                Toast(ok);
                return;
            }
            this.loading = true;
            let params = {
                channelId: this.channelId,
                device: 'H5',
                gameId: this.gameId,
                userName: this.account,
                password: crypto.encrypt(this.password)
            };
            login(params).then(res => {
                this.loading = false;
                if (res.data.success) {
                    localStorage.setItem('token', res.data.data.token);
                    localStorage.setItem('user', JSON.stringify(res.data.data));
                    localStorage.setItem('loginUrl', res.data.data.loginUrl);

                    this.handlerRememberPassword();
                    Toast.success('登录成功');
                    //this.$router.replace({ path: `/play/${this.channelId}/${this.gameId}`});
                    this.$parent.enterGame();
                } else {
                    Toast.fail(res.data.error.message);
                }
            });
        },
        checkForm() {
            let ok = 'ok';
            if (!this.account) {
                ok = "请输入七里帐号"
            } else if (!this.password) {
                ok = "请输入密码";
            } else if (!this.accountPattern.test(this.account)) {
                ok = "七里帐号为6-12位数字或字母";
            } else if (!this.passwordPattern.test(this.password)) {
                ok = "密码为6-12位数字字母特殊符号";
            }
            return ok;
        },
        selectOtherAccount() {
            if (this.accountColumns.length > 0) {
                this.showPicker = true;
            } else {
                Toast('当前没有其他账号');
            }

        },
        pickAccount() {
            this.showPicker = false;
            let user = this.userList[this.currentIndex];
            this.account = user.u;
            this.password = crypto.decrypt(user.p);
        },
        onChange(picker, value, index) {
            this.currentIndex = index;
        },
        toForgetPage() {
            this.$router.push({path: '/reset'});
        },
        toRegisterPage() {
            this.$router.push({path: '/register'});
        },
        handlerRememberPassword() {
            localStorage.setItem('rememberPassword', this.rememberPassword);

            this.userList = JSON.parse(localStorage.getItem('userList')) || [];
            let has = false;
            let index = -1;
            this.userList.forEach((v, i) => {
                if (v.u === this.account) {
                    has = true;
                    index = i;
                }
            })

            let password = crypto.encrypt(this.password);
            localStorage.setItem('username', this.account);
            localStorage.setItem('password', password);

            if (this.rememberPassword) {
                if (!has) {
                    let item = {
                        u: this.account,
                        p: password,
                    }
                    this.userList.push(item);
                    localStorage.setItem('userList', JSON.stringify(this.userList));
                } else if (password !== this.userList[index].p) {
                    this.userList[index].p = password;
                    localStorage.setItem('userList', JSON.stringify(this.userList));
                }
            } else {
                if (has) {
                    this.userList.splice(index, 1);
                    localStorage.setItem('userList', JSON.stringify(this.userList));
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
.login-page {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    .main-border {
        max-width: 500px;
        text-align: center;
        background-color: white;
        border-radius: .3rem;
        width: 84%;
        border: 1px solid #eceaea;
        padding: 20px 20px;
        box-sizing: border-box;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

        .select-other {
            margin-top: 10px;
            display: block;
            font-size: 12px;
            color: #b3b3b6;
        }

        .width-line {
            height: 1px;
            margin: 0 1.04rem;
            border-bottom: 1px solid #b3b3b6;
        }

        .forget-row {
            margin-top: 1.5rem;
            font-size: .8rem;
        }

        .box-button {
            margin: 20px 3px 0;

            .text-button {
                font-size: .8rem;
                margin-top: .8rem;
                color: #888888;
                text-align: right;
            }
        }
    }

    .van-field {
        outline: 1px #dbdbdb solid;
        border-radius: 3px;
    }

    .van-field:focus-within {
        outline: 2px #3478F6 solid;
    }

    /deep/ .van-field__control {
        //color: #3478F6;
    }

}
</style>
