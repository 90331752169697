<template>
    <div class="main-view">

        <div style="display: flex;align-items: center;justify-content: center;">
            <img src="../../assets/logo.png" style="width: 60px;height: 60px;">
            <div class="login-game">
                <span>七里网络</span>
                <span style="font-size: 12px;color: #888888">游戏快捷登录</span>
            </div>
        </div>

        <div style="margin-top: 10px">
            <div style="font-weight: 600">请选择游戏账号</div>
            <div style="font-size: 10px;padding: 0 25px;text-align: left;color: #888888;margin-top: 10px;">
                使用七里网络快捷登录需要关注《七里网络》公众号，且需将要登录的游戏帐号与扫描的微信绑定
            </div>
            <div v-if="!isLogin" class="account-list">
                <div v-if="listData.length > 0" class="account-item" v-for="(item,index) in listData" :class="{liActive: curIndex === index}"
                     @click="onSelect(item.userId,index)">
                    <div class="liCell" :style="curIndex === index ? 'color: #FF7900' : 'color: #666666'">
                        <span style="font-weight: bold">{{ item.username }}</span>
                        <span style="font-size: 14px;">uid {{ item.userId }}</span>
                    </div>
                </div>
                <div v-if="listData.length === 0" style="color: #888888;font-size: 16px;margin-top: 20px;">
                  <span>未查询到相关帐号</span>
                </div>

            </div>

            <div v-if="isLogin" style="width: 80%;height: 300px;line-height: 300px;font-size: 36px;margin: 0 auto">
                登录成功
            </div>
        </div>
        <div v-if="!isLogin" style="position: relative;top: 20px">
            <van-button type="info" :disabled="curIndex === -1"
                        style="width: 150px;border-radius: 8px;background-color: #FF7900;" color="#FF7900"
                        :loading="loading" @click="onSubmit">确认登录
            </van-button>
            <br/>
            <button class="ql-button-text" @click="cancelLogin">取消登录</button>
        </div>
        <van-overlay :show="showOverlay">
            <div class="overlay-wrapper" @click.stop>
                <div class="overlay-block">
                    <div style="font-size: 20px;font-weight: 600;white-space: pre-line">
                        请先关注七里网络公众号<br>
                        绑定七里账号
                    </div>
                </div>
            </div>
        </van-overlay>
        <van-overlay :show="overlayShow">
            <div class="overlay-panel">
                <div class="overlay-info">
                    <p>
                        请使用微信扫一扫打开
                    </p>
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
    import {commRequest, wxAuth} from "@/api/request";
    import {Toast} from "vant";

    export default {
        name: "wxLogin",
        data() {
            return {
                listData: [],
                curIndex: -1,
                openid: "",
                showOverlay: false,
                overlayShow: false,
                tempToken: "",
                codeId: "",
                userId: "",
                isLogin: false,
                loading: false,
            }
        },
        mounted() {
            if(!this.isWeiXin()){
              this.overlayShow = true;
              return;
            }
            if (this.$route.query.genCode) {
                localStorage.setItem("tempCode", this.$route.query.genCode);
            }
            this.doAuth();
        },
        methods: {
            isWeiXin() {
                let ua = window.navigator.userAgent.toLowerCase();
                //通过正则表达式匹配ua中是否含有MicroMessenger字符串
                return ua.match(/MicroMessenger/i) == 'micromessenger';
            },
            doAuth() {
                let url = window.location.href;
                let arr = url.split("#")[0];
                if (arr.indexOf('?') > -1 && arr.indexOf('&') > -1) {
                    arr = arr.split("?")[1].split("&");
                    let obj = {};
                    for (let i = 0; i < arr.length; i++) {
                        obj[arr[i].split('=')[0]] = arr[i].split('=')[1]
                    }
                    let params = {
                        code: obj.code
                    }
                    this.UserAccount(params);
                } else {
                    let redirectUri = encodeURIComponent("https://game.7li.cn/#/wxLogin");
                    window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?" +
                        "appid=wxcd97d39a8899de98" +
                        "&redirect_uri=" + redirectUri +
                        "&response_type=code" +
                        "&scope=snsapi_base" +
                        "&state=" + new Date().getTime() +
                        "#wechat_redirect";
                }
            },
           /* test() {
                this.openid = 'oxgUMxJoeji_8KDCbWbRPlYMGsHg'
                this.codeId = 22
                let options = {
                    url: "/sdk/scanLogin/scanCode",
                    data: {
                        codeId: this.codeId,
                        openid: this.openid
                    }
                };
                commRequest(options).then(res => {
                    if (res.data.success) {
                        this.listData = res.data.data.userInfoList;
                        this.tempToken = res.data.data.tempToken;
                        if (this.listData.length > 0) {
                          this.curIndex = 0;
                          this.userId = this.listData[0].userId;
                        }
                    } else {
                        Toast.fail(res.data.error.message)
                    }
                })
            },*/

            UserAccount(params) {
                wxAuth(params).then(res => {
                    if (res.data.success) {
                        //this.openid = res.data.data.openid;
                        this.codeId = localStorage.getItem("tempCode");
                        let options = {
                            url: "/sdk/scanLogin/scanCode",
                            data: {
                                codeId: this.codeId,
                                openid: res.data.data.openid
                            }
                        };
                        commRequest(options).then(res => {
                            if (res.data.success) {
                                this.listData = res.data.data.userInfoList;
                                this.tempToken = res.data.data.tempToken;
                                if (this.listData.length > 0) {
                                    this.curIndex = 0;
                                    this.userId = this.listData[0].userId;
                                }

                            } else {
                                Toast.fail(res.data.error.message)
                            }
                        })
                    } else {
                        this.showOverlay = true;
                    }
                })
            },
            onSubmit() {
                this.loading = true;
                let options = {
                    url: "/sdk/scanLogin/confirmLogin",
                    data: {
                        codeId: this.codeId,
                        userId: this.userId,
                        tempToken: this.tempToken
                    }
                };
                commRequest(options).then(res => {
                    this.loading = false;
                    if (res.data.success) {
                        this.isLogin = true;
                        localStorage.removeItem("tempCode");
                        setTimeout(() => {
                          WeixinJSBridge.call("closeWindow");
                        }, 1500);
                    } else {
                        Toast.fail(res.data.error.message)
                    }
                })
            },
            cancelLogin() {
                WeixinJSBridge.call("closeWindow");
            },
            onSelect(userId, index) {
                this.curIndex = index;
                this.userId = userId;
            },
        }
    }
</script>

<style scoped lang="scss">
    .main-view {
        background-color: #fafafa;
        height: 100vh;
        padding-top: 50px;
    }

    .login-game {
        font-size: 20px;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 20px;
    }

    .account-list {
        width: 80%;
        height: 250px;
        margin: 15px auto;
        border-radius: 5px;
        overflow-y: auto;
        background-color: #ffffff;
        padding: 10px 10px;
        box-sizing: border-box;
    }

    .account-item {
        height: 40px;
        line-height: 40px;
        border: 2px solid #ffffff;
        color: #666666;
    }

    .liActive {
        border: 2px solid #FF7900;
        background-color: rgba(255, 121, 0, 0.15);
        color: #FF7900;
    }

    .liCell {
        text-align: left;
        display: flex;
        justify-content: space-between;
    }

    .liCell span {
        margin: 0 5px;
    }

    .ql-button-text {
        border: 0;
        background-color: transparent;
        display: inline-block;
        font-size: 14px;
        margin-top: 15px;
        color: #888888;
    }

    .ql-button-text:active {
        color: #417bbe;
    }

    .overlay-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .overlay-block {
        width: 300px;
        height: 200px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .van-overlay {
        .overlay-panel {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            .overlay-info {
                text-align: center;
                width: 80%;
                margin: 20px auto;
                border-radius: 60px;
                border: 2px dashed white;
                color: white;
                font-weight: 600;
            }
        }
    }

</style>