<template>
    <div class="main">
        <div class="main-header" v-if="step !== 3">
            <img src="../../assets/logo-new.jpg"  width="40px" style="border-radius: 3px">
            <div style="display: flex;flex-direction: column;align-items: flex-start">
                <span style="margin-left: 20px">
                    七里网络斗罗大陆加速器
                </span>
                <span style="margin-left: 40px;font-weight: normal;font-size: 12px;color: #cccccc;margin-top: 4px;">全平台通用版</span>
            </div>

        </div>
        <div class="main-center" v-if="step !== 3">
            <div class="form-view"  v-show="step === 1">
                <div class="logo-view">
                    <span class="top-span">使用加速器请先登录</span>
                </div>
                <div class="input-view">
                    <div style="text-align: left;margin: 10px 0 5px;font-size: 13px;">
                        <span>七里帐号</span>
                    </div>

                    <van-field style="color: #F18F25" placeholder="请输入七里帐号" class="border-input" v-model="username" clearable>
                    </van-field>

                    <div style="text-align: left;margin: 10px 0 5px;font-size: 13px;">
                        <span>密码</span>
                    </div>
                    <van-field style="" type="password"  placeholder="请输入登录密码" v-model="password" clearable></van-field>

                    <div style="margin-top: 15px;">
                        <van-checkbox v-model="remPassword" icon-size="15px" checked-color="#F18F25">
                            <span style="color: #999999;font-size: 13px;">记住密码</span>
                        </van-checkbox>
                    </div>

                    <div class="main-button" @click="doLogin">登录</div>
                    <div style="margin-top: 10px;display: flex;justify-content: flex-end">
                        <div style="color: #999999; font-size: 13px;">
                            没有帐号?<span style="color: #EA8519;margin-left: 6px;cursor: pointer" @click="goRegisterPage">立即注册</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-view"  v-show="step === 2">
                <div class="logo-view">
                    <span class="top-span">注册七里帐号</span>
                </div>
                <div class="input-view">
                    <div style="text-align: left;margin: 10px 0 5px;font-size: 13px;">
                        <span>七里帐号</span>
                    </div>

                    <van-field style="color: #F18F25" placeholder="请输入七里帐号" class="border-input" v-model="regUsername" clearable>
                    </van-field>

                    <div style="text-align: left;margin: 10px 0 5px;font-size: 13px;">
                        <span>密码</span>
                    </div>
                    <van-field style="" type="password"  placeholder="请输入密码" v-model="regPassword" clearable></van-field>
                    <div style="text-align: left;margin: 10px 0 5px;font-size: 13px;">
                        <span>确认密码</span>
                    </div>
                    <van-field style="" type="password"  placeholder="请再次输入密码" v-model="regPassword2" clearable></van-field>


                    <div class="main-button" @click="doRegister">注册</div>
                    <div style="margin-top: 10px;display: flex;justify-content: flex-end">
                        <div style="color: #999999; font-size: 13px;">
                            已有帐号?<span style="color: #EA8519;margin-left: 6px;cursor: pointer" @click="goLogin">去登录</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tips-view">
                <span style="font-size: 14px;font-weight: bold; margin: 15px 0">七里网络斗罗大陆加速器用法介绍</span>
                <span>1. 在本页面登录七里平台帐号，如无帐号可以点击“立即注册”按钮先注册七里平台帐号</span>
                <span>2. 登录成功后进入斗罗大陆通行证页面</span>
                <span>3. 在浏览器页面的左侧靠下位置，有七里网络的LOGO图标，点击图标打开加速页面的浮窗</span>
                <span>4. 在浮窗里面可以看到已经获取到了页面的通行证，并且已经复制到粘贴板</span>
                <span>5. 用浏览器新开一个标签页，打开您所在的平台的游戏页面，登录成功后，停留在选择区服页面</span>
                <span>6. 点击选择区服页面上的“通行证”按钮，将刚才复制好的通行证粘贴在弹出框中，点击确认</span>
                <span>7. 再次返回七里加速器页面，此时该页面已经成功进入游戏</span>
                <span>8. 点击七里网络的LOGO图标，在浮窗中点击需要加速的倍数，完成游戏加速</span>
            </div>

        </div>

        <div v-if="step === 3" class="game-main">
            <iframe v-show="true" allowfullscreen ref="gameIframe" id="gameIframe" :src="gameUrl" frameborder="0" scrolling="no" width="100%"
                    height="100%"></iframe>
        </div>

        <div v-if="step === 3" class="xuanfu" id="moveDiv"
             @mousedown="down($event)"
             @touchstart="down($event)"
             @mousemove="move($event)"
             @touchmove="move($event)"
             @mouseover="end($event)"
             @mouseleave="move($event)"
             @mouseup="end($event)"
             @touchend="end($event)"
             @click="showMainWindow">
              
            <img src="../../assets/logo-new.jpg" @click="showQiliDialog" width="40px" style="border-radius: 20px;cursor: pointer">
             
        </div>

        <div v-if="step === 3" class="qili-dialog" :class="qiliDialogShow ? 'show-dialog' : 'hide-dialog'">
            <div style="margin: 10px 10px;">
                <div style="display: flex;align-items: center">
                    <img src="../../assets/head.png" style="width: 50px;height: 50px;">
                    <div style="display: flex;flex-direction: column;align-items: flex-start;padding-left: 10px">
                        <div>
                            <span style="font-weight: bold">{{userInfo.userName}}</span>
                            <span @click="logout" style="font-size: 12px;margin-left: 10px;color: #888888;cursor: pointer;">[退出登录]</span>
                        </div>
                        <div  v-if="code !== ''" style="margin-top: 4px;font-size: 12px;">当前通行证: <span style="user-select: text">{{code}}</span>
                            <span style="margin-left: 10px;">已复制</span></div>
                    </div>

                </div>

                <img src="../../assets/x.png" @click="qiliDialogShow = false" style="width: 30px;height: 30px;position: absolute;right: 1px;top: 1px;cursor: pointer">
            </div>
            <div style="width: 100%;height: 1px;background-color: #e7e7e7"></div>
            <div style="display: flex;">
                <div style="flex: 1">
                    <div style="font-size: 14px;margin-top: 10px;text-align: left;margin-left: 15px;font-weight: bold">请选择加速倍率</div>
                    <div style="display: flex;justify-content: space-evenly;margin-top: 20px;">
                        <div @click="setSpeed(1)" class="speed-button" :style="speedRate === 1 ? 'background: #F05858;color: #ffffff' : ''">
                            原速
                        </div>
                        <div @click="setSpeed(3)" class="speed-button" :style="speedRate === 3 ? 'background: #F05858;color: #ffffff' : ''">
                            3倍
                        </div>
                        <div @click="setSpeed(5)" class="speed-button" :style="speedRate === 5 ? 'background: #F05858;color: #ffffff' : ''">
                            5倍
                        </div>
                    </div>
                    <div style="display: flex;justify-content: space-evenly;margin-top: 20px;">
                        <div @click="setSpeed(10)" class="speed-button" :style="speedRate === 10 ? 'background: #F05858;color: #ffffff' : ''">
                            10倍
                        </div>
                        <div @click="setSpeed(16)" class="speed-button" :style="speedRate === 16 ? 'background: #F05858;color: #ffffff' : ''">
                            16倍
                        </div>
                        <div @click="setSpeed(32)" class="speed-button" :style="speedRate === 32 ? 'background: #F05858;color: #ffffff' : ''">
                            32倍
                        </div>
                    </div>
                    <div style="color: #e59e1b;font-size: 12px;margin-top: 30px;font-weight: bold">当前游戏加速倍率：{{speedRate}}</div>
                </div>

                <div style="flex: 1;margin-top: 30px;font-size: 12px;color: #888888">
                    <img src="../../assets/qili-qrcode.png" style="width: 100px;height: 100px;">
                    <div style="margin-top: 10px;">魂师对决即将上线<br>更多详情请关注七里网络公众号</div>

                </div>

            </div>



        </div>
        <myloading v-show="showLoading"></myloading>
    </div>
</template>

<script>
    import {commRequest} from '@/api/request'
    import crypto from '@/utils/crypto.js';
    import { Dialog, Toast } from 'vant'
    import myloading from '@/components/myloading'
    import timerhook from '@/utils/timerhook';
    

    export default {
        name: "login",
        components: {
            myloading
        },
        data() {
            return {
                showLoading: false,
                remPassword: true,
                username: '',
                password: '',
                regUsername: '',
                regPassword: '',
                regPassword2: '',
                step: 1,
                userInfo: {},
                qiliDialogShow: false,

                usernameRegexp: /^[a-zA-Z0-9_]{6,12}$/,
                passwordRegexp: /^[a-zA-Z0-9~!@#$%^&*()_+.-=]{6,12}$/,
                gameUrl: '',

                position: {x: 0, y: 0},
                nx: '', ny: '', dx: '', dy: '', xPum: '', yPum: '',
                speedRate: 1,
                code: '',
            }
        },
        mounted() {
            this.initPage();
            this.initLoginStatus();
            this.checkIframeParam();
            
            window.addEventListener('message', this.listener);
        },
        methods: {
            initLoginStatus() {
                let token = localStorage.getItem('speed_token');
                if (!!token) {
                    let options = {
                        url:"/speedGame/checkToken/" + token,
                        data: {}
                    };
                    commRequest(options).then(res => {
                        this.showLoading = false;
                        if (res.data.success) {
                            this.step = 3;
                            this.gameUrl = localStorage.getItem('speed_game_url');
                            this.userInfo = JSON.parse(localStorage.getItem('speed_user_info'));
                        }
                        else {
                            localStorage.removeItem("speed_token");
                            localStorage.removeItem("speed_user_info");
                            localStorage.removeItem("speed_game_url");
                            this.step = 1;
                        }
                    });
                }
            },
            initPage() {
                let rem = localStorage.getItem('speed_rem');
                this.remPassword = rem === '1';
                if (this.remPassword) {
                    this.username = localStorage.getItem('speed_rem_username');
                    this.password = crypto.decrypt(localStorage.getItem('speed_rem_password'));
                }
                else {
                    this.username = '';
                    this.password = '';
                }
                this.speedRate = 1;
                timerhook.setSpeed(1);
            },
            change(){
                this.remPassword = !this.remPassword;
            },
            goRegisterPage() {
                this.step = 2;
            },
            goLogin () {
                this.step = 1;
            },
            setSpeed(n) {
                this.getAndCopyCode();
                if (!!this.code) {
                    Toast('请先进入游戏再启动加速');
                    return ;
                }
                this.speedRate = n;
                timerhook.setSpeed(n);
                Toast('游戏当前加速倍率：' + n);
            },
            showQiliDialog() {
                this.qiliDialogShow = !this.qiliDialogShow;
                if (this.qiliDialogShow) {
                    this.getAndCopyCode();
                }
            },
            logout() {
                this.qiliDialogShow = false;
                Dialog.confirm({
                    message: '确认退出登录吗？',
                }).then(() => {
                    this.doLogout();
                }).catch(() => {});
            },
            doLogout() {
                this.showLoading = true;
                let options = {
                    url:"/speedGame/logout",
                    data: {}
                };
                commRequest(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        Toast('帐号退出成功');
                        localStorage.removeItem("speed_token");
                        localStorage.removeItem("speed_user_info");
                        localStorage.removeItem("speed_game_url");
                        
                        localStorage.removeItem("iframe_param");
                        
                        this.step = 1;
                        this.initPage();
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });

            },
            copyCode(code) {
                const input = document.createElement('input')
                document.body.appendChild(input)
                input.setAttribute('value', code)
                input.select()
                if (document.execCommand('copy')) {
                    document.execCommand('copy')
                }
                document.body.removeChild(input);
                Toast('通行证复制成功');
            },
            getAndCopyCode() {
                try {
                    const iframe = document.getElementById('gameIframe');
                    const doc = iframe.contentDocument || iframe.contentWindow.document;
                    let div = doc.getElementById("qrcode");
                    if (!!div) {
                        this.code = div.title;
                        this.copyCode(this.code);
                    }
                    else {
                        this.code = '';
                    }
                } catch (e) {
                    this.code = '';
                }
            },
            doLogin() {
                let ok = this.checkLogin();
                if (ok !== 'ok') {
                    Toast(ok);
                    return ;
                }
                let from = localStorage.getItem('from')

                this.showLoading = true;
                let options = {
                    url:"/speedGame/login",
                    data: {
                        userName: this.username,
                        password: crypto.encrypt(this.password)
                    }
                };
                commRequest(options).then(res => {
                    this.showLoading = false;
                    console.log(res);
                    if (res.data.success) {
                        this.step = 3;
                        this.saveAccountLocal();
                        let userInfo = res.data.data;
                        this.userInfo = userInfo;
                        this.gameUrl = userInfo.loginUrl;
                        localStorage.setItem('speed_user_info', JSON.stringify(userInfo));
                        localStorage.setItem('speed_token', userInfo.token);
                        localStorage.setItem('speed_game_url', this.gameUrl);
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            resetForm() {
                this.username = '';
                this.password = '';
                this.regUsername = '';
                this.regPassword = '';
                this.regPassword2 = '';
            },
            saveAccountLocal() {
                if (this.remPassword) {
                    localStorage.setItem('speed_rem_username', this.username);
                    localStorage.setItem('speed_rem_password', crypto.encrypt(this.password));
                    localStorage.setItem('speed_rem', '1');
                }
                else {
                    localStorage.setItem('speed_rem', '0');
                }
            },
            doRegister() {
                let ok = this.checkRegister();
                if (ok !== 'ok') {
                    Toast(ok);
                    return;
                }
                this.showLoading = true;
                let options = {
                    url:"/speedGame/register",
                    data: {
                        userName: this.regUsername,
                        password: crypto.encrypt(this.regPassword)
                    }
                };
                commRequest(options).then(res => {
                    this.showLoading = false;
                    console.log(res);
                    if (res.data.success) {
                        Toast('账号注册成功');
                        this.step = 1;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            checkRegister() {
                if (!this.regUsername) {
                    return "请输入七里帐号";
                }
                if (!this.usernameRegexp.test(this.regUsername)) {
                    return "七里帐号为6-12位数字字母";
                }
                if (!this.regPassword) {
                    return "请输入密码";
                }
                if (!this.passwordRegexp.test(this.regPassword)) {
                    return "密码为6-12位数字字母特殊符号";
                }
                if (!this.regPassword2) {
                    return "请再次输入密码";
                }
                if (this.regPassword !== this.regPassword2) {
                    return "两次密码输入不一致，请重新输入";
                }
                return "ok";
            },
            listener(e) {
                if(e.data.type === 'linkParam'){
                    this.saveIframeParam(e);
                }
            },
            saveIframeParam(e) {
                let url = this.gameUrl;
                let param = e.data.params;
                if(!param){
                    this.changeIframeUrl(url);
                    return;
                }
                if(param === "?genCode=true") return;

                let iframeParam = JSON.parse(localStorage.getItem('iframe_param'));
                if(iframeParam){
                    
                }else {
                    let currentTimestamp = Date.now();
                    let gapTimestamp = 60 * 60 * 1000 * 2.5;

                    let params = {
                        expired: currentTimestamp + gapTimestamp,
                        params: param
                    }
                    localStorage.setItem('iframe_param',JSON.stringify(params));
                }
            },
            changeIframeUrl(url) {
                this.$nextTick(() => {
                    this.$refs.gameIframe.src = url;
                })
            },
            checkIframeParam() {
                let interval = setInterval(() => {
                    if(this.$refs.gameIframe) {
                        let iframeParam = JSON.parse(localStorage.getItem('iframe_param'));
                        
                        if(iframeParam){
                            if(iframeParam.expired < Date.now()){
                                localStorage.removeItem('iframe_param');
                            }else
                            if (iframeParam.params) {
                                let url = this.gameUrl.substring(0,this.gameUrl.indexOf('?'));
                                this.changeIframeUrl(url + iframeParam.params)
                            }
                        }

                        window.clearInterval(interval);
                    }
                } ,100)
            },
            checkLogin() {
                let errorinfo = 'ok';
                if (!this.username) {
                    errorinfo = "请输入七里帐号"
                } else if (!this.password) {
                    errorinfo = "请输入密码";
                } else if (!this.usernameRegexp.test(this.username)) {
                    errorinfo = "七里帐号为6-12位数字字母下划线";
                } else if (!this.passwordRegexp.test(this.password)) {
                    errorinfo = "密码为6-12位数字字母特殊符号";
                }
                return errorinfo;
            },
            down(event) {
                this.flags = true;
                let touch;
                if (event.touches) {
                    touch = event.touches[0];
                } else {
                    touch = event;
                }
                this.position.x = touch.clientX;
                this.position.y = touch.clientY;
                this.dx = moveDiv.offsetLeft;
                this.dy = moveDiv.offsetTop;
            },
            move(event) {
                if (this.flags) {
                    if (this.buttonHidden) {
                        this.activeButton();
                    }
                    let touch;
                    if (event.touches) {
                        touch = event.touches[0];
                    } else {
                        touch = event;
                    }
                    this.nx = touch.clientX - this.position.x;
                    this.ny = touch.clientY - this.position.y;
                    this.xPum = this.dx + this.nx;
                    this.yPum = this.dy + this.ny;
                    moveDiv.style.left = this.xPum + "px";
                    moveDiv.style.top = this.yPum + "px";
                    //阻止页面的滑动默认事件；如果碰到滑动问题，1.2 请注意是否获取到 touchmove
                    document.addEventListener("touchmove", function () {
                        event.preventDefault();
                    }, false);
                }
            },
            //鼠标释放时候的函数
            end(event) {
                this.flags = false;
                if (!this.xPum) {
                    return;
                }

                let {
                    clientHeight: windowHeight,
                    clientWidth: windowWidth
                } = document.documentElement;
                // 计算后坐标  设置 按钮位置
                //this.halfOffset = -moveDiv.clientWidth / 2;
                if (this.yPum > 50 && this.yPum < (windowHeight - 150)) { //  不在顶部 且 不在底部
                    if (this.xPum <= (windowWidth / 2)) {  //  left 小于等于屏幕一半
                        moveDiv.style.left = 0;
                        moveDiv.style.right = 'auto';
                        //this.hiddenOnLeft();
                        this.start();
                    } else { //  left 大于屏幕一半
                        moveDiv.style.left = 'auto';
                        moveDiv.style.right = 0;
                        this.start();
                    }
                } else {
                    if (this.yPum <= 50) {  //  在顶部
                        moveDiv.style.top = 0;
                        moveDiv.style.bottom = 'auto';
                        this.start();
                    } else if (this.yPum >= (windowHeight - 150)) {
                        moveDiv.style.bottom = 0;
                        moveDiv.style.top = 'auto';
                        this.start();
                    }
                    if (this.xPum < 0) {
                        moveDiv.style.left = 0;
                        this.start();
                    } else if (this.xPum > (windowWidth - moveDiv.clientWidth)) {
                        moveDiv.style.right = 0;
                        moveDiv.style.left = 'auto';
                        this.start();
                    }
                }
            },
            start() {
            },
            showMainWindow() {

            }
        }
    }
</script>

<style scoped>
    .qili-dialog {
        position: absolute;
        background: rgb(255,255,255);
        width: 360px;
        height: 300px;
        top: 150px;
        left: -300px;
        border: 1px solid #dddddd;
        border-radius: 5px;
        user-select: none;
    }
    .show-dialog {
        left: 0;
        transition: 0.5s;
    }
    .hide-dialog {
        left: -365px;
        transition: 0.5s;
    }


    .main {
        width: 100%;
        height: 100vh;
        background-color: #e8e8e8;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        overflow: hidden;
    }
    .game-main{
        width: 100%;
        height: 100%;
        overflow: hidden;
        margin: 0;
    }
    .main-header {
        width: 100%;
        height: 80px;
        background-color: #4870c2;
        color: #ffffff;
        font-size: 20px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .main-center{
        max-width: 600px;
        height: calc(100vh - 80px);
        margin: 0 auto;
        background-color: #FFFFFF;
        box-sizing: border-box;
        padding-top: 50px;
        overflow-y: auto;
    }
    .form-view{
        width: 70%;
        max-width: 350px;
        margin: 0 auto 0;
        border: 1px solid #d7d7d7;
        border-radius: 5px;
        padding: 10px 27px 0;
    }
    .tips-view {
        width: 80%;
        margin: 20px auto;
        display: flex;flex-direction: column;
        align-items: flex-start;
        text-align: left;
        padding: 10px 0;
        font-size: 12px;
        color: #666666;
        box-sizing: border-box;
    }
    .tips-view span {
        margin-top: 5px;
    }


    .top-span{
        font-size: 20px;
        font-weight: 600;
    }
    .input-view{
        padding-top: 10px;
        padding-bottom: 20px;
    }
    .logo-view{
        display: flex;
        align-items: flex-end;
    }
    .main-button{
        font-size: 16px;
        background-color: #e55825;
        width: 100%;
        height: 40px;
        line-height: 40px;
        color: #FFFFFF;
        margin-top: 20px;
        border-radius: 5px;
        cursor: pointer;
    }
    .speed-button {
        font-weight: bold;
        width: 40px;
        height: 40px;
        font-size: 12px;
        line-height: 40px;
        color: #e55825;
        background-color: rgba(240, 88, 88, 0.15);
        border: 2px solid #F05858;
        border-radius: 3px;
        text-align: center;
        cursor: pointer;
    }
    .van-field{
        outline: 1px #dbdbdb solid;
        border-radius: 3px;
        padding: 6px 16px;
    }
    .van-field:focus-within{
        outline: 2px #F18F25 solid;
    }
    /deep/.van-field__control {
        color: #F18F25;
    }

    .xuanfu {
        position: fixed;
        touch-action: none;
        height: 2.8rem;
        width: 3rem;
        /* 如果碰到滑动问题，1.3 请检查 z-index。z-index需比web大一级*/
        z-index: 999;
        top: 70%;
        left: 0;
    }
</style>