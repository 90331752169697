<template>
  <div class="main-container">
      <van-form @submit="onSubmit" ref="submitForm">
        <div class="left-top-title">
          <van-image class="back-icon" :src="backIcon" @click="back" width="2rem"></van-image>
          <h2>修改密码</h2>
        </div>

        <div style="margin: 10px 20px;">
          <van-field label-width="60px" label="原密码" type="password" placeholder="请输入您的原密码" style="margin-top: 10px;" v-model="oldPassword" clearable/>

          <van-field label-width="60px" label="新密码" type="password" placeholder="请输入新密码" style="margin-top: 10px;" v-model="newPassword" clearable/>

          <van-field label-width="60px" label="确认密码" type="password" placeholder="请再次输入新密码" style="margin-top: 10px;" v-model="confirmPassword" clearable/>

          <div class="box-button">
            <van-button type="info" block native-type="submit" :loading="loading" loading-text=""  size="small"
                        color="#5A6EFC">
              确认修改
            </van-button>
          </div>
        </div>

      </van-form>

  </div>
</template>

<script>

  import {modifyPasswordByOld} from '@/api/request'
  import crypto from '@/utils/crypto.js';
  import passwordIcon from "@/assets/password.png";
  import backIcon from "@/assets/back.png";
  import { Dialog } from 'vant'
  import { Toast } from 'vant'

  export default {
    name: 'changePassword',
    data() {
      return {
        passwordIcon,
        backIcon,
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
        passwordPattern: /^[a-zA-Z0-9~!@#$%^&*()_+.]{6,12}$/,
        loading: false,
      };
    },
    methods: {
      back() {
        this.$parent.$parent.hideChangePage();
      },
      onSubmit() {
        let ok = this.checkForm();
        if (ok !== 'ok') {
          Toast(ok);
          return ;
        }

        this.loading = true;
        let channelId = localStorage.getItem("channelId");
        let gameId = localStorage.getItem("gameId");
        let params = {
          device: 'H5' ,
          oldPassword: crypto.encrypt(this.oldPassword),
          newPassword: crypto.encrypt(this.newPassword),
        };
        modifyPasswordByOld(params).then(res => {
          this.loading = false;
          if(res.data.success) {
            this.$parent.$parent.changePasswordShow = false;
            this.$parent.$parent.mainDialogShow = false;
            Dialog.alert({
              message: '密码修改成功，请重新登录!',
            }).then(() => {
              this.$parent.$parent.logoutCallback();
            });
          }
          else {
            Toast.fail(res.data.error.message);
          }
        });
      },
      checkForm() {
        if (!this.oldPassword) {
          return "请输入原密码";
        }
        if (!this.passwordPattern.test(this.oldPassword)) {
          return "原密码为6-12位数字字母特殊符号";
        }
        if (!this.newPassword) {
          return "请输入新密码";
        }
        if (!this.passwordPattern.test(this.newPassword)) {
          return "新密码为6-12位数字字母特殊符号";
        }
        if (!this.confirmPassword) {
          return "请再次输入密码";
        }
        if (this.newPassword !== this.confirmPassword) {
          return "两次密码输入不一致，请重新输入";
        }
        return "ok";
      },
    }
  }
</script>

<style scoped lang="scss">
  .main-container {
    margin: .5rem .3rem .8rem;
    .left-top-title{
      display: flex;
      /*实现垂直居中*/
      align-items: center;
      justify-content: left;
      width: 100%;
      padding-left: .2rem;
      color: #666666;
      vertical-align: center;
      height: 3rem;
      line-height: 3rem;
      .back-icon{
        display: inline-block;
      }
      h2{
        display: inline-block;
        font-size: 1.3rem;
        margin: 0;
      }

    }

    .top-label{
      width: 100%;
      text-align: left;
      padding: 0 .8rem;
      color: #666666;
      font-size: .8rem;
      span{
        margin-left: .5rem;
      }
    }

    .down-input{
      margin-top: .8rem;
    }
    .van-cell{
      padding: 5px 16px;
    }
    .line-row{
      position: relative;
      vertical-align: bottom;
      font-size: .8rem;
      .right-line-button{
        position: absolute;
        right: .8rem;
        bottom: .8rem;
      }
    }

    .box-button {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .van-field{
      outline: 1px #dbdbdb solid;
      border-radius: 3px;
    }
    .van-field:focus-within{
      outline: 2px #3478F6 solid;
    }
    /deep/.van-field__control {
      //color: #3478F6;
    }
  }
</style>
