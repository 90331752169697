<template>
  <div class="main-container">
      <van-form @submit="onSubmit" ref="submitForm">
        <div class="center-top-title">
          <h4>{{goodsName}}</h4>
        </div>
        <van-field
                v-model="price"
                label-width="4.2rem"
                label="商品价格"
                aria-autocomplete="none"
                autocomplete="off"
                readonly
                class="info-input"
        />

        <van-field
                v-model="account"
                label-width="4.2rem"
                label="当前账号"
                aria-autocomplete="none"
                autocomplete="off"
                readonly
                class="info-input"
        />

        <van-field
                v-model="uid"
                label-width="4.2rem"
                label="UID"
                aria-autocomplete="none"
                autocomplete="off"
                readonly
                class="info-input"
        />

        <van-divider :style="{ color: '#99C800', borderColor: '#99C800', 'font-size': '.8rem', margin: '.7rem .5rem' }">
          选择支付方式
        </van-divider>
        <div class="pay-class">
          <van-radio-group v-model="radio" direction="horizontal">
            <van-radio name="2" shape="square" icon-size=".9rem">支付宝</van-radio>
            <van-radio name="3" shape="square" icon-size=".9rem">微信</van-radio>
            <van-radio name="1" shape="square" icon-size=".9rem">{{ptbBalance}}</van-radio>
          </van-radio-group>
        </div>

        <div class="box-button">
          <van-button round type="info" block native-type="submit" size="small"
                      color="linear-gradient(to right, rgb(153, 200, 0), rgb(153, 200, 0))">
            确认
          </van-button>
          <p class="text-button" @click="back">取消支付
          </p>
        </div>
      </van-form>

  </div>
</template>

<script>

  import {doLogin} from '@/api/request'
  //import crypto from '@/utils/crypto.js';
  import wxPayIcon from "@/assets/pay_wx.png";
  import qiliLogo from "@/assets/qiliLogo.png";
  import zfbPayIcon from "@/assets/pay_zfb.png";

  export default {
    name: 'realName',
    data: function () {

      return {
        wxPayIcon,
        zfbPayIcon,
        qiliLogo,
        ptbBalance: '七里币(余额: 38000000)',
        goodsName: '30000元宝充值',
        price: '298.00',
        account: '68890998',
        uid: '21098',
        radio: '',
      }
    },
    methods: {
      back() {
        this.$parent.$parent.back();
      },
      onSubmit() {

      }

    }
  }
</script>

<style scoped lang="scss">
  .main-container {
    margin: .5rem .3rem .8rem;
    background-color: #F7F8FA;
    .center-top-title{
      width: 100%;
      vertical-align: center;
      height: 3rem;
      line-height: 3rem;
      h4{
        display: inline-block;
        font-size: 1rem;
        margin: 0;
        color: #99C800;
      }

    }

    .info-input{
      background-color: #F7F8FA;
    }

    /deep/.van-field__label{
      color: #b3b3b6;
    }

    .top-label{
      width: 100%;
      text-align: left;
      padding: 0 .8rem;
      color: #666666;
      font-size: .8rem;
      span{
        margin-left: .5rem;
      }
    }

    .down-input{
      margin-top: .8rem;
    }
    .van-form{
      .van-cell{
        padding: 2px 16px;
      }
    }

    .width-line{
      height: 1px;
      margin: 0 1.04rem;
      border-bottom: 1px solid #b3b3b6;
    }

    .pay-class{
      text-align: left;
      padding: 6px 16px;
      font-size: .8rem;
      font-weight: 600;
    }

    .box-button {
      margin: .7rem 1rem 0;
      p{
        margin: 0;
      }
      .text-button{
        font-size: .8rem;
        margin-top: .4rem;
        color: #99C800;
      }
    }
    .van-cell::after {
      border-bottom: 0 solid #4d4e50;
    }
  }
</style>
