<template>
    <div class="pay-code-panel">
        <span>请用{{payName}}扫码支付，支付金额 {{payPrice}}</span>
        <van-image width="100%" height="100%" :src="payImg">
        </van-image>
    </div>
</template>

<script>

  export default {
    name: 'pay-code-img',
    data() {
      return {
        payImg: null,
        payName: '',
        payPrice: '',
        payType: '',
      }
    },
    mounted() {

    },
    methods: {
      init() {
        if (this.payType === '4') {
          this.payName = '支付宝';
        }
        else if (this.payType === '5') {
          this.payName = '微信';
        }
      }
    }
  }
</script>

<style scoped lang="scss">
    .pay-code-panel{
        margin-top: 1.2rem;
        font-size: .8rem;
        padding: 0 .8rem;
        span{
            display: block;
            margin-bottom: .9rem;
        }
    }
</style>
