<template>
  <div class="all-page">
    <div class="header-panel">
      <div class="logo-panel">
        <van-image :src="logo" width="3rem"></van-image>
        <span style="color: white;margin-left: 10px;font-size: 18px;font-weight: 700">七里游戏下载</span>
      </div>
    </div>

    <div class="main-page">
      <div class="top-panel">
        <div class="top-inner">
          <van-image :src="logoUrl" class="game-logo-div"></van-image>
          <div class="name-div">{{ this.gameName }}</div>
          <van-button type="primary" color="#7456ea" class="down-button" @click="downloadGame" :disabled="this.disabled">{{ this.buttonName }}
          </van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import logo from '@/assets/logo.png'
import {getIOSParamOld} from '@/api/request'
import {Toast} from 'vant'

export default {
  name: 'download',
  data() {
    return {
      buttonName: '准备中(300)',
      logo,
      logoUrl: '',
      gameName: '',
      thisUrl: '',
      iosUrl: '',
      disabled: true,
      gameCode: '',
      channelId: '',
      udid: '',
      timer: '',
      timerText: '',
      time: 300
    }
  },
  mounted() {
    this.gameCode = this.$route.path.split('/')[2]
    this.getGameName(this.gameCode);
    this.logoUrl= "https://qili-data.oss-cn-qingdao.aliyuncs.com/oldlogo/"+this.gameCode+".png"
    this.code = this.$route.query.code;
    this.udid = this.$route.query.udid;
    if (this.code === "1") {
      this.buttonName='设备不足'
    } else {
      this.getDownLoad();
      this.timer = setInterval(this.getDownLoad, 10000);
      this.timerText = setInterval(this.setButtonText, 1000);
    }
  },
  methods: {
    setButtonText(){
      let a = this.time-1;
      this.time = a;
      this.buttonName = "准备中("+a+")";
      console.log(this.buttonName);
    },
    getDownLoad() {
        let params = {
          gameCode: this.gameCode,
          udid: this.udid
        }
        let _t = this;
        getIOSParamOld(params).then((res) => {
          if (res.data.success === true) {
            this.iosUrl = res.data.data.iosUrl;
            if (res.data.data.signStatus === 1) {
              clearInterval(this.timer);
              clearInterval(this.timerText);
              _t.buttonName = '主界面下载中';
              _t.disabled = false;
              window.location.href = this.iosUrl
            }
          } else {
            Toast(res.data.error.message)
          }
        })
    },
    downloadGame() {
      Toast({
        message: '点击允许安装后，在桌面查看下载进度',
        position: 'bottom',
        duration: 60000
      });
      window.location.href = this.iosUrl
    },
    getGameName(code){
      if(code=="yzsy_old"){
        this.gameName="远征手游";
      }
      else if(code=="7liyx_old"){
        this.gameName="七里游戏";
      }
      else if(code=="hdqy_old"){
        this.gameName="混沌起源";
      }
      else if(code=="nuhuoyidao_old"){
        this.gameName="怒火一刀";
      }
      else if(code=="nuhuoyidao_new"){
        this.gameName="怒火一刀";
      }
    },
  }
}
</script>

<style scoped lang="scss">
.all-page {
  overflow: hidden;
  background-color: #eceaea;
  width: 100%;
  height: 100vh;

  .header-panel {
    width: 100%;
    height: 70px;
    background-color: #37414d;
    text-align: center;

    .logo-panel {
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
      display: flex;
      height: 100%;
      align-content: center;
      align-items: center;
      justify-content: center;
    }
  }

  .main-page {
    overflow: hidden;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;

    .top-panel {
      margin: 10px 15px;
      width: 100%;

      .top-inner {
        margin: 0 15px;
        padding-bottom: 20px;
        border-radius: 5px;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;

        .game-logo-div {
          width: 100px;
          display: block;
          margin-top: 30px;
        }

        .name-div {
          width: 100px;
          display: block;
          font-weight: bold;
          margin-top: 20px;
        }

        .ios-div {
          width: 100px;
          display: block;
          color: #7456ea;
          margin-top: 30px;
        }

        .down-button {
          width: 100px;
          display: block;
          font-weight: bold;
          margin-top: 30px;
        }
      }

    }

    .code-div {
      width: 100%;

      bottom: 5rem;

      .code-inner {
        margin: 0 15px;
        background-color: white;
        border-radius: 5px;
        padding-bottom: 10px;

        p {
          font-weight: 600;
          background-color: white;
          border-radius: 5px;
        }

        .qr-code {
          padding: 10px;
          border-radius: 10px;
          background-color: white;

          /deep/ img {
            display: inline !important;
          }
        }
      }

    }


  }

  .van-overlay {
    .overlay-panel {
      text-align: right;

      .overlay-info {
        text-align: center;
        width: 80%;
        margin: 20px auto;
        border-radius: 60px;
        border: 2px dashed white;
        color: white;
        font-weight: 600;
      }
    }
  }
}

</style>
