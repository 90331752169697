<template>
  <div class="main-container">
      <van-form @submit="onSubmit" ref="submitForm">
        <div class="left-top-title">
          <van-image v-show="backShow" class="back-icon" :src="backIcon" @click="back" width="2rem"></van-image>
          <h2>实名认证</h2>
        </div>

        <div style="margin: 10px 20px;">

          <van-field label-width="60px" label="真实姓名" :readonly="backShow" placeholder="请输入您的真实姓名" style="margin-top: 10px;" v-model="realName" clearable/>

          <van-field label-width="60px" label="身份证号" :readonly="backShow" placeholder="请输入您的身份证号" style="margin-top: 20px;" v-model="carId" clearable/>

          <div class="box-button">

            <van-button type="info" block native-type="submit" :loading="loading" style="height: 35px;"
                        loading-text="正在认证"
                        color="#5A6EFC">
              立即认证
            </van-button>

            <van-button type="info" block  :loading="loading" style="height: 35px;opacity: .5" v-show="false"
                        loading-text="正在认证"
                        color="#5A6EFC">
              立即认证
            </van-button>
            <div v-if="false" style="font-size: 12px;margin-top: 15px;display: flex;justify-content: flex-end;padding: 0 3px;">
              <div @click="logout" style="color: #888888">切换其他账号</div>
            </div>
          </div>

        </div>
      </van-form>
  </div>
</template>

<script>

  import {personCheck, logout} from '@/api/request'
  import backIcon from "@/assets/back.png";
  import { Toast } from 'vant'

  export default {
    name: 'realName',
    data: function () {
      return {
        backIcon,
        realName: '',
        carId: '',
        loading: false,
        missShow: false,
        backShow: true,
        realNamePattern: /^[\u4E00-\u9FA5]{1,5}$/,
        carIdPattern: /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][012])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/,
      }
    },
    mounted() {

    },
    methods: {
      logout() {

      },
      back() {
        this.$parent.$parent.hideRealNamePage();
      },
      initData() {
        let user = JSON.parse(localStorage.getItem('user'));
        this.carId = user.cardNo;
        this.realName = user.realName;
      },
      onSubmit() {
        if (this.backShow) {
            return;
        }
        let ok = this.checkForm();
        if (ok !== 'ok') {
          Toast(ok);
          return ;
        }

        this.loading = true;
        let params = {
          channelId: localStorage.getItem("channelId"),
          gameId: localStorage.getItem("gameId"),
          device: 'H5' ,
          cardNo: this.carId,
          realName: this.realName,
        };
        personCheck(params).then((res) => {
          this.loading = false;
          if (res.data.success === true) {
            Toast.success('实名认证成功');
            let user = JSON.parse(localStorage.getItem('user'));
            user.isUserCheck = res.data.data;
            localStorage.setItem('user', JSON.stringify(user));
            if (!this.backShow) {
              this.miss();
            } else {
              this.back();
            }
          } else {
            Toast.fail(res.data.error.message);
          }
        });
      },
      miss() {
        this.$parent.$parent.missRealName();
      },
      checkForm() {
        if (!this.realName) {
          return "请输入真实姓名";
        }
        /*if (!this.realNamePattern.test(this.realName)) {
          return "真实姓名格式错误";
        }*/
        if (!this.carId) {
          return "请输入身份证号码";
        }
        if (!this.carIdPattern.test(this.carId)) {
          return "身份证号码格式错误";
        }
        return "ok";
      },
    }
  }
</script>

<style scoped lang="scss">
  .main-container {
    margin: .5rem .3rem .8rem;
    .left-top-title{
      display: flex;
      /*实现垂直居中*/
      align-items: center;
      justify-content: left;
      width: 100%;
      padding-left: .5rem;
      color: #666666;
      vertical-align: center;
      height: 3rem;
      line-height: 3rem;
      .back-icon{
        display: inline-block;

      }
      h2{
        display: inline-block;
        font-size: 1.3rem;
        margin: 0;
      }

    }

    .top-label{
      width: 100%;
      text-align: left;
      padding: 0 .8rem;
      color: #666666;
      font-size: .8rem;
      span{
        margin-left: .5rem;
      }
    }

    .down-input{
      margin-top: .8rem;
    }
    .van-cell{
      padding: 6px 16px;
    }
    .line-row{
      position: relative;
      vertical-align: bottom;
      font-size: .8rem;
      .right-line-button{
        position: absolute;
        right: .8rem;
        bottom: .8rem;
      }
    }

    .box-button {
      margin: 20px 0;
    }
    .van-field{
      outline: 1px #dbdbdb solid;
      border-radius: 3px;
    }
    .van-field:focus-within{
      outline: 2px #3478F6 solid;
    }
    /deep/.van-field__control {
      //color: #3478F6;
    }
  }
</style>
