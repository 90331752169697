<template>
  <div class="all-panel">
    <!--<van-sticky>-->
    <div class="header-panel">
      <div class="inner-header">
        <div style="display: flex;align-items: center">
          <van-image :src="qiliLogo" width="3rem"></van-image>
          <span style="color: white;margin-left: 10px;font-size: 16px;font-weight: bold">七里游戏</span>
        </div>
        <div style="display: flex;justify-content: center;margin-left: 30px;width: 180px;">
          <van-field
                  style="width: 200px;height: 40px"
                  v-model="gameSearchInput"
                  size="small"
                  placeholder="搜索游戏"
                  autocomplete="off"
          />
          <van-button icon="search"  @click="searchData" type="default" color="#ed792c" style="height: 40px"></van-button>
        </div>
      </div>

    </div>
    <!--</van-sticky>-->
    <div class="main-panel" :style="safariStyle">
    <div class="center-panel">
      <div class="tabs-panel">
        <ul>
          <li><a @click="activeMenu(0)" @mouseover="onMenu(0)" @mouseleave="leaveMenu">所有</a>
            <div class="down_line" :style="(active === 0 || onActive === 0) ? 'display: block' : 'display: none'">
            </div>
          </li>
          <li><a @click="activeMenu(1)" @mouseover="onMenu(1)" @mouseleave="leaveMenu">微网游</a>
            <div class="down_line" :style="(active === 1 || onActive === 1) ? 'display: block' : 'display: none'">
            </div>
          </li>
          <li><a @click="activeMenu(2)" @mouseover="onMenu(2)" @mouseleave="leaveMenu">手游</a>
            <div class="down_line" :style="(active === 2 || onActive === 2) ? 'display: block' : 'display: none'">
            </div>
          </li>
        </ul>
      </div>
    </div>


    <div class="type-panel">
      <div class="type-line">
        <a @click="selectType(0)" :class="linkActive === 0 ? 'active' : ''">所有</a>
        <a @click="selectType(1)" :class="linkActive === 1 ? 'active' : ''">卡牌</a>
        <a @click="selectType(2)" :class="linkActive === 2 ? 'active' : ''">传奇</a>
        <a @click="selectType(3)" :class="linkActive === 3 ? 'active' : ''">武侠</a>
        <a @click="selectType(4)" :class="linkActive === 4 ? 'active' : ''">角色</a>
        <a @click="selectType(5)" :class="linkActive === 5 ? 'active' : ''">养成</a>
        <a @click="selectType(6)" :class="linkActive === 6 ? 'active' : ''">三国</a>
        <a @click="selectType(7)" :class="linkActive === 7 ? 'active' : ''">奇迹</a>
        <a @click="selectType(8)" :class="linkActive === 8 ? 'active' : ''">BT</a>
      </div>
    </div>

    <div class="game-list-panel">
      <div v-if="emptyGameList" style="width: 100%;text-align: center">
        <van-empty image="error" description="当前分类没有内容" />
      </div>

      <div class="ar arUnActive" v-for="item in gameList"  @mouseover="onGame($event)" @mouseleave="leaveGame($event)">
        <div class="left">
          <van-image width="5.5rem" :src="item.img_url">
          </van-image>
        </div>
        <div class="right">
          <span class="game-name">{{item.game_name}}</span>
          <!--<span class="game-desc">{{item.game_topic}}</span>-->
          <span class="game-desc">{{item.game_desc}}</span>
          <div style="text-align: left">
            <van-button v-if="item.game_url" plain type="primary" @click="open(item.game_url)" color="#a78ffd" size="small" style="margin-right: 10px;">立即试玩</van-button>
            <van-button v-if="item.down_url" @click="open(item.down_url)" plain type="primary" color="#a78ffd" size="small">下载</van-button>
          </div>
        </div>
      </div>
    </div>

      <div class="footer-panel">
        <span>©2021 7li.cn/ All Rights Resered. <a href="https://beian.miit.gov.cn">蜀ICP备2021003061号-1</a></span>
        <van-image :src="gonganbu"></van-image> <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51019002004716">川公网安备 51019002004716号</a>

        <span style="color: #969698">抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防受骗上当<br>适度游戏益脑 沉迷游戏伤身 合理安排时间 享受健康生活</span>
        <span style="color: #969698">战略合作：4000818979 按 1 转接</span>
      </div>
    </div>

    </div>


</template>

<script>
  import { getGameList } from '@/api/request'
  import qiliLogo from "@/assets/logo.png";
  import gonganbu from "@/assets/gonganbu.png";
  import { Toast } from 'vant'

  export default {
    name: 'gameHome',
    data() {
      return {
        qiliLogo,
        gonganbu,
        active: 0,
        onActive: -1,
        linkActive: 0,
        gameSearchInput: '',
        emptyGameList: false,
        channel_id: '',
        gameList: [],
        safariStyle: '',
      }
    },
    mounted() {
      this.channel_id = this.$route.path.split('/')[2];
      this.getData();
      if(this.isSafari()) {
        this.safariStyle = 'padding-bottom: 113px';
      }
    },
    methods: {
      getData() {//所有  H5  手游
        this.linkActive = 0;
        let params = {
          game_type: this.active === 0 ? '' : this.active,
          channel_id: this.channel_id
        }
        this.query(params);
      },
      getDataByTopic() {//点击游戏类型
        let params = {
          game_type: this.active === 0 ? '' : this.active,
          game_topic : this.linkActive === 0 ? '' : this.linkActive,
          channel_id: this.channel_id
        }
        this.query(params);
      },
      searchData() {//点击搜索按钮
        if (!this.gameSearchInput) {
          Toast.success('请输入游戏名称');
          return ;
        }
        this.active = 0;
        this.linkActive = 0;
        let params = {
          game_name: this.gameSearchInput,
          channel_id: this.channel_id
        }
        this.query(params);
      },
      query(params) {
        this.emptyGameList = false;
        getGameList(params).then(res => {
          if (res.data.success) {
            this.gameList = res.data.data;
            if (this.gameList.length === 0) {
              this.emptyGameList = true;
            }
          }
          else {
            Toast.success('获取数据失败');
          }
        });
      },
      activeMenu(index) {
        this.active = index;
        this.getData();
      },
      onMenu(index) {
        this.onActive = index;
      },
      leaveMenu() {
        this.onActive = -1;
      },
      selectType(i) {
        this.linkActive = i;
        this.getDataByTopic();
      },
      onGame($event) {
        $event.currentTarget.className = "ar arActive";
      },
      leaveGame($event) {
        $event.currentTarget.className = "ar arUnActive";
      },
      open(url) {
        window.open(url, '');
        try{
          window.androidWebJS.openToWeb(url);
        }catch (e){
        }
      },
      isSafari() {
        console.log(/Chrome/.test(navigator.userAgent));
        return (/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent));
      }
    }
  }
</script>

<style lang="scss" scoped>
  .all-panel{
    width: 100%;
    height: 100vh;
    background-color: #f2f2f2;
    .header-panel{
      width: 100%;
      box-sizing: border-box;
      padding: 0 10px;
      height: 70px;
      background-color: #37414d;
      .inner-header {
        width: 100%;
        height: 70px;
        max-width: 1024px;
        margin: 0 auto;
        display: flex;
        align-items: center;
      }
    }
    .main-panel{
      height: calc(100vh - 70px);
      overflow-y: scroll;
      box-sizing: border-box;

    .center-panel{
      margin: 0 auto;
      background-color: white;

      .tabs-panel{
        max-width: 1000px;
        height: 55px;
        margin: 0 auto;
        text-align: left;
        ul {
          display: block;
          list-style-type: disc;
          height: 55px;
          li{
            float: left;
            text-align: center;
            /*position: relative;*/
            display: list-item;
            line-height: 55px;
            a{
              display: block;
              padding: 0 20px;
              text-decoration: none;
              cursor: pointer;
              font-size: 20px;
              line-height: 55px;
            }
            .down_line{
              width: 100%;
              height: 5px;
              background-color: #7456ea;
            }
            .active {
              .down_line{
                display: block;
              }
            }
          }
        }
        ol, ul, li, dd, dl, dt {
          list-style: outside none none;
        }
      }
    }

      .type-panel{
        max-width: 1024px;
        background-color: white;
        margin: 10px auto;
        padding: 25px 0;
        .type-line{
          margin: 0 15px;
          display: flex;
          flex-wrap: wrap;
          justify-items: center;
          align-items: center;
          a{
            border: 1px solid #ece7e8;
            color: #989898;
            text-decoration: none;
            cursor: pointer;
            font-size: 14px;
            line-height: 32px;
            margin-left: 10px;
            margin-top: 5px;
            text-align: center;
            width: 78px;
          }
          .active {
            background: #a78ffd none repeat scroll 0 0;
            border: 1px solid #a78ffd;
            color: #fff;
            text-decoration: none;
          }
        }

      }
      .game-list-panel{
        max-width: 1024px;
        background-color: white;
        min-height: calc(100vh - 325px);
        margin: 10px auto;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        .arActive{
          border: 1px solid #e9e6e6;
          box-shadow:0 0 7px rgba(208, 206, 206, 0.5)
        }
        .arUnActive{
          border: 1px solid white;
        }
        .ar{
          padding: 20px 10px;
          margin: 8px 15px;
          display: flex;
          height: 88px;
          cursor: pointer;
          span{
            display: block;
            text-align: left;
          }
          .left{

          }
          .right{
            margin-left: 15px;
            padding: 0 10px;
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: space-between;
            width: 150px;
            .game-name {
              font-weight: 700;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .game-desc {
              color: #b3b3b6;
              font-size: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
    .footer-panel{
      /*position: absolute;
      bottom: 0;*/
      width: 100%;
      background-color: #272727;
      padding-top: 5px;
      height: 80px;
      color: white;
      font-size: 13px;
      span{
        display: block;
      }
      a{
        color: white;
        cursor: pointer;
      }
    }
  }
</style>
