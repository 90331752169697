<template>
    <div class="all-page">
        <header>
            由于iOS系统权限调整，打开应用需要进入【设置>>通用>>描述文件】(iOS9上叫“描述文件”, iOS10上叫“设备管理”)，信任同名的描述文件即可。
        </header>
        <article>
            <h2>详细图文教程：</h2>
            <div class="title"><i>1</i><p>如果打开下载的「游戏或者应用」，出现如下图提示，<span>记住红线部分的描述文件名</span></p></div>
            <van-image :src="oneImg" class="mod-img"></van-image>
            <div class="title"><i>2</i><p>然后在设备中点击<span>【设置>>通用>>描述文件】(iOS9上叫“描述文件”, iOS10上叫“设备管理)，</span>，找到刚才同名的描述文件</p></div>
            <van-image :src="twoImg" class="mod-img"/><van-image :src="threeImg" class="mod-img"/>
            <div class="title"><i>3</i><p>点击文件，<span>选择【信任】</span>，然后就能正常打开APP了</p></div>
            <van-image :src="fourImg" class="mod-img"/>

        </article>
        <footer>

        </footer>
    </div>
</template>

<script>
  import oneImg from '@/assets/demo/1.png'
  import twoImg from '@/assets/demo/2.png'
  import threeImg from '@/assets/demo/3.png'
  import fourImg from '@/assets/demo/4.png'

  export default {
    name: 'download_dt',
    data() {
      return {
        oneImg,
        twoImg,
        threeImg,
        fourImg,
      }
    },
    methods: {
    }
  }
</script>

<style scoped>
    .all-page{
        width: 100%;
        height: 100vh;
        overflow-y: scroll;
    }

    *{
        margin: 0;
        padding: 0;
    }
    p {
        text-align: left;
    }
    body {
        font-family: "Microsoft Yahei",Arial,"宋体",Times New Roman,Verdana,Tahoma,Helvetica,STHeiti;
        background-color: #f8f8f8;
    }
    header {
        text-align: left;
        padding: 10px;
        background-color: #ffe8dd;
        border: 1px solid #ffd5be;
        font-size: 14px;
        color: #ff671c;
        line-height: 1.7;
    }
    @media only screen
    and (-webkit-min-device-pixel-ratio: 2){
        header {border: .5px solid #ffd5be;}
    }
    article {
        padding: 10px 15px;
    }
    h2 {
        font-size: 14px;
        margin-top: 10px;
        margin-bottom: 20px;
        color: #333333;
    }
    .title {
        display: -webkit-box;
        -webkit-box-orient: horizontal;
        -webkit-box-pack: start;
    }
    .title p {
        font-size: 16px;
        color: #333333;
        -webkit-box-flex: 1;
        line-height: 1.7;
    }
    i{
        display: block;
        width: 20px;
        height: 20px;
        margin-top: 4px;
        margin-right: 6px;
        border-radius: 3em;
        font-size: 14px;
        color: #fff;
        text-align: center;
        line-height: 20px;
        background-color: #0099FF;
    }
    .title span {
        color: #FF001C;
        font-size: 16px;
        line-height: 1.7;
    }
    .mod-img {
        display: block;
        margin: 15px auto 20px auto;
        width: 90%;
    }
    .mod-img-union {
        width: 100%;
        margin: 15px auto 20px auto;
        overflow: auto;
    }
    .mod-img-union img {
        display: block;
        width: 49%;
        float: left;
    }
    .mod-img-union img:first-child {
        margin-right: 2%;
    }
    footer {
        color: #333333;
        font-size: 14px;
        padding: 10px;
        height: 20px;
        line-height: 1.7;
    }
    .btn {
        display: block;
        background-color: #1B8DFA;
        width: 280px;
        height: 40px;
        line-height: 40px;
        margin: 0 auto;
        text-decoration: none;
        text-align: center;
        font-size: 16px;
        color: #fff;
        border-radius: 4px;
    }

</style>
