<template>
  <div class="back-div">
  <div class="main-page" ref="mainPage">
    <iframe v-show="true" ref="gameIframe" :src="gameUrl" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
  </div>
  </div>
</template>

<script>

import {huiyouGetGameUrl,huiyouPreOrder,huiyouUploadRole} from '@/api/request'
import mainRoundImg from "@/assets/main-round.png";
import { Toast } from 'vant'

export default {
  name: 'huiyou',//会玩 联运
  data() {
    return {
      mainRoundImg,
      channelId: '176',//固定值
      game_id: '',
      user_id: '',
      user_name: '',
      time: '',
      localUserId: '',
      gameUrl: '',
      hySDK: {},
    };
  },
  mounted() {
    this.importJs();
    this.game_id = this.$route.query.gameId;
    this.user_id = this.$route.query.userId;
    this.user_name = this.$route.query.userName;
    this.time = this.$route.query.time;
    let sign = this.$route.query.sign;
    localStorage.setItem("channelId", this.channelId);
    //localStorage.setItem("gameId", this.game_id);
    if (!this.gameUrl) {
      let params = {
        channelId: this.channelId,
        game_id: this.game_id,
        user_id: this.user_id,
        user_name: this.user_name,
        time: this.time,
        sign: sign,
      };
      huiyouGetGameUrl(params).then(res => {
        if(res.data.success) {
          if (res.data.data.screenType === 1) {
            this.$refs.mainPage.style.maxWidth = "600px";
          }
          this.gameUrl = res.data.data.loginUrl;
          this.localUserId = res.data.data.userId;
          console.log('返回游戏页面', this.gameUrl);
        }
        else {
          Toast.fail('拉取游戏数据异常');
        }
      });
    }
    this.initListener();
    let me = this;
    setTimeout(function () {
      me.initSDK();
    }, 1000);
  },
  methods: {
    importJs() {
      const oScript = document.createElement('script');
      oScript.type = 'text/javascript';
      oScript.src = 'https://game.7li.cn/static/sdk/hyly.sdk.js';
      document.body.appendChild(oScript);
    },
    initSDK() {
      console.log('hySDK init...');
      this.hySDK = window.HY_GAME_SDK;
      let params = {
        gameId: "0", //游戏的ID
        pay: {
          success: function (e) {
            if(e === 'success'){
              console.log("用户支付成功");
            }
            else if(e === 'payClose'){//⽤户关闭⽀付窗⼝
              console.log("⽤户关闭⽀付窗⼝");
            }
          }
        },
        uploadGameRole: {
          success: function (e) {
            console.log("上报⻆⾊信息成功");
          }
        }
      };
      this.hySDK.config(params); //初始化
      console.log('hySDK init success');
    },
    initListener() {
      window.addEventListener("message", this.listener);
    },
    listener(event) {
      let me = this;
      if (event.data.operation === 'pay') {
        console.log("sdk触发支付");
        let order = event.data.param;
        me.preOrder(order);
      }
      else if (event.data.operation === 'uploadGameRole') {
        console.log("sdk触发角色上报");
        let param = event.data.param;
        me.uploadRole(param);
      }
      else if (event.data.operation === 'repeatLogin') {
        console.log("sdk触发登陆被顶");
        me.logout();
      }
    },
    preOrder(order) {
      order.channelId = this.channelId;
      order.userId = this.localUserId;
      order.cpOrder = order.cpOrderId;
      order.serviceId = order.server;
      order.serviceName = order.server;
      order.desc = order.goodsName;
      order.roleName = order.role;
      order.roleId = order.role;

      huiyouPreOrder(order).then(res => {
        if(res.data.success) {
          let _order = {
            gameId: res.data.data.game_id,
            userId: this.user_id,
            server: order.serviceName,
            time: res.data.data.time,
            role: order.roleName,
            goodsName: order.goodsName,
            money: order.money,
            cpOrderId: res.data.data.orderId,
            sign: res.data.data.sign,
            signType: "md5"
          };
          console.log('预下单参数', JSON.stringify(_order));
          this.hySDK.pay(_order);
        }
        else {
          Toast.fail(res.data.error.message);
        }
      });
    },
    uploadRole(roleParam) {
      roleParam.serviceId = roleParam.serverId;
      roleParam.serviceName = roleParam.serverName;

      if(roleParam.gameId === 242) {
        roleParam.remark = '';
      }

      huiyouUploadRole(roleParam).then(res => {
        if(res.data.success) {
          console.log("上报后台角色信息成功");
          roleParam.gameId = res.data.data.game_id;
          roleParam.userId = this.user_id;
          this.hySDK.uploadGameRole(roleParam);
        }
        else {
          console.log("上报后台角色信息失败");
        }
      });
    },
    logout() {
      this.hySDK.repeatLogin();
      console.log("登出");
    },
  }
}
</script>

<style scoped lang="scss">
.back-div{
  background-color: #eceaea;

  .main-page {
    position: relative;
    background-color: #ffffff;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    touch-action: none;
    margin: 0 auto;
    .main-dialog{

    }

    .loading-dialog {
      position: fixed;
      top: 40%;
      left: 20%;
      width: 60%;
      height: 5rem;
    }
  }
}


</style>
