<template>
    <div class="back-div">
        <div class="main-page" ref="mainPage">
            <iframe v-show="true" ref="gameIframe" :src="gameUrl" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
        </div>
    </div>
</template>

<script>
    import {commRequest} from '@/api/request'
    import { Toast } from 'vant'

    export default {
        name: "daoyi",
        data() {
            return {
                channelId: '4',//固定值
                game_id: '',
                user_id: '',
                user_name: '',
                sign: '',
                time: '',
                localUserId: '',
                gameUrl: '',
                daoyiSDK: {},
            };
        },
        mounted() {
            this.importJs();
            this.game_id = this.$route.query.gameId;
            this.user_id = this.$route.query.userId;
            this.user_name = this.$route.query.userName;
            this.time = this.$route.query.time;
            this.sign = this.$route.query.sign;
            localStorage.setItem("channelId", this.channelId);
            //localStorage.setItem("gameId", this.game_id);
            this.getGameUrl();
            this.initListener();
            let me = this;
            setTimeout(function () {
                me.initSDK();
            }, 1000);
        },

        methods: {
            getGameUrl() {
                if (!this.gameUrl) {
                    let options = {
                        url: "/dispatch/daoyi/getGameUrl",
                        data: {
                            channelId: this.channelId,
                            game_id: this.game_id,
                            user_id: this.user_id,
                            user_name: this.user_name,
                            time: this.time,
                            sign: this.sign,
                        }
                    };

                    commRequest(options).then(res => {
                        if(res.data.success) {
                            if (res.data.data.screenType === 1) {
                                this.$refs.mainPage.style.maxWidth = "600px";
                            }
                            this.gameUrl = res.data.data.loginUrl;
                            this.localUserId = res.data.data.userId;
                            console.log('返回游戏页面', this.gameUrl);
                        }
                        else {
                            Toast.fail('进入游戏出错了');
                        }
                    });
                }
            },
            importJs() {
                const oScript = document.createElement('script');
                oScript.type = 'text/javascript';
                oScript.src = 'https://gw.daoyigame.com/static/sdk/daoyi-sdk.js';
                document.body.appendChild(oScript);
            },
            initSDK() {
                console.log('daoyiSDK init...');
                this.daoyiSDK = window.DAOYI_SDK;
                let params = {
                    gameId: "0",
                };
                this.daoyiSDK.config(params); //初始化
                console.log('daoyiSDK init success');
            },
            initListener() {
                window.addEventListener("message", this.listener);
            },
            listener(event) {
                let me = this;
                if (event.data.operation === 'pay') {
                    console.log("sdk触发支付");
                    console.dir(event)
                    let order = event.data.param;
                    me.preOrder(order);
                }
                else if (event.data.operation === 'uploadGameRole') {
                    console.log("sdk触发角色上报");
                    let param = event.data.param;
                    me.uploadRole(param);
                }
                else if (event.data.operation === 'repeatLogin') {
                    console.log("sdk触发登陆被顶");
                    me.logout();
                }
            },
            preOrder(order) {
                order.channelId = this.channelId;
                order.userId = this.localUserId;
                order.cpOrder = order.cpOrderId;
                order.serviceId = order.server;
                order.serviceName = order.server;
                order.desc = order.goodsName;
                order.roleName = order.role;
                order.roleId = order.role;

                let options = {
                    url: "/dispatch/daoyi/preOrder",
                    data: order
                };

                commRequest(options).then(res => {
                    if(res.data.success) {
                        let _order = {
                            gameId: res.data.data.game_id,
                            userId: this.user_id,
                            serverId: order.serviceId,
                            serverName: order.serviceName,
                            time: res.data.data.time,
                            roleId: order.roleId,
                            roleName: order.roleName,
                            goodsName: order.goodsName,
                            money: order.money,
                            cpOrderId: res.data.data.orderId,
                            sign: res.data.data.sign,
                            signType: "md5"
                        };
                        console.log('预下单参数', JSON.stringify(_order));
                        this.daoyiSDK.openPayPage(_order);
                    }
                    else {
                        Toast.fail(res.data.error.message);
                    }
                });
            },
            uploadRole(roleParam) {
                roleParam.serviceId = roleParam.serverId;
                roleParam.serviceName = roleParam.serverName;

                let options = {
                    url: "/dispatch/daoyi/roleSync",
                    data: roleParam
                };

                commRequest(options).then(res => {
                    if(res.data.success) {
                        console.log("上报后台角色信息成功");
                        roleParam.gameId = res.data.data.game_id;
                        roleParam.userId = this.user_id;

                        let role = {
                            gameId: res.data.data.game_id,
                            userId: this.user_id,
                            serverId: roleParam.serviceId,
                            serverName: roleParam.serviceName,
                            time: res.data.data.time,
                            roleId: roleParam.roleId,
                            roleName: roleParam.roleName,
                            combatNum: "",
                            vipLevel: "",
                            remark: "",
                            sign: res.data.data.sign,
                            signType: "md5"
                        };

                        this.daoyiSDK.reportRoleInfo(role);
                    }
                    else {
                        console.log("上报后台角色信息失败");
                    }
                });
            },
            logout() {
                this.daoyiSDK.offline();
                console.log("登出");
            },
        }
    }
</script>

<style scoped>
    .back-div{
        background-color: #eceaea;
    }

    .main-page {
        position: relative;
        background-color: #ffffff;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        touch-action: none;
        margin: 0 auto;
    }

    .main-dialog{
    }

    .loading-dialog {
        position: fixed;
        top: 40%;
        left: 20%;
        width: 60%;
        height: 5rem;
    }
</style>