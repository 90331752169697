<template>
    <div class="main-container">
        <div class="top-info">
            <van-image :src="headImg" width="2.5rem" round>
            </van-image>

            <div class="title-user-panel">
                <div>
                    <span class="user-name">{{username}}</span>
                    <span class="user-uid">uid {{uid}}</span>
                </div>
                <div class="second-info">
                    <span>余额  <span style="font-weight: bold">{{qlb}}</span></span>
                    <span style="margin-left: 15px;">Tel  <span style="font-weight: bold">{{tel}}</span></span>
                    <span class="tel_button" v-if="!hasTel" @click="openBindPage">绑定</span>
                    <span class="tel_button" v-if="hasTel" @click="openUnbindPage">解绑</span>
                </div>

            </div>
        </div>

        <van-divider :style="{ borderColor: '#777775', 'font-size': '.8rem', margin: '.2rem 16px' }">
        </van-divider>
        <div class="main-panel">
            <div class="center-button-view">
                <div class="main-button" @click="openKf">
                    在线客服
                </div>

                <div class="main-button" @click="openChangePassword">
                    修改密码
                </div>

                <div class="main-button" v-if="realNameButtonShow" @click="openRealName">
                    实名认证
                </div>

                <div class="main-button" @click="refresh">
                    刷新页面
                </div>

                <div class="main-button" v-if="outsideButtonShow" @click="openOutsidePage">
                    {{outsideButtonName}}
                </div>

            </div>

            <div class="center-button-view">

                <div class="main-button" @click="openRealName" v-if="false">
                    查看实名
                </div>

                <div class="main-button"  v-if="showJsButton" style="background: #e36d06" @click="openJsPage">
                    游戏加速
                </div>

                <div style="width: 75px;"></div>
            </div>
        </div>

        <div class="gift-panel">
            <van-field style="color: #F18F25;margin: 10px 15px;width: auto" placeholder="请输入福利码" v-model="exchangeCode"
                       clearable>
                <template #button>
                    <div style="color: #5A6EFC;cursor: pointer;user-select: none;" @click="welfareSubmit">
                        兑换福利
                    </div>
                </template>
            </van-field>
        </div>

        <div class="main-bottom-button">

            <div @click="logout" class="main-button" style="color: #f63e07;background: #ffffff;margin-right: 15px;">
                退出登录
            </div>
            <div @click="continuePlay" class="main-button">继续游戏</div>
        </div>

    </div>
</template>

<script>

    import {logout, getGameQLBalance, welfareGet} from '@/api/request'
    import headImg from '@/assets/headimg.png'
    import phoneIcon from '@/assets/phone.png'
    import qiliLogo from '@/assets/qiliLogo.png'
    import passwordIcon from '@/assets/password-g.png'
    import kfIcon from '@/assets/kf-g.png'
    import cardIcon from '@/assets/card-g.png'
    import refreshIcon from '@/assets/refresh.png'
    import giftIcon from '@/assets/gift.png'
    import gift2Icon from '@/assets/gift2.png'
    import gift3Icon from '@/assets/gift3.png'

    import {Dialog, Toast} from 'vant'

    export default {
        name: 'realName',
        data: function () {
            return {
                headImg,
                phoneIcon,
                kfIcon,
                cardIcon,
                qiliLogo,
                passwordIcon,
                refreshIcon,
                giftIcon,
                gift3Icon,
                username: '',
                uid: '',
                tel: '',
                hasTel: false,
                qlb: '0',
                realNameButtonShow: false,
                channelId: '',
                gameId: '',
                exchangeCode: '',
                outsideButtonShow: false,
                outsideButtonName: '',
                showJsButton: false,
            }
        },
        mounted() {
            let user = JSON.parse(localStorage.getItem('user'));
            //{"token":"a868a91a68e5442baded334fd90a9260","headerImg":null,"nickName":null,
            // "userName":"lilu666","phone":null,"isUserCheck":"0","userId":"52","adminId":"100","needSecondCheck":"0","is_audit":null}
            this.username = user.userName;
            this.uid = user.userId;
            this.tel = user.phone ? user.phone : "未绑定";
            user.phone && (this.hasTel = true);
            if (this.tel && this.tel.startsWith("1")) {
                this.tel = this.tel.toString().slice(0, 3) + '****' + this.tel.toString().slice(-4)
            }
            (!user.isUserCheck || user.isUserCheck !== '1') && (this.realNameButtonShow = true);
            this.channelId = localStorage.getItem("channelId");
            this.gameId = localStorage.getItem("gameId");
            user.webviewPageUrl && (this.outsideButtonShow = true)
            this.outsideButtonName = user.webviewPageName;
        },
        methods: {
            refresh() {
                //window.location.reload();
                this.$parent.$parent.refreshPage();
            },
            getBalance() {
                let params = {
                    channelId: this.channelId,
                    gameId: this.gameId,
                    device: 'H5',
                };
                getGameQLBalance(params).then(res => {
                    if (res.data.success) {
                        this.qlb = res.data.data;
                    } else {
                        Toast.fail(res.data.error.message);
                    }
                });
            },
            welfareSubmit() {
                if (!this.exchangeCode) {
                    Toast.fail('请输入福利码');
                    return;
                }
                let param = {
                    channelId: this.channelId,
                    welfareCode: this.exchangeCode,
                    gameId: this.gameId
                };
                welfareGet(param).then(res => {
                    if (res.data.success) {
                        Toast.success('福利码兑换成功');
                        this.exchangeCode = '';
                        this.getBalance();
                    } else {
                        Toast.fail(res.data.error.message);
                    }
                });
            },
            continuePlay() {
                this.$parent.$parent.closeMainWindow();
            },
            logout() {
                Dialog.confirm({
                    message: '确认退出登录吗？',
                }).then(() => {
                    this.$parent.$parent.logout();
                }).catch(() => {
                });
            },
            openKf() {
                this.$parent.$parent.showKfPage();
            },
            openChangePassword() {
                this.$parent.$parent.showChangePage();
            },
            openRealName() {
                this.$parent.$parent.showRealNamePageReadOnly();
            },
            openJsPage() {
                this.$parent.$parent.openJsPage();
            },
            openBindPage() {
                this.$parent.$parent.showBindPage();
            },
            openUnbindPage() {
                this.$parent.$parent.showUnbindPage();
            },
            openOutsidePage() {
                this.$parent.$parent.openOutsidePage();
            }
        }
    }
</script>

<style scoped lang="scss">
    .main-container {
        margin: .5rem .3rem .8rem;

        .top-info {
            text-align: left;
            padding: .5rem .8rem;
            display: flex;
            /*实现垂直居中*/
            align-items: center;
            justify-content: left;

            .title-user-panel {
                display: inline-block;
                margin-left: .8rem;
                width: 100%;

                .user-name {
                    font-size: .9rem;
                    color: #4d4e50;
                    font-weight: bold;
                }

                .user-uid {
                    font-size: .9rem;
                    color: #7a7a7c;
                    margin-left: .9rem;
                }

                .second-info {
                    margin-top: .3rem;
                    font-size: 12px;
                    color: #888888;

                    .tel_button {
                        font-size: 12px;
                        color: #5A6EFC;
                        margin-left: .7rem;
                        border: 1px solid #5A6EFC;
                        padding: 1px 5px;
                        border-radius: 2px;
                        cursor: pointer;
                        user-select: none;
                    }
                }
            }
        }

        .main-panel {
            text-align: left;
            margin-top: 10px;
            .van-cell {
                font-size: .8rem;
            }

            .center-button-view {
                /*margin-top: .8rem;
                padding: 0 .9rem;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                button {
                    width: 80px;
                    margin-top: 10px;
                }
                .van-button--mini+.van-button--mini {
                    margin-left: 0;
                }*/

                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 15px 0;


            }
        }

        .gift-panel {
            font-size: .8rem;
            margin-top: 15px;

        }

        .main-button {
            background: #5A6EFC;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            font-size: 12px;
            width: 75px;
            font-weight: bold;
            border-radius: 3px;
            cursor: pointer;
            user-select: none;
        }

        .main-bottom-button {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 15px;
        }

        .van-cell {
            padding: 5px 16px;
        }

        .van-field {
            outline: 1px #979696 solid;
            border-radius: 3px;
        }

        /*.van-field:focus-within{
            outline: 1px #3478F6 solid;
        }*/
        /deep/ .van-field__control {
            //color: #3478F6;
        }

    }
</style>
