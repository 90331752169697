<template>
  <div class="main-container">
      <div class="center-top-title">
        <h4>{{goodsName}}</h4>
      </div>

      <van-cell-group inset>
          <van-cell title="商品价格">
              <template #right-icon>
                  <span class="order-money-text">{{orderMoney}}</span>
              </template>
          </van-cell>
          <van-cell v-show="showDiscount" title="优惠抵扣 / 抵扣率" :value=preferenceMoney />
          <van-cell title="UID" :value=uid />
      </van-cell-group>

      <!--<van-field
              v-model="orderMoney"
              label-width="4.2rem"
              label="商品价格"
              aria-autocomplete="none"
              autocomplete="off"
              readonly
              class="info-input order-text"
      />
      <van-field
              v-show="showDiscount"
              v-model="preferenceMoney"
              label-width="4.2rem"
              label="优惠抵扣"
              aria-autocomplete="none"
              autocomplete="off"
              readonly
              class="info-input"
      />
      <van-field
              v-show="showDiscount"
              v-model="discount"
              label-width="4.2rem"
              label="抵扣率"
              aria-autocomplete="none"
              autocomplete="off"
              readonly
              class="info-input"
      />
      <van-field
              v-model="uid"
              label-width="4.2rem"
              label="UID"
              aria-autocomplete="none"
              autocomplete="off"
              readonly
              class="info-input"
      />-->

      <van-divider :style="{ color: '#5A6EFC', borderColor: '#5A6EFC', 'font-size': '.8rem', margin: '.2rem 16px' }">
        选择支付方式
      </van-divider>
      <div class="pay-class">
        <van-radio-group v-model="radio">
            <div style="display: flex;align-items: center;justify-content: space-between;background: #ffffff;padding: 5px 15px;margin-bottom: 5px;">
                <div style="display: flex;">
                    <img :src="zfbPayIcon" style="width: 18px;height: 18px;">
                    <span style="font-size: 12px;margin-left: 10px;">支付宝</span>
                </div>
                <van-radio name="2" shape="square" icon-size=".9rem"/>
            </div>

            <div style="display: flex;align-items: center;justify-content: space-between;background: #ffffff;padding: 5px 15px;margin-bottom: 5px;">
                <div style="display: flex;">
                    <img :src="wxPayIcon" style="width: 18px;height: 18px;">
                    <span style="font-size: 12px;margin-left: 10px;">微信</span>
                </div>
                <van-radio name="3" shape="square" icon-size=".9rem"/>
            </div>

            <div style="display: flex;align-items: center;justify-content: space-between;background: #ffffff;padding: 5px 15px;margin-bottom: 5px;">
                <div style="display: flex;">
                    <img :src="qiliLogo" style="width: 18px;height: 18px;">
                    <span style="font-size: 12px;margin-left: 10px;">七里币 <span style="color: #888888">余额: {{qlb}}</span></span>
                </div>
                <van-radio name="1" shape="square" icon-size=".9rem"/>
            </div>
        </van-radio-group>
      </div>

      <div class="box-button">
        <van-button type="info" @click="submitPay" block native-type="submit" size="small"
                    :loading="loading" loading-text="正在支付"
                    color="#5A6EFC">
          确认支付
        </van-button>
        <p class="text-button" @click="back">取消支付
        </p>
      </div>
  </div>
</template>

<script>

  import {preOrder, getGameQLBalance, checkUser} from '@/api/request'
  //import crypto from '@/utils/crypto.js';
  import wxPayIcon from "@/assets/pay_wx.png";
  import qiliLogo from "@/assets/qiliLogo.png";
  import zfbPayIcon from "@/assets/pay_zfb.png";
  import { Toast } from 'vant'

  export default {
    name: 'pay',
    data: function () {
      return {
        wxPayIcon,
        zfbPayIcon,
        qiliLogo,
        qlb: '0',
        goodsName: '',
        money: '',
        orderMoney: '',
        uid: '-',
        radio: '',
        loading: false,
        channelId: '',
        gameId: '',
        order: {},
        showDiscount: false,
        preferenceMoney: '',
      }
    },
    mounted() {
      let user = JSON.parse(localStorage.getItem('user'));
      this.uid = user.userId;
      this.channelId = localStorage.getItem("channelId");
      this.gameId = localStorage.getItem("gameId");
    },
    methods: {
      initOrder(order) {
        this.goodsName = order.goodsName;
        this.orderMoney = "￥" + (order.money / 100).toFixed(2);
        this.money = order.money;
        this.order = order;

        let params = {
          channelId: this.channelId,
          device: "H5",
          sdkVersion: "1.0.0",
          gameId: this.order.gameId,
          cpOrder: this.order.cpOrderId,
          goodsName: this.goodsName,
          money: this.money,
          paymentType: this.handPayType(this.radio),
          serviceId: this.order.server,
          desc: this.goodsName,
          roleName: this.order.role,
        };
        checkUser(params).then(res => {
          if(res.data.success) {
            if (res.data.data.discount !== "1") {
             this.showDiscount = true;
             this.preferenceMoney = "- ￥" + res.data.data.preferenceMoney + ' / ' + res.data.data.discount ;
            }else{
              this.showDiscount = false;
            }
            this.orderMoney = "￥" + res.data.data.orderMoney;
          }
          else {
            Toast.fail(res.data.error.message);
          }
        });
      },
      back() {
        this.$parent.$parent.closePayPage();
      },
      getBalance() {
        let params = {
          channelId: this.channelId ,
          device: 'H5' ,
          gameId: this.gameId ,
        };
        getGameQLBalance(params).then(res => {
          if(res.data.success) {
            this.qlb = res.data.data;
          }
          else {
            Toast.fail(res.data.error.message);
          }
        });
      },
      submitPay() {
        if (!this.radio) {
            Toast('请选择支付方式');
            return ;
        }
        this.loading = true;
        let params = {
          channelId: this.channelId,
          device: "H5",
          sdkVersion: "1.0.0",
          gameId: this.order.gameId,
          cpOrder: this.order.cpOrderId,
          goodsName: this.goodsName,
          desc: this.goodsName,
          money: this.money,
          paymentType: this.handPayType(this.radio),
          serviceId: this.order.server,
          serverId: this.order.serverId,
          roleName: this.order.roleName || this.order.role,
          roleId: this.order.roleId || this.order.role,
          time: this.order.time,
          sign: this.order.sign
        };
        preOrder(params).then(res => {
          this.loading = false;
          if(res.data.success) {
            if (res.data.data.paymentType !== "1") {
              let payUrl = res.data.data.payString;
              this.$parent.$parent.openPayUrl(payUrl, res.data.data.paymentType, this.orderMoney);
            }
            this.back();
          }
          else {
            Toast.fail(res.data.error.message);
          }
        });
      },
      handPayType(payType) {
        let isMobile = this.isMobile();
        if (isMobile) {
          return payType;
        }
        if (payType === '1') {
          return '1'
        }
        if (payType === '2') {
            return '4'
        }
        if (payType === '3') {
          return '5'
        }
      },
      isMobile() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
        return flag;
      },
    }
  }
</script>

<style scoped lang="scss">
  .main-container {
    margin: .5rem .3rem .8rem;
    background-color: #F7F8FA;
    .center-top-title{
      width: 100%;
      vertical-align: center;
      height: 2rem;
      line-height: 2rem;
      h4{
        display: inline-block;
        font-size: 1rem;
        margin: 0;
        color: #5A6EFC;
      }

    }

    .info-input{
      background-color: #F7F8FA;
      padding: 0 16px;
    }
    .order-text{
      color: red;
    }

    /deep/.van-field__label{
      color: #b3b3b6;
    }

    .top-label{
      width: 100%;
      text-align: left;
      padding: 0 .8rem;
      color: #666666;
      font-size: .8rem;
      span{
        margin-left: .5rem;
      }
    }

    .down-input{
      margin-top: .8rem;
    }

    .width-line{
      height: 1px;
      margin: 0 1.04rem;
      border-bottom: 1px solid #b3b3b6;
    }

    .pay-class{
      text-align: left;
      padding: 0 16px;
      .van-cell{
        padding: 3px 16px;
        .van-cell__title{
          font-size: .8rem;
          font-weight: 600;
        }
      }
    }

    .box-button {
      margin: .7rem 1rem 0;
      p{
        margin: 0;
      }
      .text-button{
        font-size: .8rem;
        margin-top: .4rem;
        color: #888888;
      }
    }
    /*.van-cell::after {
      border-bottom: 0 solid #4d4e50;
    }*/
      .van-cell-group{
          margin: 0 15px;
          .van-cell{
              padding: 3px 16px;
              font-size: 12px;
              .van-cell__title{
                  text-align: left;
              }
          }
      }


      .order-money-text {
          font-weight: 600;
          color: red;
          font-size: 13px;
      }
  }
</style>
