<template>
    <div class="main">
        <div class="main-body">
            <div class="desc-info">
                <div v-if="!isHiddenBtn">
                    <img src="../../assets/shj/Invite_new_people.png" width="200" height="25">
                    <div class="task-condition" style="font-size: 16px;color: #252525;font-weight: 700">
                        好友邀请你来一起玩游戏啦
                    </div>
                    <div class="task-condition">
                        <div>在游戏中达成以下任务，好友可领五元微信红包哦:</div>
                        <div>1、从该页面下载游戏</div>
                        <div>2、在游戏浮窗里面绑定微信</div>
                        <div>3、游戏等级达到20级</div>
                    </div>
                    <div style="margin-top: 10px">
                        <img src="../../assets/shj/shj-bg.png" style="width: 100%;height: 150px;border-radius: 8px">
                    </div>
                    <div>
                        <div class="task-condition" style="font-size: 16px;color: #252525;font-weight: 700">
                            游戏简介
                        </div>
                        <div class="task-condition">
                            山海经巨作，玩法成熟，音乐古朴，送30真充，送1000返利红包，游戏内送1288抽，每两天早10点开区
                        </div>
                        <div style="margin-top: 15px">
                            <img src="../../assets/shj/xiazai.png" width="200" height="50" @click="toDownloadPage">
                        </div>
                    </div>
                </div>
                <div v-else>
                    <img src="../../assets/shj/Invite_new_people.png" width="200" height="25">
                    <img src="../../assets/shj/buoy_position.png" style="width: 100%;height: 8rem;border-radius: 8px;margin-top: 5px;">
                    <div style="color: #868686;font-size: 12px;margin: 5px 0">浮标位置如上图所示</div>
                    <img src="../../assets/shj/invite_publicity.png" style="width: 100%;height: 8rem;border-radius: 8px;margin-top: 5px">
                    <div class="task-condition">
                        <div>
                            好友邀请一个新人得5块，你是老玩家，也可以参与哦
                        </div>
                        <div>
                            1、进入山海经，点击小圆圈（见上图），分享给新玩家
                        </div>
                        <div>
                            2、新玩家去新区，达到20级，你就能领5元红包，公众号自动发到你的微信
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {Toast} from "vant";

export default {
    name: "shareNewUser",
    data(){
        return {
            bindCode: '',
            channel: '',
            isHiddenBtn: true
        }
    },
    mounted() {
        let shareUrl = this.$route.query.shareUrl;
        let errorMessage = this.$route.query.errorMessage;
        if(errorMessage){
            Toast(errorMessage)
            return;
        }
        if(!shareUrl){
            let url = window.location.href;
            let shareCode = url.split("?")[1].split("#")[0].split("&")[0].split("=")[1];
            window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?" +
                "appid=wxcd97d39a8899de98" +
                "&redirect_uri=" + encodeURIComponent("https://game.7li.cn/#/authForWx") +
                "&response_type=code" +
                "&scope=snsapi_userinfo" +
                "&state=" + shareCode + "" +
                "#wechat_redirect";
        }else {
            this.channel = shareUrl;
            this.isHiddenBtn = false;
        }
    },
    methods: {
        toDownloadPage(){
            location.href = "https://d.7li.cn/#/d/" + this.channel + "/366";
        }
    }
}
</script>

<style scoped>
    .main {
        display: flex;
        width: 100%;
        height: 100vh;
        font-family: "微软雅黑";
    }
    
    .main-body {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 600px;
        height: 100%;
        background-image: url(../../assets/shj/invite-bg.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin: 0 auto;
    }
    
    .desc-info{
        width: 80%;
        height: 60%;
        background-color: #f2f2f2;
        border-radius: 8px;
        margin: 15px 0;
        padding: 20px;
    }
    
    .task-condition{
        font-size: 12px;
        text-align: left;
        color: #868686;
        margin-top: 10px;
    }
    
</style>