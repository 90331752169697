<template>
    <div class="register-page">

        <div style="width: 100%;margin-top: 40px;text-align: left;padding: 10px 25px;font-size: 16px;box-sizing: border-box;display: flex;align-items: center;">
            <img src="../../assets/qiliLogo.png" style="width: 80px;height: 80px;">
            <div style="margin-left: 20px;">
                <div>您好</div>
                <div>欢迎来到<span style="font-weight: bold">七里游戏</span></div>
            </div>
        </div>

        <div class="main-border">
            <van-form @submit="onSubmit" ref="submitForm">
                <div style="font-size: 18px;font-weight: bold;text-align: left;margin-bottom: 15px;display: flex;align-items: center">
                    <span>账号注册</span>
                </div>

                <van-field label-width="60px" label="帐号"  placeholder="请输入新帐号" class="border-input" v-model="account" clearable/>

                <van-field label-width="60px" type="password" label="密码" style="margin-top: 20px;" placeholder="请输入登录密码"  v-model="password" clearable/>

                <van-field label-width="60px" type="password" label="确认密码" style="margin-top: 20px;" placeholder="请输入登录密码"  v-model="confirmPassword" clearable/>

                <div class="box-button">
                    <div v-if="gameId === '407'" class="read-div">
                        <div style="padding-top: 2px;text-align: left">
                            <van-checkbox v-model="isRead"  icon-size="14px" shape="square" checked-color="#606CFD">
                            </van-checkbox>
                        </div>
                        <div class="read-line" style="text-align: left;margin-left: 10px;">
                            我已阅读并同意了
                            <span @click="showDialog(lxhzUserUrl)" class="text-button">《嘉趣游戏用户协议》</span>和
                            <span @click="showDialog(lxhzPrivacyUrl)" class="text-button">《嘉趣游戏隐私政策》</span>
                        </div>
                    </div>

                    <div v-else class="read-div">
                        <div style="padding-top: 2px;text-align: left">
                            <van-checkbox v-model="isRead"  icon-size="14px" shape="square" checked-color="#606CFD">
                            </van-checkbox>
                        </div>
                        <div class="read-line" style="text-align: left;margin-left: 10px;">
                            我已阅读了
                            <span @click="showDialog(readUrl)" class="text-button">《七里游戏用户协议》</span>
                        </div>
                    </div>

                    <van-button type="info" block native-type="submit" :loading="loading" style="height: 35px;"
                                loading-text="正在注册"
                                color="#5A6EFC">
                        注   册
                    </van-button>

                    <div style="font-size: 13px;margin-top: 15px;display: flex;justify-content: space-between;padding: 0 3px;">
                        <div class="text-button"></div>
                        <div @click="toLoginPage" style="color: #3478F6">返回登录页面</div>
                    </div>

                </div>
            </van-form>

            <van-dialog  class="main-dialog" v-model="readDialogShow" >
                <iframe v-show="true" class="iframe-box" :src="iframeUrl" frameborder="0" scrolling="y"
                        width="100%" height="100%" style="min-height: 20rem"></iframe>
            </van-dialog>

        </div>
    </div>
</template>

<script>

    import {register} from '@/api/request'
    import crypto from '@/utils/crypto.js';
    import accountIcon from "@/assets/account.png";
    import passwordIcon from "@/assets/password.png";

    import { Dialog,Toast } from 'vant';

    export default {
        name: 'login',
        data() {
            return {
                accountIcon,
                passwordIcon,
                account: '',
                password: '',
                confirmPassword: '',
                accountPattern: /^[a-zA-Z0-9]{6,12}$/,
                passwordPattern: /^[a-zA-Z0-9~!@#$%^&*()_+]{6,12}$/,
                loading: false,
                channelId: '',
                gameId: '',
                isRead: false,
                readDialogShow: false,
                iframeUrl: '',
                readUrl: 'https://game.7li.cn/static/sdk/user.html',
                lxhzUserUrl: 'https://game.7li.cn/static/sdk/user_lhxz.html',
                lxhzPrivacyUrl: 'https://game.7li.cn/static/sdk/privacy_lhxz.html',
            };
        },
        mounted() {
            this.channelId = localStorage.getItem("channelId");
            this.gameId = localStorage.getItem("gameId");
        },
        methods: {
            showDialog(url) {
                this.iframeUrl = url;
                this.readDialogShow = true;
            },
            onSubmit() {
                let ok = this.checkForm();
                if (ok !== 'ok') {
                    Toast(ok);
                    return ;
                }
                this.loading = true;
                let params = {
                    channelId: this.channelId ,
                    gameId: this.gameId ,
                    device: 'H5' ,
                    userName: this.account,
                    password: crypto.encrypt(this.password)
                };
                register(params).then(res => {
                    this.loading = false;
                    if(res.data.success) {
                        Dialog.alert({
                            message: '注册成功！',
                        }).then(() => {
                            this.toLoginPage();
                        });
                    }
                    else {
                        Dialog.alert({
                            message: res.data.error.message,
                        }).then(() => {

                        });
                    }
                });
            },
            checkForm() {
                if (!this.account) {
                    return "请输入七里帐号";
                }
                if (!this.accountPattern.test(this.account)) {
                    return "七里帐号为6-12位数字字母";
                }
                if (!this.password) {
                    return "请输入密码";
                }
                if (!this.passwordPattern.test(this.password)) {
                    return "密码为6-12位数字字母特殊符号";
                }
                if (!this.confirmPassword) {
                    return "请再次输入密码";
                }
                if (this.password !== this.confirmPassword) {
                    return "两次密码输入不一致，请重新输入";
                }
                if (!this.isRead) {
                    return '帐号注册需要同意七里游戏用户协议';
                }
                return "ok";
            },

            toLoginPage() {
                this.$router.back();
            },

        }
    }
</script>

<style scoped lang="scss">
    .register-page {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;

        .main-border {
            max-width: 500px;
            text-align: center;
            background-color: white;
            border-radius: .3rem;
            width: 85%;
            border: 1px solid #eceaea;
            padding: 20px 20px;
            box-sizing: border-box;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

            .left-top-title{
                width: 100%;
                text-align: left;
                padding: 0 .8rem;
                color: #666666;
                h2{
                    font-size: 1.3rem;
                }
            }

            .top-label{
                width: 100%;
                text-align: left;
                padding: 0 .8rem;
                color: #666666;
                font-size: .8rem;
                span{
                    margin-left: .5rem;
                }
            }

            .down-input{
                margin-top: .8rem;
            }
            .van-cell{
                padding: 8px 16px;
            }
            .forget-row{
                margin-top: 1.5rem;
                font-size: .8rem;
            }
            .box-button {
                margin-top: 10px;
                font-size: .8rem;
                .read-div{
                    display: flex;
                    align-items: flex-start;
                    margin: 15px 0;
                }

                /deep/html::-webkit-scrollbar, /deep/body::-webkit-scrollbar{width:0px;height:0px;}

                .text-button{
                    color: #3478F6;
                }
                .register{
                    margin-top: .8rem;
                }
                .van-checkbox{
                    display: inline-flex;
                }
            }

            .van-dialog{
                border-radius: 3px;
                padding: 10px 10px 0 10px;
            }
        }

        .van-field{
            outline: 1px #dbdbdb solid;
            border-radius: 3px;
        }
        .van-field:focus-within{
            outline: 2px #3478F6 solid;
        }
        /deep/.van-field__control {
            //color: #3478F6;
        }
    }
</style>
