console.log('process.env',process.env)
const serverList = [
  {
    BASEURL: 'http://192.168.0.198:8891',
  },
  {
    BASEURL: 'https://gameapi.7li.cn',
  },
  {
    BASEURL: 'https://test-api.7liyx.cn',
  },
  {
    BASEURL: 'http://192.168.0.8:8891',
  },
];
let serverArr = serverList[1]
export default serverArr
