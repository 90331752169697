<template>
  <div class="back-div">
  <div class="main-page">
    <!--<van-button @click="testRole">角色</van-button>-->
    <iframe v-show="true" ref="gameIframe" :src="gameUrl" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>

  </div>
  </div>
</template>

<script>

import {dzbGetGameUrl,dzbPreOrder,dzbUploadRole} from '@/api/request'
import mainRoundImg from "@/assets/main-round.png";
import { Toast } from 'vant'

export default {
  name: 'dazuiba',//大嘴霸 联运
  data() {
    return {
      mainRoundImg,
      channelId: '132',//固定值
      game_id: '',
      user_id: '',
      server_id:'',
      channelExt: '',
      game_appid: '',
      localUserId: '',
      gameUrl: '',
    };
  },
  mounted() {
    this.importDzbJs();
    this.game_id = 111;
    this.user_id = this.$route.query.user_id;
    let timestamp = this.$route.query.timestamp;
    this.channelExt = this.$route.query.channelExt;
    let agent_id = this.$route.query.agent_id;
    this.game_appid = this.$route.query.game_appid;
    let loginplatform2cp = this.$route.query.loginplatform2cp;
    let birthday = this.$route.query.birthday;
    let sign = this.$route.query.sign;
    localStorage.setItem("channelId", this.channelId);
    localStorage.setItem("gameId", this.game_id);
    if (!this.gameUrl) {
      let params = {
        channelId: this.channelId ,
        game_id: this.game_id ,
        user_id: this.user_id ,
        timestamp: timestamp ,
        channelExt: this.channelExt ,
        agent_id: agent_id ,
        game_appid: this.game_appid ,
        loginplatform2cp: loginplatform2cp ,
        birthday: birthday ,
        sign: sign ,
      };
      dzbGetGameUrl(params).then(res => {
        if(res.data.success) {
          this.gameUrl = res.data.data.loginUrl;
          this.localUserId = res.data.data.userId;
          console.log('返回游戏页面', this.gameUrl);
        }
        else {
          Toast.fail('拉取游戏数据异常');
        }
      });
    }
    this.initListener();
  },
  methods: {
    importDzbJs() {
      const oScript = document.createElement('script');
      oScript.type = 'text/javascript';
      oScript.src = 'https://www.zjdzb.com/themes/simpleboot3/sdkh5/assets/js/xigusdk/h5gamesdk.js';
      document.body.appendChild(oScript);
    },
    initListener() {
      window.addEventListener("message", this.listener);
    },
    listener(event) {
      let me = this;
      if (event.data.operation === 'pay') {
        console.log("sdk触发支付");
        let order = event.data.param;
        me.preOrder(order);
      }
      else if (event.data.operation === 'uploadGameRole') {
        console.log("sdk触发角色上报");
        let param = event.data.param;
        me.uploadRole(param);
      }
      else if (event.data.operation === 'repeatLogin') {
        console.log("sdk触发登陆被顶");
        me.logout();
      }
    },
    preOrder(order) {
      order.channelExt = this.channelExt;
      order.channelId = this.channelId;
      order.userId = this.localUserId;
      order.cpOrder = order.cpOrderId;
      order.serviceId = order.server;
      order.serviceName = order.server;
      order.desc = order.goodsName;
      order.roleName = order.role;
      order.roleId = order.role;
      let params = {
        amount : order.money,
        channelExt : this.channelExt,
        game_appid : this.game_appid,
        props_name : order.goodsName,
        user_id : this.user_id,
        timestamp : order.time,
        server_id : order.server,
        server_name : order.server,
        role_id : order.role,
        role_name : order.role,
        role_level : 1,
      }

      dzbPreOrder(order).then(res => {
        if(res.data.success) {
          params.sign = res.data.data.sign;
          params.trade_no = res.data.data.orderId;
          params.server_name = res.data.data.server_name;
          params.role_name = res.data.data.role_name;
          XG_H5_GAME_SDK.pay (params,function(data){
            console.log('大嘴霸 SDK 支付接口');
            console.log(data);
          });
        }
        else {
          Toast.fail(res.data.error.message);
        }
      });
    },
    uploadRole(roleParam) {
      roleParam.serviceId = roleParam.serverId;
      roleParam.serviceName = roleParam.serverName;
      roleParam.channelExt = this.channelExt;
      let params = {
        user_id : this.user_id,
        game_appid : this.game_appid,
        server_id : roleParam.serverId,
        server_name : roleParam.serverName,
        role_id : roleParam.roleId,
        role_name : roleParam.roleName,
        role_level : 1,
        combat_number : '1',
        channelExt : this.channelExt,
      }

      dzbUploadRole(roleParam).then(res => {
        if(res.data.success) {
          console.log("上报后台角色信息成功");
          params.sign = res.data.data.sign;
          params.timestamp = res.data.data.time;
          XG_H5_GAME_SDK.role(params,function(data){
            console.log('大嘴霸 SDK 角色上报接口');
            console.log(data);
          });
        }
        else {
          console.log("上报后台角色信息失败");
        }
      });
    },
    logout() {
       console.log("登出");
    },
  }
}
</script>

<style scoped lang="scss">
.back-div{
  background-color: #eceaea;

  .main-page {
    position: relative;
    max-width: 600px;
    background-color: #ffffff;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    touch-action: none;
    margin: 0 auto;
    .main-dialog{

    }

    .loading-dialog {
      position: fixed;
      top: 40%;
      left: 20%;
      width: 60%;
      height: 5rem;
    }
  }
}


</style>
