<template>
    <div class="main-container">
        <van-button @click="pay">支付</van-button>
        <van-button @click="uploadGameRole">上报角色</van-button>
        <van-button @click="repeatLogin">登录被顶</van-button>
    </div>
</template>

<script>

import verifyCodeIcon from "@/assets/verifyCode.png";
import phoneIcon from "@/assets/phone.png";
import backIcon from "@/assets/back.png";

import {md5} from '@/utils/md5'
import {Toast} from "vant";

export default {
    name: 'gameTest',
    data() {
        return {
            phoneIcon,
            verifyCodeIcon,
            backIcon,
            gameId: 103,
            userId: 6335,
            qiliSdk: null,
            sdkInit: false,
            testKey: '0810e1220a8742198ae3cfc976e6db2f',
        };
    },
    mounted() {
        this.importJs();
        setTimeout(() => {
            this.initQiliSdk();
        }, 1000)
    },
    methods: {
        importJs() {
            const oScript = document.createElement('script');
            oScript.type = 'text/javascript';
            oScript.src = 'https://game.7li.cn/static/sdk/qili.sdk.js';
            document.body.appendChild(oScript);
        },
        initQiliSdk() {
            this.qiliSdk = window.QILI_GAME_SDK;
            if (this.qiliSdk) {
                let params = {gameId: this.gameId};
                this.qiliSdk.init(params); //初始化
                this.sdkInit = true;
            }
        },
        uploadGameRole() {
            if(this.checkSdkInit()) return;
            let roleParam = {
                gameId: this.gameId,
                userId: this.userId,
                "serverId": "1",
                "serverName": "1区",
                "roleId": "1",
                "roleName": "zs",
                "remark": "{'serverId':'1','serverName':'1区'}",
            };//参数说明请参考⽂档
            this.qiliSdk.uploadGameRole(roleParam);
        },
        //⽤户点击商品下单
        pay() {
            if(this.checkSdkInit()) return;
            let order = {
                gameId: this.gameId,
                userId: this.userId,
                server: "昊天一区",
                time: new Date().getTime() + "",
                role: "通天塔发",
                goodsName: "10000元宝",
                money: "250",
                cpOrderId: "QILI2022" + new Date().getTime(),
            };
            order.sign = this.sign(order);
            this.qiliSdk.pay(order);
        },
        //登录被顶
        repeatLogin() {
            if(this.checkSdkInit()) return;
            this.qiliSdk.repeatLogin();
        },
        checkSdkInit() {
            if (!this.sdkInit) {
                this.initQiliSdk();
                Toast('sdk还未初始化成功，请稍后重试');
                return true;
            }
            else {
                return false;
            }
        },
        sign(data) {
            let sortedParam = this.sorter(data);
            let needSignatureStr = "";
            for (let key in sortedParam) {
                let value = sortedParam[key];
                needSignatureStr = needSignatureStr + key + '=' + value + '&';
            }
            needSignatureStr += this.testKey;
            console.log('需加密串:' + needSignatureStr);
            let sign = md5(needSignatureStr)
            console.log('加密结果:' + sign);
            return sign;
        },
        sorter(inObject) {
            let sortedJson = {};
            let sortedKeys = Object.keys(inObject).sort();
            for (let i = 0; i < sortedKeys.length; i++) {
                sortedJson[sortedKeys[i]] = inObject[sortedKeys[i]]
            }
            return sortedJson;
        },
    }
}
</script>

<style scoped lang="scss">
.main-container {

}
</style>
