<template>
  <div class="main-container">
    <div class="left-top-title">
      <h2>红包</h2>
    </div>

    <div class="user-panel">
      <div class="user-name">{{username}}</div>
      <div class="user-info">{{userID}}</div>
      <div class="user-info">{{gameInfo}}</div>
      <div class="balance-panel">
        <div class="left">
          <span>我的余额: 128.50</span>
        </div>
        <div class="right">
          <span @click="goCashoutPage">提现</span>
        </div>
      </div>
    </div>

    <van-tabs>
      <van-tab title="等级红包">
        <div class="red-list-panel">
          <div class="red-panel" v-for="i in levelList">
            <van-image width="60px" height="70px" :src="redpaperIcon">
            </van-image>
            <div class="name-panel">
              <span class="name">{{i.red_name}}</span>
            </div>
            <span>待领取</span>
          </div>
        </div>
      </van-tab>
      <van-tab title="充值红包">
        <div class="red-list-panel">
          <div class="red-panel" v-for="i in rechargeList">
            <van-image width="60px" height="70px" :src="redpaperIcon">
            </van-image>
            <div class="name-panel">
              <span class="name lc">{{i.red_name}}</span>
            </div>
            <span>待领取</span>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
  import {queryRedList} from '@/api/request';

import backIcon from "@/assets/back.png";
import redpaperIcon from "@/assets/redpaper.png";
import { Toast } from 'vant'

export default {
  name: 'redPaper',
  data() {
    return {
      backIcon,
      redpaperIcon,
      show: false,
      username: '全服第一',
      userID: 'ID: 88790',
      gameInfo: '当前区服: S_122   当前等级: 170级',
      levelList: [],
      rechargeList: [],
      userId: '',
      adminId: '',
      gameId: '',
    };
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem('user'));
    this.userId = user.userId;
    this.adminId = user.adminId;
    this.gameId = localStorage.getItem('gameId');
    this.initRedListData();
  },
  methods: {
    goCashoutPage() {
      this.$router.push({ path: `/open/cashout` });
    },
    showDialog() {
      this.show = true;
    },
    initRedListData() {
      let params = {
        gameId: this.gameId,
        adminId: this.adminId,
        userId: this.userId,
      };
      queryRedList(params).then(res => {
        if (res.data.success) {
          this.levelList = res.data.data.levelList;
          this.rechargeList = res.data.data.rechargeList;
        }
        else {
          Toast(res.data.error);
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.main-container {
  overflow: hidden;
  position: relative;
  .left-top-title{
    display: flex;
    /*实现垂直居中*/
    align-items: center;
    justify-content: left;
    width: 100%;
    padding-left: 20px;
    color: #666666;
    vertical-align: center;
    height: 3rem;
    line-height: 3rem;
    .back-icon{
      display: inline-block;
    }
    h2{
      display: inline-block;
      font-size: 1.3rem;
      margin: 0;
    }

  }
  .user-panel{
    text-align: left;
    padding: 15px 20px 0 20px;
    font-size: 12px;
    color: #666666;
    background-color: rgba(230,154,63,0.15);

    .user-name{
      font-weight: bold;
      font-size: 14px;
      color: #333333;
    }
    .user-info{
      margin-top: 5px;
    }
    .balance-panel{
      display: flex;
      font-size: 15px;
      margin-top: 10px;
      height: 40px;
      line-height: 40px;
      color: white;
      text-align: center;
      .left{
        border-start-start-radius: 10px;
        width: 70%;
        background-color: black;
      }
      .right{
        border-start-end-radius: 10px;
        width: 30%;
        background-color: #E8B33E;
        color: black;
        font-weight: 600;
      }
    }
  }
  /deep/.van-tabs__wrap{
    width: 50%;
    span {
      font-weight: bold;
    }
  }
  .red-list-panel{
    margin-top: 20px;
    display: flex;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;  // 重点
    }
    .red-panel{
      position: relative;
      display: flex;
      box-sizing: border-box;
      flex-direction: column;
      padding: 15px 10px 5px 10px;
      background-color: rgb(253,242,180);
      margin: 0 5px;
      border-radius: 8px;
      .name-panel{
        width: 60px;
        text-align: center;
        position: absolute;
        bottom: 35px;
        .name{
          color: rgb(253,242,180);
        }
        .lc{
          font-size: 14*0.6px;
        }
      }

      span {
        color: rgb(182,154,61);
        font-size: 13px;
      }
    }
  }
}
</style>
