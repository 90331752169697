<template>
    <div class="pay-code-panel">
        <span>请用{{payName}}扫码支付，支付金额 {{payPrice}}</span>
        <!--<van-image :src="payCode" width="9.8rem"></van-image>-->
        <div class="qr-code" ref="qrCodeUrl"></div>
    </div>
</template>

<script>
  import QRCode from 'qrcodejs2'

  export default {
    name: 'pay-code',
    data() {
      return {
        payCode: '',
        payType: '',
        payName: '',
        payPrice: '',
        qrCode: {},
      }
    },
    mounted() {

    },
    methods: {
      init() {
        if (this.payType === '4') {
          this.payName = '支付宝';
        }
        else if (this.payType === '5') {
          this.payName = '微信';
        }
        this.creatQrCode();
      },
      creatQrCode() {
        //console.log(this.$refs.qrCodeUrl.innerHTML = "");
        this.$refs.qrCodeUrl.innerHTML = ""
        if (this.qrCode._oQRCode) {
          this.qrCode.clear(); //清除
        }
        this.qrCode = new QRCode(this.$refs.qrCodeUrl, {
          text: this.payCode,
          width: 250,
          height: 250,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: 3

        });
      }
    }
  }
</script>

<style scoped lang="scss">
    .pay-code-panel{
        margin-top: 1.2rem;
        font-size: .8rem;
        padding: 0 .8rem;
        span{
            display: block;
            margin-bottom: .9rem;
        }

        .qr-code{
            /*实现垂直居中*/
            display: inline-block;
            align-items: center;
            justify-content: center;
            text-align: center;
            border: 2px solid black;
            padding: 6px ;
            img{
                /*实现垂直居中*/
                align-items: center;
                justify-content: left;
                margin: 0 auto;
                display: unset !important;
                padding: 6px ;
            }
        }
    }
</style>
