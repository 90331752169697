<template>

    <div>
        <!-- 登录页面 -->
        <div v-if="step === 1">
            <loginPage ref="loginPage"></loginPage>
        </div>

        <div v-if="step === 2" class="back-div">
            <div class="main-page" ref="mainPage">
                <!--<van-button @click="testPay">支付</van-button>-->
                <div class="diy-dialog" v-show="diyDialogShow">
                    <div style="text-align: center">信息</div>
                    <van-divider/>
                    <div style="height: 80px">您将打开支付页面，请问是否继续？<br><br>
                        <span style="color: #b3b3b6; font-size: 13px;">注：支付成功后请关闭新打开的支付窗口</span>
                    </div>
                    <van-divider/>
                    <div class="diy-dialog-buttons">
                        <div>
                            <a @click="closeDiyDialog()">取消</a>
                        </div>
                        <div>
                            <a target="_blank" :href="payUrl" @click="closeDiyDialog()">继续</a>
                        </div>

                    </div>

                </div>

                <iframe v-show="true" allowfullscreen ref="gameIframe" :src="gameUrl" frameborder="0" scrolling="no"
                        width="100%"
                        height="100%"></iframe>

                <van-dialog :style="dialogStyle" style="background-color: #F7F8FA" v-model="payShow"
                            :showConfirmButton="false">
                    <pay ref="pay"></pay>
                </van-dialog>

                <!--<van-dialog class="pay-url-dialog" v-model="payDialogShow" confirm-button-text="支付完成点击关闭">
                  <pay-dialog ref="payDialog"></pay-dialog>
                </van-dialog>-->

                <van-dialog class="pay-code-dialog" v-model="payCodeShow" confirm-button-text="支付完成点击关闭">
                    <pay-code ref="payCode"></pay-code>
                </van-dialog>

                <van-dialog class="pay-code-dialog" v-model="payCodeImgShow" confirm-button-text="支付完成点击关闭">
                    <pay-code-img ref="payCodeImg"></pay-code-img>
                </van-dialog>

                <van-dialog :style="dialogStyle" class="main-dialog" v-model="videoPageDialogShow"
                            :showConfirmButton="false">
                    <videoPage ref="videoPage" v-if="videoPageShow"></videoPage>
                </van-dialog>

                <van-dialog v-if="mainDialogShow" :style="dialogStyle" class="main-dialog" v-model="mainDialogShow" :showConfirmButton="false"
                            close-on-click-overlay>
                    <!--          <qili-main ref="mainDialog" v-if="mainShow"></qili-main>-->
                    <!--          <kf v-if="kfShow"></kf>-->
                    <!--          <gamespeed  ref="gameSpeedDialog" v-if="jsPageShow"></gamespeed>-->
                    <!--          <real-name ref="realNameDialog" v-if="realNameShow"></real-name>-->
                    <!--          <unbind-tel v-if="unBingTelShow"></unbind-tel>-->
                    <!--          <bind-tel ref="bindTelDialog" v-if="bingTelShow"></bind-tel>-->
                    <!--          <change-password v-if="changePasswordShow"></change-password>-->
                    <iframe :src="iframeUrl" frameborder="0" scrolling="no" width="100%" height="320px"/>
                </van-dialog>

                <van-dialog :style="dialogStyle" class="main-dialog" v-model="checkDialogShow"
                            :showConfirmButton="false" close-on-click-overlay>
                    <real-name ref="realNameDialog" v-if="realNameShow"></real-name>
                    <bind-tel ref="bindTelDialog" v-if="bingTelShow"></bind-tel>
                </van-dialog>

                <div v-if="isShow" class="xuanfu" id="moveDiv"
                     @mousedown="down($event)"
                     @touchstart="down($event)"
                     @mousemove="move($event)"
                     @touchmove="move($event)"
                     @mouseover="end($event)"
                     @mouseleave="move($event)"
                     @mouseup="end($event)"
                     @touchend="end($event)"
                     @click="showMainWindow">

                    <van-image :src="mainRoundImg" width="50px" height="50px"></van-image>

                </div>

                <div v-show="false">
                    <van-count-down
                        ref="countDown"
                        :time="time"
                        :auto-start="false"
                        format="ss"
                        @finish="finish"
                    />
                </div>
            </div>
            <van-overlay :show="openPageShow" @click="openPageShow = false">
                <div class="right-panel">
                    <van-icon @click="closeOutsidePage" style="font-size: 25px;" name="arrow-down"/>
                    <iframe ref="webview_iframe" v-show="true" style="overflow-x:auto;overflow-y:auto"
                            allowfullscreen
                            :src="openPageUrl"
                            frameborder="0"
                            width="100%"
                            height="100%" scrolling="yes">
                    </iframe>
                </div>
            </van-overlay>

        </div>
    </div>

</template>

<script>

import {roleSync, logout, login} from '@/api/request'

import changePassword from "@/components/changepassword.vue";
import bindTel from "@/components/bindtel.vue";
import unbindTel from "@/components/unbindtel.vue";
import realName from "@/components/realname.vue";
import pay from "@/components/pay.vue";
import payHorizontal from "@/components/payhorizontal.vue";
import qiliMain from "@/components/qilimain.vue";
import kf from "@/components/kf.vue";
import gamespeed from "@/components/gamespeed.vue";
import videoPage from "@/components/video.vue";
import payDialog from "@/components/pay-dialog.vue";
import payCode from "@/components/pay-code.vue";
import payCodeImg from "@/components/pay-code-img.vue";
import loginPage from "@/components/login.vue";
import mainRoundImg from "@/assets/main-round.png";
import {Dialog, Toast} from 'vant';
import timerhook from '@/utils/timerhook';

export default {
    name: 'mainPage',
    components: {
        'change-password': changePassword,
        'bind-tel': bindTel,
        'unbind-tel': unbindTel,
        'real-name': realName,
        'pay': pay,
        'pay-horizontal': payHorizontal,
        'qili-main': qiliMain,
        'kf': kf,
        'videoPage': videoPage,
        'pay-dialog': payDialog,
        'pay-code': payCode,
        'pay-code-img': payCodeImg,
        loginPage,
        gamespeed
    },
    data() {
        return {
            step: 0,
            mainRoundImg,
            payShow: false,
            mainDialogShow: false,

            mainShow: false,
            changePasswordShow: false,
            bingTelShow: false,
            unBingTelShow: false,
            realNameShow: false,
            kfShow: false,
            openPageShow: false,
            videoPageShow: false,
            videoPageDialogShow: false,
            payDialogShow: false,
            payCodeShow: false,
            payCodeImgShow: false,

            diyDialogShow: false,

            flags: false,
            position: {x: 0, y: 0},
            nx: '', ny: '', dx: '', dy: '', xPum: '', yPum: '',
            isShow: false,

            channelId: '',
            gameId: '',

            buttonHidden: true,
            halfOffset: 0,
            time: 10000,
            firstClick: true,
            gameUrl: '',
            tempGameUrl: '',
            payUrl: '',
            screenModel: 1,//当前屏幕方向 1-竖屏 2-横屏
            screenType: 0,//游戏类型 1-竖屏 2-横屏
            dialogStyle: {},
            openPageUrl: '',//外部链接地址

            path: location.protocol + '//' + location.host + '/#' + this.$route.path, //当前页面url
            speedRate: 1,
            showJsButton: 0,
            jsPageShow: false,
            checkDialogShow: false,
            iframeUrl: '',
        };
    },
    mounted() {
        console.log('页面初始化');
        this.from = this.$route.query.from;
        this.handleQlWebsite();
        this.initUrlParams();
        this.handleLoginStatus();

        /*this.$nextTick(function () {
          //this.openLuckyPage('送福利啦', 'https://h5.7li.cn/static/image/demo.mp4', 1);
          this.openLuckyPage('送福利啦', 'https://img01.yzcdn.cn/vant/cat.jpeg', 2);
        })*/
    },
    methods: {
        handleOrientationChange(mql) {
            this.dialogStyle = {};
            if (mql.matches) {//旋转屏幕为横屏
                this.screenModel = 1;
                if (this.screenType === 2) {
                    this.dialogStyle = {
                        'position': 'absolute',
                        //'display': 'table',
                        'top': '30%',
                        'left': '10%',
                        'backface-visibility': 'hidden',
                        'transform': 'rotate(90deg)',
                        '-ms-transform': 'rotate(90deg)',
                        '-moz-transform': 'rotate(90deg)',
                        '-webkit-transform': 'rotate(90deg)',
                        '-o-transform': 'rotate(90deg)',
                    };
                }
            } else {
                this.screenModel = 2;
                if (this.isMobile() && this.screenType !== 2) {
                    Dialog.alert({
                        message: '为了更好的游戏体验，请关闭手机旋转功能',
                    }).then(() => {
                    });
                }
            }
        },
        noticeScreen(mql) {
            if (this.isMobile()) {
                Dialog.alert({
                    message: '为了更好的游戏体验，请关闭手机旋转功能',
                }).then(() => {
                });
            }
            this.dialogStyle = {};
            if (mql.matches) {//旋转屏幕为横屏
                if (this.screenType === 2) {
                    this.dialogStyle = {
                        'position': 'absolute',
                        //'display': 'table',
                        'top': '30%',
                        'left': '10%',
                        'backface-visibility': 'hidden',
                        'transform': 'rotate(90deg)',
                        '-ms-transform': 'rotate(90deg)',
                        '-moz-transform': 'rotate(90deg)',
                        '-webkit-transform': 'rotate(90deg)',
                        '-o-transform': 'rotate(90deg)',
                    };
                }
            }
        },
        handleQlWebsite() {
            if (this.from === 'qlWebsite') {
                this.u = this.$route.query.u;
                this.p = this.$route.query.p.replace(' ', '+');
                localStorage.setItem('username', this.u);
                localStorage.setItem('password', this.p);

                location.replace(this.path);
            }
        },
        initUrlParams() {
            this.channelId = this.$route.path.split('/')[2];
            this.gameId = this.$route.path.split('/')[3];
            this.showJsButton = this.$route.query.showJsButton;
            localStorage.setItem("channelId", this.channelId);
            localStorage.setItem("gameId", this.gameId);
        },
        handleLoginStatus() {
            //官网和七里app过来的直接自动登录
            if (this.from === 'qlWebsite' || this.from === 'qiliApp') {
                let account = localStorage.getItem('username');
                let password = localStorage.getItem('password');
                if (!account || !password) {
                    this.step = 1;
                    this.$nextTick(function () {
                        this.$refs.loginPage.from = this.from;
                    })
                    return;
                }

                let params = {
                    channelId: this.channelId,
                    device: 'H5',
                    gameId: this.gameId,
                    userName: account,
                    password: password
                };
                login(params).then(res => {
                    if (res.data.success) {
                        localStorage.setItem('token', res.data.data.token);
                        localStorage.setItem('user', JSON.stringify(res.data.data));

                        this.screenType = res.data.data.screenType;
                        if (this.isMobile()) {
                            console.log('手机端');
                            // let mql = window.matchMedia('(orientation: portrait)');
                            // this.noticeScreen(mql);
                            // mql.addListener(this.handleOrientationChange);
                        }
                      
                        let loginUrl = res.data.data.loginUrl;
                        localStorage.setItem('loginUrl', loginUrl);
                        this.enterGame();
                    } else {
                        this.step = 1;
                        Toast.fail(res.data.error.message);
                    }
                });
            } else {
                this.step = 1;
                this.$nextTick(function () {
                    this.$refs.loginPage.from = this.from;
                })
            }
        },
        enterGame() {
            this.step = 2;
            let user = JSON.parse(localStorage.getItem("user"));
            if (!!user) {
                this.screenType = user.screenType;
            }

            let loginUrl = localStorage.getItem('loginUrl');
            this.isBindPhone(loginUrl);
            localStorage.removeItem('loginUrl');

            this.isShow = true;
            this.$nextTick(function () {
                //this.start();
                this.initListener();
                if (this.screenType === 1) {
                    this.$refs.mainPage.style.maxWidth = "600px";
                }
            })

            if (this.from === 'htfapp') {//昊天服app开启加速按钮 灵魂序章app
                this.showJsButton = 1;
            }

            this.iframeUrl = "https://sdkh5.7li.cn/#/sdk/main?gameId=" + this.gameId + "&token=" + localStorage.getItem("token") + "&from=h5&showJsButton=" + this.showJsButton;
            //this.iframeUrl = "http://192.168.1.192:8081/#/sdk/main?gameId=" + this.gameId + "&token=" + localStorage.getItem("token")  + "&from=h5&showJsButton=" + this.showJsButton;
        },
        setSpeed(n) {
            this.speedRate = n;
            timerhook.setSpeed(n);
            Toast('游戏当前加速倍率：' + n);
        },
        isBindPhone(loginUrl) {
            this.tempGameUrl = loginUrl;
            let user = JSON.parse(localStorage.getItem("user"));
            if (!user.phone) {
                this.checkDialogShow = true;
                this.bingTelShow = true;
                this.$nextTick(function () {
                    this.$refs.bindTelDialog.backShow = false;
                    this.$refs.bindTelDialog.missShow = true;
                })
            } else {
                this.isRealName();
            }
        },
        isRealName() {
            let user = JSON.parse(localStorage.getItem("user"));
            if (user.isUserCheck !== '1') {
                this.checkDialogShow = true;
                this.realNameShow = true;
                this.$nextTick(function () {
                    this.$refs.realNameDialog.backShow = false;
                    this.$refs.realNameDialog.missShow = user.forcePersonCheck !== '1';
                })
            } else {
                this.gameUrl = this.tempGameUrl;
                //this.gameUrl = 'http://192.168.1.192:8083/#/test2';
            }
        },
        missBindTel() {
            this.checkDialogShow = false;
            this.bingTelShow = false;
            this.isRealName();
        },
        initListener() {
            window.addEventListener("message", this.listener);
        },
        listener(event) {
            console.dir(event);
            let me = this;
            if (event.data.operation === 'pay') {
                console.log("触发支付");
                let order = event.data.param;
                me.openPayPage(order);
            } else if (event.data.operation === 'uploadGameRole') {
                console.log("触发角色上报");
                let param = event.data.param;
                me.uploadRole(param);
            } else if (event.data.operation === 'repeatLogin') {
                console.log("触发登陆被顶");
                me.logout();
            } else if (event.data.operation === 'closeMenu') {
                this.closeMainWindow();
            } else if (event.data.operation === 'logout') {
                this.logout();
            } else if (event.data.operation === 'copy') {
                this.copy(event.data.param.content);
            } else if (event.data.operation === 'startIntent') {
                this.$toast(event.data.param.message);
            } else if (event.data.operation === 'gameSpeed') {
                this.setSpeed(event.data.param.speed);
            }
            else if (event.data.operation === 'showIOSPay') {
                this.closeMainWindow();
                this.payUrl = event.data.url;
                this.diyDialogShow = true;
            }
        },
        uploadRole(roleParam) {
            let params = {
                channelId: this.channelId,
                device: "H5",
                gameId: roleParam.gameId,
                userId: roleParam.userId,
                roleId: roleParam.roleId,
                roleName: roleParam.roleName,
                serverId: roleParam.serverId,
                serverName: roleParam.serverName,
                roleLevel: roleParam.roleLevel,
                vipLevel: roleParam.vipLevel,
                combatNum: roleParam.combatNum,
                partyId: roleParam.partyId,
                partyName: roleParam.partyName,
                partyMasterId: roleParam.partyMasterId,
                partyMasterName: roleParam.partyMasterName,
                eventType: roleParam.eventType,
                cpSign: roleParam.sign,
            }
            roleSync(params).then(res => {
                if (res.data.success) {
                    this.$refs.gameIframe.contentWindow.postMessage({operation: "onRole", param: 'success'}, "*");
                } else {
                    console.log(res.data.error.message);
                }
            });
        },
        start() {
            this.$refs.countDown.reset();
            this.$refs.countDown.start();
        },
        reset() {
            this.$refs.countDown.reset();
        },
        finish() {
            this.unActiveButton();
        },
        //实现移动端拖拽
        down(event) {
            this.flags = true;
            let touch;
            if (event.touches) {
                touch = event.touches[0];
            } else {
                touch = event;
            }
            this.position.x = touch.clientX;
            this.position.y = touch.clientY;
            this.dx = moveDiv.offsetLeft;
            this.dy = moveDiv.offsetTop;
        },
        move(event) {
            if (this.flags) {
                if (this.buttonHidden) {
                    this.activeButton();
                }
                let touch;
                if (event.touches) {
                    touch = event.touches[0];
                } else {
                    touch = event;
                }
                this.nx = touch.clientX - this.position.x;
                this.ny = touch.clientY - this.position.y;
                this.xPum = this.dx + this.nx;
                this.yPum = this.dy + this.ny;
                moveDiv.style.left = this.xPum + "px";
                moveDiv.style.top = this.yPum + "px";
                //阻止页面的滑动默认事件；如果碰到滑动问题，1.2 请注意是否获取到 touchmove
                document.addEventListener("touchmove", function () {
                    event.preventDefault();
                }, false);
            }
        },
        //鼠标释放时候的函数
        end(event) {

            this.flags = false;
            if (!this.xPum) {
                return;
            }

            let {
                clientHeight: windowHeight,
                clientWidth: windowWidth
            } = document.documentElement;
            // 计算后坐标  设置 按钮位置
            //this.halfOffset = -moveDiv.clientWidth / 2;
            if (this.yPum > 50 && this.yPum < (windowHeight - 150)) { //  不在顶部 且 不在底部
                if (this.xPum <= (windowWidth / 2)) {  //  left 小于等于屏幕一半
                    moveDiv.style.left = 0;
                    moveDiv.style.right = 'auto';
                    //this.hiddenOnLeft();
                    this.start();
                } else { //  left 大于屏幕一半
                    moveDiv.style.left = 'auto';
                    moveDiv.style.right = 0;
                    this.start();
                }
            } else {
                if (this.yPum <= 50) {  //  在顶部
                    moveDiv.style.top = 0;
                    moveDiv.style.bottom = 'auto';
                    this.start();
                } else if (this.yPum >= (windowHeight - 150)) {
                    moveDiv.style.bottom = 0;
                    moveDiv.style.top = 'auto';
                    this.start();
                }
                if (this.xPum < 0) {
                    moveDiv.style.left = 0;
                    this.start();
                } else if (this.xPum > (windowWidth - moveDiv.clientWidth)) {
                    moveDiv.style.right = 0;
                    moveDiv.style.left = 'auto';
                    this.start();
                }
            }
        },
        activeButton() {
            //moveDiv.style.opacity = 1;
            this.buttonHidden = false;
            if (!moveDiv.style.left) {
                moveDiv.style.left = "0px"
            }

            if (moveDiv.style.left === '-25px') {
                moveDiv.style.left = "0px"
            } else if (moveDiv.style.right === '-25px') {
                moveDiv.style.right = "0px"
            }
            /*moveDiv.style.transform = `translate3d(${this.halfOffset+'px'}, 0, 0)`;
            moveDiv.style.transition = 'transform 1s linear 0s';*/
        },
        unActiveButton() {
            //moveDiv.style.opacity = 0.2;
            this.buttonHidden = true;
            if (moveDiv.style.left === '0px') {
                moveDiv.style.left = "-25px"
            } else if (moveDiv.style.right === '0px') {
                moveDiv.style.right = "-25px"
            }

            /*moveDiv.style.transform = `translate3d(${this.halfOffset+'px'}, 0, 0)`;
            moveDiv.style.transition = 'transform 1s linear 0s';*/
        },
        showMainWindow() {
            if (this.buttonHidden) {
                this.activeButton();
                this.start();
                return;
            }
            /*if (this.firstClick) {
              this.firstClick = false;
              return;
            }*/
            this.closeAll();
            this.jsPageShow = false;
            this.mainDialogShow = true;
            this.mainShow = true;
            // this.$nextTick(function () {
            //   this.$refs.mainDialog.getBalance();
            //   this.$refs.mainDialog.showJsButton = this.showJsButton;
            // })
        },
        closeMainWindow() {
            this.mainDialogShow = false;
            this.activeButton();
            this.start();
        },
        hideMainPage() {
            this.mainShow = false;
        },
        showMainPage() {
            //this.mainShow = true;
            this.$nextTick(function () {
                this.$refs.mainDialog.showJsButton = this.showJsButton;
            })
        },
        showKfPage() {
            this.hideMainPage();
            this.kfShow = true;
        },
        openJsPage() {
            this.hideMainPage();
            this.jsPageShow = true;
            this.$nextTick(function () {
                this.$refs.gameSpeedDialog.speedRate = this.speedRate;
            })
        },
        openOutsidePage(title, src, type) {
            /*this.videoPageDialogShow = true;
            this.videoPageShow = true;
            this.$nextTick(function () {
              this.$refs.videoPage.show(title, src, type);
            })*/
            //this.$refs.webview_iframe.document.location.reload();
            //window.parent.document.getElementById('iframe');
            this.$refs.webview_iframe.contentWindow.location.reload(true);
            console.log(this.$refs.webview_iframe.contentWindow);
            this.closeMainWindow();
            let user = JSON.parse(localStorage.getItem('user'));
            this.openPageUrl = window.location.origin + user.webviewPageUrl;
            this.openPageShow = true;
        },
        closeOutsidePage() {
            this.openPageShow = false;
        },
        closeLuckyPage() {
            this.videoPageShow = false;
            this.videoPageDialogShow = false;
        },
        closeKfPage() {
            this.kfShow = false;
            this.showMainPage();
        },
        closeJsPage() {
            this.jsPageShow = false;
            this.showMainPage();
        },
        showChangePage() {
            this.hideMainPage();
            this.changePasswordShow = true;
        },
        hideChangePage() {
            this.changePasswordShow = false;
            this.showMainPage();
        },
        showRealNamePage() {
            this.hideMainPage();
            this.realNameShow = true;
        },
        showRealNamePageReadOnly() {
            this.hideMainPage();
            this.realNameShow = true;
            this.$nextTick(function () {
                this.$refs.realNameDialog.backShow = true;
                this.$refs.realNameDialog.initData();
            })
        },
        hideRealNamePage() {
            this.realNameShow = false;
            this.showMainPage();
        },
        showBindPage() {
            this.hideMainPage();
            this.bingTelShow = true;
            /*this.$nextTick(function() {
              this.$refs.bindTelDialog.backShow = true;
            })*/
        },
        hideBindPage() {
            this.bingTelShow = false;
            this.checkDialogShow = false;
            this.showMainPage();
        },
        showUnbindPage() {
            this.hideMainPage();
            this.unBingTelShow = true;
        },
        hideUnbindPage() {
            this.unBingTelShow = false;
            this.showMainPage();
        },
        closeAll() {
            this.changePasswordShow = false;
            this.bingTelShow = false;
            this.unBingTelShow = false;
            this.realNameShow = false;
            this.kfShow = false;
        },
        testPay() {
            let order = {
                goodsName: '测试商品',
                money: '200',
                gameId: '101',
            };
            this.openPayPage(order);
        },
        openPayPage(order) {
            this.payShow = true;
            this.$nextTick(function () {
                this.$refs.pay.initOrder(order);
                this.$refs.pay.getBalance();
            })
        },
        openPayUrl(url, payType, price) {
            //处理ios端七里app的支付
            if (this.from === 'qiliApp') {
                uni.postMessage({
                    data: {
                        action: 'continue',
                        msg: url
                    }
                });
                return;
            }
            if (this.from === 'htfapp') {
                window.htfJsInterface.openUrl(url);
                return;
            }

            if (this.from === 'android') {
                window.QiliJsInterface.openUrl(url);
                return;
            }

            this.payShow = false;
            if (this.isMobile()) {
                this.payUrl = url + '&redirect_url=7li.cn%3Ahttps%3A%2F%2Fgame.7li.cn%2F%23%2Fplay%2F1%2F110';
                if (url.indexOf("api.kj-pay") > -1 || url.indexOf("kjwxpay") > -1) {
                    let ua = navigator.userAgent.toLowerCase();
                    if (/iphone|ipad|ipod/.test(ua)) {//判断是否ios
                        this.diyDialogShow = true;
                    } else {
                        window.open(this.payUrl, '');
                    }
                } else {
                    this.payUrl = url;
                    let ua = navigator.userAgent.toLowerCase();
                    if (/iphone|ipad|ipod/.test(ua)) {//判断是否ios
                        this.diyDialogShow = true;
                    } else {
                        window.open(this.payUrl, '');
                    }
                }
            } else {
                if (url.indexOf('data:image') > -1 || url.indexOf('create-qr-code') > -1 || url.indexOf('qrcode') > -1) {
                    this.payCodeImgShow = true;
                    this.$nextTick(function () {
                        this.$refs.payCodeImg.payImg = url;
                        this.$refs.payCodeImg.payType = payType;
                        this.$refs.payCodeImg.payPrice = price;
                        this.$refs.payCodeImg.init();
                    });
                } else {
                    this.payCodeShow = true;
                    this.$nextTick(function () {
                        this.$refs.payCode.payCode = url;
                        this.$refs.payCode.payType = payType;
                        this.$refs.payCode.payPrice = price;
                        this.$refs.payCode.init();
                    });
                }

            }

        },
        missRealName() {
          this.mainDialogShow = false;
          this.realNameShow = false;
          this.gameUrl = this.tempGameUrl;
        },
        refreshPage() {
            this.gameUrl = '';
            setTimeout(() => {
                this.gameUrl = this.tempGameUrl;
            }, 1000)

        },
        closeDiyDialog() {
            this.diyDialogShow = false;
        },
        isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
            return flag;
        },
        closePayPage() {
            this.payShow = false;
        },
        logout() {
            logout().then(res => {
                this.logoutCallback();
            })
        },
        logoutCallback() {
            window.removeEventListener("message", this.listener);
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('loginUrl');
            if (this.from === 'qiliApp') {
                uni.postMessage({
                    data: {
                        action: 'back',
                        msg: ''
                    }
                });
            } else {
                Toast('已退出登录');
                this.mainDialogShow = false;
                this.mainShow = false;
                this.changePasswordShow = false;
                this.bingTelShow = false;
                this.unBingTelShow = false;
                this.realNameShow = false;
                this.kfShow = false;
                this.openPageShow = false;
                this.videoPageShow = false;
                this.videoPageDialogShow = false;
                this.payDialogShow = false;
                this.payCodeShow = false;
                this.payCodeImgShow = false;
                this.diyDialogShow = false;
                this.step = 1;
                this.gameUrl = null;
                window.location.reload();
            }
        },
        copy(v) {
            const input = document.createElement('input')
            document.body.appendChild(input)
            input.setAttribute('value', v)
            input.select()
            if (document.execCommand('copy')) {
                document.execCommand('copy')
            }
            document.body.removeChild(input)
        },
    }
}
</script>

<style scoped lang="scss">
.back-div {
    background-color: #eceaea;

    .main-page {
        position: relative;
        //max-width: 600px;
        background-color: #ffffff;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        touch-action: none;
        margin: 0 auto;
        /*.van-dialog{
          position: absolute;
          display: table;
          top: 30%;
          left: 12%;
          backface-visibility:hidden;
          transform:rotate(90deg);
          -ms-transform:rotate(90deg);
          -moz-transform:rotate(90deg);
          -webkit-transform:rotate(90deg);
          -o-transform:rotate(90deg);
        }*/

        /*.van-dialog {
          position: fixed;
          width: 90%;
          top: 17%;
          left: 5%;
          -webkit-transform: unset !important;
          transform: unset !important;
          -webkit-backface-visibility: unset !important;
          backface-visibility: unset !important;
        }*/

        .diy-dialog {
            background-color: white;
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 10px 8px 1px 8px;
            text-align: left;
            font-size: 14px;
            width: 70%;
            border-radius: 10px;
            margin: 40% auto;
            left: 14.5%;
            //border:1px solid #e3e3e3;
            //box-shadow:0 0 5px 2px #dbdada;
            .diy-dialog-buttons {
                padding: 0 15px;
                font-size: 14px;
                display: flex;
                justify-content: space-between;
                margin-bottom: 0;
                height: 30px;

                a {
                    color: #1783d6;
                }

                div {
                    width: 100%;
                    text-align: center;
                }
            }

            .van-divider--hairline {
                margin: 8px 0;
            }
        }

        .xuanfu {
            position: fixed;
            touch-action: none;
            height: 50px;
            width: 50px;
            /* 如果碰到滑动问题，1.3 请检查 z-index。z-index需比web大一级*/
            z-index: 999;
            top: 60%;
            left: -25px;
        }

        .loading-dialog {
            position: fixed;
            top: 40%;
            left: 20%;
            width: 60%;
            height: 5rem;
        }
    }

    .right-panel {
        position: absolute;
        background-color: white;
        width: 100%;
        height: 95%;
        top: 5%;
        right: 0;
    }
}


</style>
