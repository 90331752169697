<template>
  <div class="main-container">
    <div class="left-top-title">
      <van-image class="back-icon" :src="backIcon" @click="back" width="2rem"></van-image>
      <h2>余额提现</h2>
    </div>

    <div class="balance-panel">
      <div class="left">
        <span class="title">余额</span>
        <span>128.50</span>
      </div>
      <div class="right">
        <span class="title">七里币</span>
        <span>0</span>
      </div>
    </div>
    <div class="cashout-game-panel">
      <div>
        提现到七里币    1游戏币=1元
      </div>
      <div class="yxb-number">1000</div>
      <div class="cashout-button-yxb">兑换游戏币</div>
    </div>
    <div class="tips-panel">
      <div class="title">兑换说明</div>
      <span>
        红包余额可兑换成游戏币在游戏内使用，每天只能兑换1次，兑换实时到账
      </span>
    </div>

    <van-divider />
    <div class="cashout-game-panel">
      <div>
        提现至微信钱包
      </div>
      <div class="wx-number">
        <div>200元</div>
        <div class="button-tips">4天内达到350级</div>
      </div>
      <div class="cashout-button-wx">立即提现</div>
    </div>
    <div class="tips-panel bottom">
      <div class="title">提现说明</div>
      <span>
        1. 无门槛提现，每天提现1次<br>
        2. 提现申请将在1-3个工作日审核到账，请耐心等待
      </span>
    </div>
  </div>
</template>

<script>
  import {getConfig} from '@/api/request';

import backIcon from "@/assets/back.png";
import redpaperIcon from "@/assets/redpaper.png";
import { Toast } from 'vant'

export default {
  name: 'cashOutPage',
  data() {
    return {
      backIcon,
      username: '全服第一',
      userID: 'ID: 88790',
      gameInfo: '当前区服: S_122   当前等级: 170级',
    };
  },
  mounted() {
    let params = {
      device: 'H5'
    };
  },
  methods: {
    back() {
      this.$router.back();
    },

  }
}
</script>

<style scoped lang="scss">
.main-container {
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  height: 100vh;
  .left-top-title{
    display: flex;
    /*实现垂直居中*/
    align-items: center;
    justify-content: left;
    width: 100%;
    padding-left: .2rem;
    color: #666666;
    vertical-align: center;
    height: 3rem;
    line-height: 3rem;
    .back-icon{
      display: inline-block;
    }
    h2{
      display: inline-block;
      font-size: 1.3rem;
      margin: 0;
    }

  }
  .balance-panel{
    width: 90%;
    margin: 0 auto;
    background-color: #f5f4f4;
    border-radius: 10px;
    display: flex;
    padding: 20px 50px;
    box-sizing: border-box;
    justify-content: space-between;

    .left,.right {
      font-size: 18px;
      display: flex;
      flex-direction: column;
      font-weight: bold;
    }
    .left .title,.right .title{
      font-size: 13px;
      margin-bottom: 12px;
    }
  }

  .cashout-game-panel{
    text-align: left;
    margin-top: 15px;
    padding: 0 15px;
    font-weight: bold;
    font-size: 13px;

    .yxb-number{
      font-size: 14px;
      text-align: center;
      line-height: 30px;
      border-radius: 20px;
      width: 100px;
      height: 30px;
      box-sizing: border-box;
      color: #f11e1e;
      background-color: #FFF4EACE;
      border: 1px solid #f11e1e;
      margin-top: 15px;
    }
    .cashout-button-yxb{
      font-size: 14px;
      text-align: center;
      line-height: 35px;
      border-radius: 20px;
      width: 150px;
      height: 35px;
      box-sizing: border-box;
      background-color: #f11e1e;
      border: 1px solid #f11e1e;
      color: white;
      margin: 20px auto;
    }

    .wx-number{
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 14px;
      text-align: center;
      border-radius: 8px;
      width: 150px;
      height: 60px;
      box-sizing: border-box;
      color: #F3960B;
      border: 1px solid #F7C20D;
      margin-top: 15px;
      background-color: #FFF4EACE;
      .button-tips{
        margin-top: 10px;
        font-weight: normal;
        font-size: 6px;
        color: #a7a8aa;
      }
    }
    .cashout-button-wx{
      font-size: 14px;
      text-align: center;
      border-radius: 20px;
      width: 150px;
      height: 35px;
      line-height: 35px;
      box-sizing: border-box;
      background-color: #F7C20D;
      border: 1px solid #F7C20D;
      color: white;
      margin: 20px auto;
    }
  }

  .tips-panel{
    text-align: left;
    font-size: 12px;
    margin: 10px 15px;
    color: #838384;
    .title{
      color: #333333;
      font-weight: bold;
      margin-bottom: 7px;
    }
  }
  .bottom{
    margin-bottom: 40px;
  }
}
</style>
