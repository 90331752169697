<template>
  <div class="main-container">
      <van-form @submit="onSubmit" ref="submitForm">
        <div class="left-top-title">
          <van-image class="back-icon" :src="backIcon" @click="back" width="2rem"></van-image>
          <h2>解绑您的手机号</h2>
        </div>

        <div style="margin: 10px 20px;">
          <van-field label-width="60px" label="手机号码" readonly placeholder="请输入您的手机号" style="margin-top: 10px;" v-model="tel" clearable/>

          <van-field label-width="60px" label="验证码"  style="margin-top: 20px;"  v-model="verifyCode" type="number"
                     placeholder="请输入验证码">
            <template #button>
              <van-count-down
                      v-show="showCountDown"
                      ref="countDown"
                      :time="60000"
                      :auto-start="false"
                      format="重新发送(ss秒)"
                      style="font-size: 12px;color: #888888"
                      @finish="finish"
              />
              <span style="color: #5A6EFC;font-size: 12px;" v-show="!showCountDown" @click="sendSmsForUnbind">发送验证码</span>
            </template>
          </van-field>

          <div class="box-button">
            <van-button type="info" block native-type="submit" size="small"
                        color="#5A6EFC">
              立即解绑
            </van-button>
          </div>

        </div>
      </van-form>
  </div>
</template>

<script>

  import {sendSms, unBindPhone} from '@/api/request'
  import verifyCodeIcon from "@/assets/verifyCode.png";
  import phoneIcon from "@/assets/phone.png";
  import backIcon from "@/assets/back.png";

  import { Toast } from 'vant'

  export default {
    name: 'changePassword',
    data() {
      return {
        phoneIcon,
        verifyCodeIcon,
        backIcon,
        tel: '',
        verifyCode: '',
        codePattern: /^\d{6}$/,
        showCountDown: false,
        showSendText: true,
        disabledSend: false,
      };
    },
    mounted() {
      let user = JSON.parse(localStorage.getItem('user'));
      this.tel = user.phone;
    },
    methods: {
      back() {
        this.$parent.$parent.hideUnbindPage();
      },
      onSubmit() {
        let ok = this.checkCode();
        if (ok !== 'ok') {
          Toast(ok);
          return ;
        }

        let params = {
          device: 'H5' ,
          phone: this.tel,
          checkCode : this.verifyCode,
        };
        unBindPhone(params).then((res) => {
          if (res.data.success === true) {
            Toast.success('手机号码解绑成功');
            let user = JSON.parse(localStorage.getItem('user'));
            user.phone = null;
            localStorage.setItem('user', JSON.stringify(user));
            this.back();
          } else {
            Toast.fail(res.data.error.message);
          }
        });
      },
      sendSmsForUnbind() {
        this.disabledSend = true;
        this.showSendText = false;
        this.showCountDown = true;
        this.$refs.countDown.start();
        let params = {
          device: 'H5' ,
          phone: this.tel,
          type: '3',
        };
        sendSms(params).then((res) => {
          if (res.data.success === true) {
            Toast('验证码发送成功');
          } else {
            Toast(res.data.error.message);
            this.finish();
          }
        });
      },
      checkCode() {
        if (!this.verifyCode) {
          return "请输入验证码";
        }
        if (!this.codePattern.test(this.verifyCode)) {
          return "验证码为6位数字";
        }
        return 'ok';
      },
      finish() {
        this.disabledSend = false;
        this.showSendText = true;
        this.showCountDown = false;
        this.$refs.countDown.reset();
      }
    }
  }
</script>

<style scoped lang="scss">
  .main-container {
    margin: .5rem .3rem .8rem;
    .left-top-title{
      display: flex;
      /*实现垂直居中*/
      align-items: center;
      justify-content: left;
      width: 100%;
      padding-left: .2rem;
      color: #666666;
      vertical-align: center;
      height: 3rem;
      line-height: 3rem;
      .back-icon{
        display: inline-block;
      }
      h2{
        display: inline-block;
        font-size: 1.3rem;
        margin: 0;
      }

    }

    .top-label{
      width: 100%;
      text-align: left;
      padding: 0 .8rem;
      color: #666666;
      font-size: .8rem;
      span{
        margin-left: .5rem;
      }
    }

    .down-input{
      margin-top: .8rem;
    }
    .van-cell{
      padding: 5px 16px;
    }
    .line-row{
      position: relative;
      vertical-align: bottom;
      font-size: .8rem;
      .right-line-button{
        position: absolute;
        right: .8rem;
        bottom: .8rem;
        .van-button__text{
          font-size: .7rem;
        }
      }
    }
    .width-line{
      height: 1px;
      margin: 0 1.04rem;
      border-bottom: 1px solid #b3b3b6;
    }
    .box-button {
      margin: 20px 0;
    }
    .van-field{
      outline: 1px #dbdbdb solid;
      border-radius: 3px;
    }
    .van-field:focus-within{
      outline: 2px #3478F6 solid;
    }
    /deep/.van-field__control {
      //color: #3478F6;
    }
  }
</style>
