import Vue from 'vue'
import Router from 'vue-router'
import Register from './views/register/register.vue';
import Reset from './views/password/reset.vue';
import Main from './views/main/main.vue';
import WoYangWangLuo from './views/dispatch/woyangwangluo.vue';
import DaZuiBa from './views/dispatch/dazuiba.vue';
import DaZuiBaXin from './views/dispatch/dazuibaxin.vue';
import ZhuZhuYou from './views/dispatch/zhuzhuyou.vue';
import HuiYou from './views/dispatch/huiyou.vue';
import HanTian from './views/dispatch/hantian.vue';
import Punk from './views/dispatch/punk.vue';
import DaoYi from './views/dispatch/daoyi.vue';
import CustomerService from './views/customerservice/customerservice.vue';
import InnerWindow from './open/innerwindow.vue';
import Download_HT from './download/dldlht.vue';
import Download_HT1 from './download/dldlht1.vue';
import Download_LC from './download/dldllc.vue';
import Download_HD from './download/ydcs.vue';
import Download_HD1 from './download/ydcs1.vue';
import Download_LS from './download/ydcsls.vue';
import Download from './download/get.vue';
import DownloadIOS from './download/getold.vue';
import IOS from './download/ios.vue';
import IOSOld from './download/iosold.vue';
import Downloadwd from './download/geth5.vue';
import Downloadold from './download/geth5old.vue';
import IOSDemo from './download/iosdemo.vue';
import Index from './views/home/gamehome.vue';
import SelfPay from './views/home/selfpay.vue';
import SelfPayWX from './views/home/selfpay1.vue';
import QLWXJS from './views/home/wxjsapi.vue';
import RedPaper from './open/redpaper.vue';
import CashOut from './open/cashout.vue';
import Coupon from './open/coupon.vue';
import GameDetail from './views/app/gamedetail.vue';
import Intro from './views/app/intro.vue';
import SpeedDlGame from './views/hooktime/gamedl.vue';
import WxLogin from "./views/login/wxLogin.vue";
import ShareNewUser from "@/views/app/shareNewUser.vue";
import AuthForWx from "@/views/auth/authForWx.vue";
import TestSdk5 from "@/views/test/TestSdk5.vue";
import TestSdk2 from "@/views/test/TestSdk2.vue";

Vue.use(Router)

export default new Router({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/index'
    },
    /*
    {
      path: '/test5',
      name: 'TestSdk5',
      component: TestSdk5
    },
    {
      path: '/test2',
      name: 'TestSdk2',
      component: TestSdk2
    },*/
    {
      path: '/register',
      name: 'Register',
      component: Register
    },
    {
      path: '/reset',
      name: 'Reset',
      component: Reset
    },
    {
      path: '/index/*',
      name: 'Index',
      component: Index
    },
    {
      path: '/index',
      name: 'Index',
      component: Index
    },
    {
      path: '/selfPay',
      name: 'SelfPay',
      component: SelfPay
    },
    {
      path: '/jiabi',
      name: 'SelfPay',
      component: SelfPay
    },
    {
      path: '/selfPayWX',
      name: 'SelfPayWX',
      component: SelfPayWX
    },
    {
      path: '/play/*/*',
      name: 'Main',
      component: Main
    },
    {
      path: '/customerService',
      name: 'CustomerService',
      component: CustomerService
    },
    {
      path: '/open/qili/main',
      name: 'InnerWindow',
      component: InnerWindow
    },
    {
      path: '/download/ht',
      name: 'Download_HT',
      component: Download_HT,
      meta: {
        title: '斗罗大陆-昊天'
      }
    },
    {
      path: '/download/ht1',
      name: 'Download_HT1',
      component: Download_HT1,
      meta: {
        title: '斗罗大陆-昊天'
      }
    },
    {
      path: '/download/lc',
      name: 'Download_LC',
      component: Download_LC,
      meta: {
        title: '斗罗大陆-罗刹'
      }
    },
    {
      path: '/download/hd',
      name: 'Download_HD',
      component: Download_HD,
      meta: {
        title: '一刀传世'
      }
    },
    {
      path: '/download/hd1',
      name: 'Download_HD1',
      component: Download_HD1,
      meta: {
        title: '一刀传世'
      }
    },
    {
      path: '/download/ls',
      name: 'Download_LS',
      component: Download_LS,
      meta: {
        title: '一刀传世'
      }
    },
    {
      path: '/d/*/*',
      name: 'Download',
      component: Download
    },
    {
      path: '/download/*/*',
      name: 'Download',
      component: Download
    },
    {
      path: '/downloadios/*',
      name: 'DownloadIOS',
      component: DownloadIOS
    },
    {
      path: '/ios/*/*',
      name: 'IOS',
      component: IOS
    },
    {
      path: '/iosold/*',
      name: 'IOSOld',
      component: IOSOld
    },
    {
      path: '/downloadwd/*/*',
      name: 'Downloadwd',
      component: Downloadwd
    },
    {
      path: '/wd/*/*',
      name: 'Downloadwd',
      component: Downloadwd
    },
    {
      path: '/downloadold/*/*',
      name: 'Downloadold',
      component: Downloadold
    },
    {
      path: '/download/iosGuide',
      name: 'IOSDemo',
      component: IOSDemo,
      meta: {
        title: 'IOS信任指引'
      }
    },
    {
      path: '/dispatch/woyang',
      name: 'WoYangWangLuo',
      component: WoYangWangLuo
    },
    {
      path: '/dispatch/dazuiba',
      name: 'DaZuiBa',
      component: DaZuiBa
    },
    {
      path: '/dispatch/dazuibaxin',
      name: 'DaZuiBaXin',
      component: DaZuiBaXin
    },
    {
      path: '/dispatch/zzy',
      name: 'ZhuZhuYou',
      component: ZhuZhuYou
    },
    {
      path: '/dispatch/huiyou',
      name: 'HuiYou',
      component: HuiYou
    },
    {
      path: '/dispatch/hantian/*',
      name: 'HanTian',
      component: HanTian
    },
    {
      path: '/dispatch/punk',
      name: 'Punk',
      component: Punk
    },
    {
      path: '/dispatch/daoyi',
      name: 'DaoYi',
      component: DaoYi
    },
    {
      path: '/qlwxjs',
      name: 'QLWXJS',
      component: QLWXJS,
      meta: {
        title: '微信支付'
      }
    },
    {
      path: '/open/redpaper',
      name: 'RedPaper',
      component: RedPaper
    },
    {
      path: '/open/cashOut',
      name: 'CashOut',
      component: CashOut
    },
    {
      path: '/open/coupon',
      name: 'Coupon',
      component: Coupon
    },
    {
      path: '/app/GameDetail',
      name: 'GameDetail',
      component: GameDetail
    },
    {
      path: '/app/intro',
      name: 'Intro',
      component: Intro
    },
    {
      path: '/speed/game',
      name: 'SpeedDlGame',
      component: SpeedDlGame
    },
    {
        path: '/wxLogin',
        name: 'WxLogin',
        component: WxLogin
    },
    {
      path: '/userShare',
      name: 'ShareNewUser',
      component: ShareNewUser
    },
    {
      path: '/authForWx',
      name: 'AuthForWx',
      component: AuthForWx
    },
  ]
})
