<template>
    <div style="height: 450px">
        <!--<iframe ref="gameIframe" :src="payUrl" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
-->

        <a target="_blank" :href="payUrl">点击支付</a>
        <!--<van-button color="#7232dd" :url="payUrl">单色按钮</van-button>-->
    </div>
</template>

<script>
  export default {
    name: 'pay-dialog',
    data() {
      return {
        payUrl: '',
      }
    },
  }
</script>

<style scoped>

</style>
