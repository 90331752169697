<template>
    <div class="main-container">
        <div
            style="width: 100%;margin-top: 40px;text-align: left;padding: 10px 25px;font-size: 16px;box-sizing: border-box;display: flex;align-items: center;">
            <img src="../../assets/qiliLogo.png" style="width: 80px;height: 80px;">
            <div style="margin-left: 20px;">
                <div>您好</div>
                <div>欢迎来到<span style="font-weight: bold">七里游戏</span></div>
            </div>
        </div>


        <div class="main-border">
            <div style="font-size: 18px;font-weight: bold;text-align: left;margin-bottom: 15px;display: flex;align-items: center">
                <!--<van-image class="back-icon" :src="backIcon" @click="back" width="2rem"></van-image>-->
                <span>联系客服</span>
            </div>


            <div class="top-label">
                <van-image :src="qqIcon" width=".8rem"></van-image>
                <span>QQ在线客服</span>
            </div>
            <div class="cs-qq-panel">
                <span v-for="item in qqService">{{ item }}</span>
            </div>

            <div class="top-label">
                <van-image :src="wxIcon" width=".8rem"></van-image>
                <span>微信公众号</span>
            </div>
            <div class="cs-wx-panel">
                <p>公众号搜索“七里网络”，更多福利等着你</p>
                <van-image :src="qiliQrcodeImg" width="5.8rem"></van-image>
            </div>

            <div style="font-size: 12px;margin-top: 2px;display: flex;justify-content: space-between;padding: 0 20px;">
                <div @click="back" style="color: #3478F6">返回上一页面</div>
            </div>
        </div>
    </div>
</template>

<script>

import {doLogin} from '@/api/request'
//import crypto from '@/utils/crypto.js';
import qqIcon from "@/assets/qqkf-icon.png";
import wxIcon from "@/assets/wx.png";
import backIcon from "@/assets/back.png";
import qiliQrcodeImg from "@/assets/qili-qrcode.png";

export default {
    name: 'login',
    data() {

        return {
            qiliQrcodeImg,
            qqIcon,
            wxIcon,
            backIcon,
            qqService: ['888800090', '888800091', '888800092', '888800093'],
        };
    },
    methods: {
        back() {
            this.$router.back();
        },
        onSubmit() {

        }

    }
}
</script>

<style scoped lang="scss">
.main-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    .main-border {
        max-width: 500px;
        text-align: center;
        background-color: white;
        border-radius: .3rem;
        width: 84%;
        border: 1px solid #eceaea;
        padding: 20px 20px;
        box-sizing: border-box;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

        .left-top-title {
            display: flex;
            /*实现垂直居中*/
            align-items: center;
            justify-content: left;
            width: 100%;
            padding-left: .2rem;
            color: #666666;
            vertical-align: center;
            height: 3rem;
            line-height: 3rem;

            .back-icon {
                display: inline-block;
            }

            h2 {
                display: inline-block;
                font-size: 1.3rem;
                margin: 0;
            }

        }

        .top-label {
            display: flex;
            /*实现垂直居中*/
            align-items: center;
            justify-content: left;
            width: 100%;
            text-align: left;
            padding: 0 .8rem;
            color: #666666;
            font-size: .8rem;

            span {
                margin-left: .5rem;
            }

            image {
                margin-top: .1rem;
            }
        }

        .cs-qq-panel {
            padding: .5rem 5px;
            text-align: left;
            color: dodgerblue;

            span {
                margin: 0 .8rem;
                display: inline-block;
                height: 1.6rem;
                line-height: 1.6rem;
            }
        }

        .cs-wx-panel {
            text-align: left;
            padding: .5rem 5px;
            margin-left: .8rem;

            p {
                margin: 0 0 .8rem;
                font-size: .8rem;
                color: #999999;
            }
        }

        .down-input {
            margin-top: .8rem;
        }

        .van-cell {
            padding: 12px 16px;
        }

    }
}
</style>
