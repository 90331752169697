<template>
  <div class="signInPage">

  </div>
</template>

<script>
import {
    bindWx, commRequest
} from '@/api/request.js'
import { Toast } from 'vant';
export default {
  name: 'AuthTS',
  components: {},
  data () {
    return {
      show:true
    }
  },
  computed: {},
  beforeCreate () {},
  created () {
    // debugger
    let url = window.location.href;
    let code = url.split("?")[1].split("#")[0].split("&")[0].split("=")[1];
    let bindCode = url.split("?")[1].split("#")[0].split("&")[1].split("=")[1];
    let param ={
      "wxCode":code,
      "shareCode":bindCode
    }
    if (code) {//如果连接中能拿到code说明此时access_token没有或者已过期，需要重新获取token
        let options = {
            url: `/shjH5/joinShare`,
            data: param
        }
        commRequest(options).then(res => {
            if(res.data.success){
                this.$router.push("/userShare?shareUrl=" + res.data.data);
            }else {
                this.$router.push("/userShare?errorMessage=" + res.data.error.message);
            }
        })
    }

  },
  mounted () {
  },
  methods: {

  }
}
</script>
<style scoped lang="scss">
</style>
