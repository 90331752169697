<template>
    <div class="all-page">
        <div class="main-page">

            <van-image :src="downloadImg" v-show="!downloading" class="down-button" @click="downloadGame"></van-image>
            <van-image :src="guideImg" v-if="isM" v-show="downloading" class="down-button" @click="goGuide"></van-image>
            <van-image :src="backImg" width="100%" height="100%" ></van-image>

            <div  v-if="!isM" class="code-div">
                <div  class="qr-code" ref="qrCodeUrl"></div>
                <p>扫描二维码下载游戏</p>
            </div>

        </div>
        <van-overlay :show="overlayShow">
            <div class="overlay-panel">
                <div>
                    <van-image :src="wjtImg" width="80px" height="80px" ></van-image>
                </div>
                <div class="overlay-info">
                    <p>
                        请点击右上角 ... 在默认浏览器中打开
                    </p>
                </div>
            </div>


        </van-overlay>
    </div>
</template>

<script>
  import backImg from '@/assets/downloadback/yd.jpg'
  import downloadImg from '@/assets/downloadback/button_download.jpg'
  import guideImg from '@/assets/downloadback/button_guide.jpg'
  import wjtImg from '@/assets/wjt.png'
  import QRCode from 'qrcodejs2'
  import {Toast} from "vant";

  export default {
    name: 'download_ds',
    data() {
      return {
        backImg,
        downloadImg,
        guideImg,
        wjtImg,
        thisUrl: '',
        androidUrl: 'http://qili-data.oss-cn-qingdao.aliyuncs.com/shengshiwd/channel/ydcslsfhjy/ydcslsfhjy_1_1.2.2.apk',
        iosUrl: 'https://game.7li.cn/static/download/ydcsls.mobileconfig',
        iosUrlToSet: 'https://game.7li.cn/static/download/toset.mobileprovision',
        qrCodeShow: false,
        isM: false,
        downloading: false,
        overlayShow: false,
      }
    },
    mounted() {
      this.thisUrl = window.location.href;
      this.isM = this.isMobile();
      if (!this.isM) {
        this.creatQrCode();
      }
      if (this.isWeiXin()) {
        this.overlayShow = true;
      }
    },
    methods: {
      isWeiXin(){
        let ua = window.navigator.userAgent.toLowerCase();
        //通过正则表达式匹配ua中是否含有MicroMessenger字符串
        return ua.match(/MicroMessenger/i) == 'micromessenger';
      },
      isMobile() {
        return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      },
      creatQrCode() {
        //console.log(this.$refs.qrCodeUrl.innerHTML = "");
        this.qrCode = new QRCode(this.$refs.qrCodeUrl, {
          text: this.thisUrl,
          width: 150,
          height: 150,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H

        });
      },
      downloadGame() {
        if (this.isMobile()) {
          let ua = navigator.userAgent.toLowerCase();;
          if (/iphone|ipad|ipod/.test(ua)) {//判断是否ios
            Toast({
                message: "点击允许后，点击信任开发者指引，找到 斗罗大陆-昊天 点击安装，输入锁屏密码，安装之后，在桌面进行查看",
                position: 'center',
                duration: -1
            });
            window.location.href = this.iosUrl;
            this.downloading = true;
          }
          else {
            window.location.href = this.androidUrl;
          }
        }else {
          window.location.href = this.androidUrl;
        }
      },
      goGuide() {
       window.location.href=this.iosUrlToSet;
      }
    }
  }
</script>

<style scoped lang="scss">
    .all-page{
        overflow: hidden;
        background-color: #eceaea;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .main-page{
            /*background-image: url("../assets/invite.jpg");
            background-size: contain;
            background-repeat:no-repeat;*/
            overflow: hidden;
            width: 100%;
            max-width: 500px;
            height: 100%;
            background-color: white;
            text-align: center;

            .down-button{
                z-index: 1;
                position: fixed;
                top: 55%;
                left: calc(50% - 50px);
                width: 100px;
                display: block;

            }

            .code-div{
                position: fixed;
                top: auto;
                left: calc(50% - 85px);
                bottom: 5rem;
                p{
                    font-weight: 600;
                    background-color: white;
                    border-radius: 5px;
                }
                .qr-code{
                    padding: 10px;
                    border-radius: 10px;
                    background-color: white;

                    /deep/ img{
                        display: inline !important;
                    }
                }
            }


        }

        .van-overlay{
            .overlay-panel{
                text-align: right;
                .overlay-info{
                    text-align: center;
                    width: 80%;
                    margin: 20px auto;
                    border-radius: 60px;
                    border: 2px dashed white;
                    color: white;
                    font-weight: 600;
                }
            }
        }
    }
</style>
