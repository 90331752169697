<template>
  <div class="back-div">
  <div class="main-page">
    <!--<van-button @click="testRole">角色</van-button>-->
    <iframe v-show="true" ref="gameIframe" :src="gameUrl" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>

  </div>
  </div>
</template>

<script>

import {zzyGetGameUrl,zzyPreOrder,zzyUploadRole} from '@/api/request'
import mainRoundImg from "@/assets/main-round.png";
import { Toast } from 'vant'

export default {
  name: 'zhuzhuyou',//猪猪游 联运
  data() {
    return {
      mainRoundImg,
      channelId: '143',//固定值
      app_id: '',
      mem_id: '',
      user_token: '',
      server_id:'',
      channelExt: '',
      game_appid: '',
      localUserId: '',
      gameUrl: '',
    };
  },
  mounted() {
    this.importJs();
    this.app_id = this.$route.query.app_id;
    this.mem_id = this.$route.query.mem_id;
    this.user_token = this.$route.query.user_token;
    let sign = this.$route.query.sign;
    localStorage.setItem("channelId", this.channelId);
    //localStorage.setItem("gameId", this.game_id);
    if (!this.gameUrl) {
      let params = {
        channelId: this.channelId ,
        app_id: this.app_id ,
        mem_id: this.mem_id ,
        user_token: this.user_token ,
        sign: sign ,
      };
      zzyGetGameUrl(params).then(res => {
        if(res.data.success) {
          this.gameUrl = res.data.data.loginUrl;
          this.localUserId = res.data.data.userId;
          console.log('返回游戏页面', this.gameUrl);
        }
        else {
          Toast.fail('拉取游戏数据异常');
        }
      });
    }
    this.initListener();
    let me = this;
    setTimeout(function () {
      me.initHuoSdk();
    }, 1000);
  },
  methods: {
    importJs() {
      const oScript = document.createElement('script');
      oScript.type = 'text/javascript';
      oScript.src = 'https://static.zhuzyou.com/h5sdk/js/huosdk.js';
      document.body.appendChild(oScript);
    },
    initHuoSdk() {
      HuoSdk.callback = {
        init: function (res) {
          console.log('init callback');
        },
        login: function (res) {
          console.log('login: callback');
        },
        pay: function (res) {
          console.log('pay: callback');
        },
        uprole: function (res) {
          console.log('uprole callback');
        },
        logout: function (res) {
          console.log('logout callback');
        }
      }
      HuoSdk.init({
        app_id: this.app_id
      });
    },
    initListener() {
      window.addEventListener("message", this.listener);
    },
    listener(event) {
      let me = this;
      if (event.data.operation === 'pay') {
        console.log("sdk触发支付");
        let order = event.data.param;
        me.preOrder(order);
      }
      else if (event.data.operation === 'uploadGameRole') {
        console.log("sdk触发角色上报");
        let param = event.data.param;
        me.uploadRole(param);
      }
      else if (event.data.operation === 'repeatLogin') {
        console.log("sdk触发登陆被顶");
        me.logout();
      }
    },
    preOrder(order) {
      order.channelId = this.channelId;
      order.userId = this.localUserId;
      order.cpOrder = order.cpOrderId;
      order.serviceId = order.server;
      order.serviceName = order.server;
      order.desc = order.goodsName;
      order.roleName = order.role;
      order.roleId = order.role;
      let params = {
        'order-cp_order_id': '',
        'order-currency': 'CNY',
        'order-product_price': order.money / 100,
        'order-product_id': 1,
        'order-product_name': order.goodsName,
        'order-product_desc': '',
        'order-ext': '',
        'role-event': 5,
        'role-server_id': order.server,
        'role-server_name': order.server,
        'role-role_id': order.role,
        'role-role_name': order.role,
        'role-role_level': 0,
        'role-role_vip': 0
      }

      zzyPreOrder(order).then(res => {
        if(res.data.success) {
          params.sign = res.data.data.sign;
          params['role-role_name'] = res.data.data.role_name;
          params['role-server_name'] = res.data.data.server_name;
          params['order-cp_order_id'] = res.data.data.orderId;
          HuoSdk.pay(params);
        }
        else {
          Toast.fail(res.data.error.message);
        }
      });
    },
    uploadRole(roleParam) {
      roleParam.serviceId = roleParam.serverId;
      roleParam.serviceName = roleParam.serverName;
      let params = {
        'role-event': 1,
        'role-server_id': roleParam.serverId,
        'role-server_name': roleParam.serverName,
        'role-role_id': roleParam.roleId,
        'role-role_name': roleParam.roleName,
        'role-role_level': 0,
        'role-role_vip': 0,
        'role-onlineTime': 0,
        'role-scene': '',
        'role-axis': '',
      }
      zzyUploadRole(roleParam).then(res => {
        if(res.data.success) {
          console.log("上报后台角色信息成功");
          params.sign = res.data.data.sign;
          HuoSdk.uprole(params)
        }
        else {
          console.log("上报后台角色信息失败");
        }
      });
    },
    logout() {
      HuoSdk.logout();
      console.log("登出");
    },
  }
}
</script>

<style scoped lang="scss">
.back-div{
  background-color: #eceaea;

  .main-page {
    position: relative;
    max-width: 600px;
    background-color: #ffffff;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    touch-action: none;
    margin: 0 auto;
    .main-dialog{

    }

    .loading-dialog {
      position: fixed;
      top: 40%;
      left: 20%;
      width: 60%;
      height: 5rem;
    }
  }
}


</style>
