<template>
  <div class="main-container">
    <div class="left-top-title">
      <h2>优惠券领取</h2>
    </div>

    <div class="coupon-panel" v-for="i in discountList">
      <van-image width="60px"
              src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2Fb68e5576330a05e78703e0dde7e9e8885f66c71244c57-XH0ExR_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1643803868&t=c58f8c14cafc9f32c0d6354a4950eb70">
      </van-image>
      <div class="info-panel">
        <span class="name-label">{{i.discount_name}}</span>
        <span class="info-label">{{i.discount_type}}</span>
      </div>

      <div class="get-button">立即领取</div>
    </div>
    <div style="height: 30px;"></div>
  </div>
</template>

<script>
  import {queryDiscountList} from '@/api/request';
  import { Toast } from 'vant'

export default {
  name: 'couponPage',
  data() {
    return {
      userId: '',
      adminId: '',
      gameId: '',
      discountList: [],
    };
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem('user'));
    this.userId = user.userId;
    this.adminId = user.adminId;
    this.gameId = localStorage.getItem('gameId');
    this.initDiscountList();
  },
  methods: {
    back() {
      this.$router.back();
    },
    initDiscountList() {
      let params = {
        gameId: this.gameId,
        adminId: this.adminId,
        userId: this.userId,
      };
      queryDiscountList(params).then(res => {
        if (res.data.success) {
          this.discountList = res.data.data;
        }
        else {
          Toast(res.data.error);
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.main-container {
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  height: 100vh;
  .left-top-title{
    text-align: left;
    padding-left: 20px;
    height: 3rem;
    line-height: 3rem;
    h2{
      display: inline-block;
      font-size: 1.3rem;
      margin: 0;
    }
  }
  .coupon-panel{
    display: flex;
    background-color: rgba(215, 213, 213, 0.13);
    padding: 10px;
    margin-top: 2px;
    justify-content: space-around;
    align-items: center;
    .info-panel{
      text-align: left;
      .name-label{
        font-size: 12px;
        display: block;
        font-weight: bold;
      }
      .info-label{
        font-size: 12px;
        color: #a7a8aa;
      }
    }

    .get-button{
      font-size: 12px;
      font-weight: bold;
      text-align: center;
      line-height: 30px;
      border-radius: 20px;
      width: 80px;
      height: 30px;
      box-sizing: border-box;
      background-color: #f11e1e;
      border: 1px solid #f11e1e;
      color: white;
    }
  }

}
</style>
