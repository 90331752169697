<template>
  <div class="main-container">
      <van-form @submit="onSubmit" ref="submitForm">
        <div class="left-top-title">
          <van-image v-show="backShow" class="back-icon" :src="backIcon" @click="back" width="2rem"></van-image>
          <h2>绑定您的手机号</h2>
        </div>

        <div style="margin: 10px 20px;">
          <van-field label-width="60px" label="手机号码" placeholder="请输入您的手机号" style="margin-top: 10px;" v-model="tel" clearable/>

          <van-field label-width="60px" label="验证码"  style="margin-top: 20px;"  v-model="verifyCode" type="number"
                     placeholder="请输入验证码">
            <template #button>
              <van-count-down
                      v-show="showCountDown"
                      ref="countDown"
                      :time="60000"
                      :auto-start="false"
                      format="重新发送(ss秒)"
                      style="font-size: 12px;color: #888888"
                      @finish="finish"
              />
              <span  style="color: #5A6EFC;font-size: 12px;" v-show="!showCountDown" @click="sendSmsForBind">发送验证码</span>
            </template>
          </van-field>

          <div class="box-button">
            <van-button type="info" block native-type="submit" size="small"
                        color="#5A6EFC">
              立即绑定
            </van-button>

            <div style="font-size: 12px;margin-top: 15px;display: flex;justify-content: flex-end;padding: 0 3px;">
              <div @click="miss" style="color: #3478F6">跳过绑定</div>
            </div>
          </div>
        </div>
      </van-form>

  </div>
</template>

<script>

  import {sendSms, bindPhone} from '@/api/request'
  import verifyCodeIcon from "@/assets/verifyCode.png";
  import phoneIcon from "@/assets/phone.png";
  import backIcon from "@/assets/back.png";

  import { Dialog, Toast } from 'vant'

  export default {
    name: 'changePassword',
    data() {

      return {
        phoneIcon,
        verifyCodeIcon,
        backIcon,
        tel: '',
        verifyCode: '',
        missShow: false,
        backShow: true,
        telPattern: /^1\d{10}$/,
        codePattern: /^\d{6}$/,
        showCountDown: false,
        showSendText: true,
        disabledSend: false,
      };
    },
    methods: {
      back() {
        this.$parent.$parent.hideBindPage();
      },
      onSubmit() {
        let ok = this.checkForm();
        if (ok !== 'ok') {
          Toast(ok);
          return ;
        }

        let params = {
          device: 'H5' ,
          phone: this.tel,
          checkCode : this.verifyCode,
        };
        bindPhone(params).then((res) => {
          if (res.data.success === true) {
           Toast.success('手机号码绑定成功');
            let user = JSON.parse(localStorage.getItem('user'));
            user.phone = this.tel;
            localStorage.setItem('user', JSON.stringify(user));
            if (!this.backShow) {
              this.miss();
            } else {
              this.back();
            }
           this.back();
          } else {
            Toast.fail(res.data.error.message);
          }
        });
      },
      sendSmsForBind() {
          let ok = this.checkTel();
          if (ok !== 'ok') {
            Toast(ok);
            return ;
          }
          this.disabledSend = true;
          this.showSendText = false;
          this.showCountDown = true;
          this.$refs.countDown.start();
          let params = {
            device: 'H5' ,
            phone: this.tel,
            type: '2',
          };
          sendSms(params).then((res) => {
            if (res.data.success === true) {
              Toast('验证码发送成功');
            } else {
              Toast(res.data.error.message);
              this.finish();
            }
          });
      },
      checkTel() {
        if (!this.tel) {
          return "请输入手机号码";
        }
        if (!this.telPattern.test(this.tel)) {
          return "请输入正确的手机号码";
        }
        return 'ok';
      },
      checkForm() {
        if (!this.tel) {
          return "请输入手机号码";
        }
        if (!this.telPattern.test(this.tel)) {
          return "请输入正确的手机号码";
        }
        if (!this.verifyCode) {
          return "请输入验证码";
        }
        if (!this.codePattern.test(this.verifyCode)) {
          return "验证码为6位数字";
        }
        return 'ok';
      },
      finish() {
        this.disabledSend = false;
        this.showSendText = true;
        this.showCountDown = false;
        this.$refs.countDown.reset();
      },
      miss() {
        this.$parent.$parent.missBindTel();
      }
    }
  }
</script>

<style scoped lang="scss">
  .main-container {
    margin: .5rem .3rem .8rem;
    .left-top-title{
      display: flex;
      /*实现垂直居中*/
      align-items: center;
      justify-content: left;
      width: 100%;
      padding-left: .5rem;
      color: #666666;
      vertical-align: center;
      height: 3rem;
      line-height: 3rem;
      .back-icon{
        display: inline-block;
      }
      h2{
        display: inline-block;
        font-size: 1.3rem;
        margin: 0;
      }

    }

    .top-label{
      width: 100%;
      text-align: left;
      padding: 0 .8rem;
      color: #666666;
      font-size: .8rem;
      span{
        margin-left: .5rem;
      }
    }

    .down-input{
      margin-top: .8rem;
    }
    .van-cell{
      padding: 5px 16px;
    }
    .line-row{
      position: relative;
      vertical-align: bottom;
      font-size: .8rem;
      .right-line-button{
        position: absolute;
        right: .8rem;
        bottom: .8rem;
        .van-button__text{
          font-size: .7rem;
        }
      }
    }
    .width-line{
      height: 1px;
      margin: 0 1.04rem;
      border-bottom: 1px solid #b3b3b6;
    }
    .box-button {
      margin: 30px 0 20px;
    }

    .van-field{
      outline: 1px #dbdbdb solid;
      border-radius: 3px;
    }
    .van-field:focus-within{
      outline: 2px #3478F6 solid;
    }
    /deep/.van-field__control {
      //color: #3478F6;
    }
  }
</style>
