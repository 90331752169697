<template>
    <div class="main-container">
        <div class="left-top-title">
            <van-image class="back-icon" :src="backIcon" @click="back" width="2rem"></van-image>
            <h2>游戏加速</h2>
        </div>
        <div style="text-align: left;padding-left: 20px;font-size: 12px;color: #888888">请选择游戏加速倍数</div>
        <div style="display: flex;justify-content: space-between;margin-top: 10px;padding: 0 40px;">
            <div @click="setSpeed(1)" class="speed-button" :class="speedRate === 1 && 'speed-button-active'">
                原速
            </div>
            <div @click="setSpeed(3)" class="speed-button" :class="speedRate === 3 && 'speed-button-active'">
                3倍
            </div>
            <div @click="setSpeed(5)" class="speed-button" :class="speedRate === 5 && 'speed-button-active'">
                5倍
            </div>
        </div>
        <div style="display: flex;justify-content: space-between;margin-top: 20px;padding: 0 40px;">
            <div @click="setSpeed(10)" class="speed-button" :class="speedRate === 10 && 'speed-button-active'">
                10倍
            </div>
            <div @click="setSpeed(16)" class="speed-button" :class="speedRate === 16 && 'speed-button-active'">
                16倍
            </div>
            <div @click="setSpeed(32)" class="speed-button" :class="speedRate === 32 && 'speed-button-active'">
                32倍
            </div>
        </div>
        <div style="color: #ee4747;font-size: 12px;margin-top: 30px;font-weight: bold">当前游戏加速倍率：{{speedRate}}</div>

    </div>
</template>

<script>
    import {Toast} from 'vant'
    import backIcon from "@/assets/back.png";

    export default {
        name: 'kf',
        data() {
            return {
                backIcon,
                speedRate: 1,
            };
        },
        mounted() {

        },
        methods: {
            back() {
                this.$parent.$parent.closeJsPage();
            },
            setSpeed(n) {
                this.speedRate = n;
                this.$parent.$parent.setSpeed(n);
            },
        }
    }
</script>

<style scoped>
    .main-container {
        overflow: hidden;
        margin: .5rem .3rem .8rem;
    }
    .left-top-title {
        display: flex;
        /*实现垂直居中*/
        align-items: center;
        justify-content: left;
        width: 100%;
        padding-left: .2rem;
        color: #666666;
        vertical-align: center;
        height: 3rem;
        line-height: 3rem;
    }

    .back-icon {
        display: inline-block;
    }

    h2 {
        display: inline-block;
        font-size: 1.3rem;
        margin: 0;
    }

    .speed-button {
        font-weight: bold;
        width: 40px;
        height: 40px;
        font-size: 12px;
        line-height: 40px;
        color: #5A6EFC;
        background-color: #ffffff;
        border: 2px solid #5A6EFC;
        border-radius: 3px;
        text-align: center
    }
    .speed-button-active {
        color: #ffffff;
        background-color: #5A6EFC;
    }
</style>
